var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "wrapper",
        staticStyle: { "background-color": "#f8f8f8", overflow: "hidden" },
      },
      [
        _c("div", { attrs: { id: "lice" } }, [
          _c("h2", { staticStyle: { "text-align": "left" } }, [
            _vm._v("Licensing And Compliance"),
          ]),
          _c("div", { attrs: { id: "content-1" } }, [
            _c("div", { staticClass: "my-col" }, [
              _c("img", {
                staticClass: "responsive",
                attrs: {
                  src: require("@/assets/images/usa_map.jpg"),
                  nopin: "nopin",
                  alt: "",
                },
              }),
            ]),
          ]),
          _c("section", [
            _c("table", { staticStyle: { width: "100%" } }, [
              _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    "American Smart Lending Company is a licensed mortgage lender, Nationwide Multistate Licensing System(NMLS) # 1456732"
                  ),
                ]),
              ]),
              _c("tr", { attrs: { height: "12px" } }),
              _c("tr", [
                _c("td", [_c("strong", [_vm._v("State")])]),
                _c("td", [_c("strong", [_vm._v("License Name")])]),
                _c("td", [_c("strong", [_vm._v("Status")])]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Washington")]),
                _c("td", [_vm._v("Washington Consumer Loan Company License")]),
                _c("td", [_vm._v("Approved")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Colorado")]),
                _c("td", [_vm._v("Colorado Mortgage Company Registration")]),
                _c("td", [_vm._v("Approved")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Texas")]),
                _c("td", [_vm._v("Texas - SML Mortgage Company License")]),
                _c("td", [_vm._v("Approved")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Florida")]),
                _c("td", [_vm._v("Florida Mortgage Broker License")]),
                _c("td", [_vm._v("Approved")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("California")]),
                _c("td", [_vm._v("California - DFPI Financing Law License")]),
                _c("td", [_vm._v("(Pending)")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("Georgia")]),
                _c("td", [
                  _vm._v("Georgia Mortgage Lender License/Registration"),
                ]),
                _c("td", [_vm._v("Approved")]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }