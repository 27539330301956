var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    { attrs: { name: "fade", tag: "div" } },
    _vm._l([_vm.currentNumber], function (number) {
      return _c("div", {
        key: number,
        staticClass: "slide",
        style: { backgroundImage: "url(" + _vm.currentImage + ")" },
        on: { mouseover: _vm.stopRotation, mouseout: _vm.startRotation },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }