var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "limiter" }, [
      _c("div", { staticClass: "container-login100" }, [
        _c("div", { staticClass: "wrap-login100 p-t-25 p-b-20" }, [
          _c(
            "form",
            {
              staticClass: "login100-form validate-form",
              attrs: { id: "sign-in-form", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.signInUser.apply(null, arguments)
                },
              },
            },
            [
              _c("moon-loader", {
                attrs: {
                  loading: _vm.isLoading,
                  color: _vm.loaderColor,
                  size: _vm.loaderSize,
                  loaderMessage: _vm.loaderMessage,
                },
              }),
              _c("span", { staticClass: "login100-form-title p-b-20" }, [
                _vm._v("Welcome"),
              ]),
              _vm.currentSection === "email"
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "wrap-input100 validate-input m-t-50 m-b-35",
                        class:
                          _vm.validations.email === 1 ? "" : "alert-validate",
                        attrs: { "data-validate": "Enter valid email" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "input100",
                          class: _vm.email !== null ? "has-val" : "",
                          attrs: {
                            id: "login-email",
                            type: "email",
                            name: "email",
                            required: "",
                          },
                          domProps: { value: _vm.email },
                          on: {
                            change: function ($event) {
                              return _vm.validateEmail($event)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "focus-input100",
                          attrs: { "data-placeholder": "Email" },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "container-login100-form-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "login100-form-btn",
                          attrs: { disabled: !_vm.isEmailValid },
                          on: {
                            click: function ($event) {
                              return _vm.requestOTP($event)
                            },
                          },
                        },
                        [_vm._v("Send OTP")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.currentSection === "otp"
                ? _c("div", [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "otp-input-row" },
                      [
                        _vm._l(_vm.otp, function (digit, index) {
                          return _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.otp[index],
                                expression: "otp[index]",
                              },
                            ],
                            key: index,
                            ref: "otpInput",
                            refInFor: true,
                            staticClass: "otp-input",
                            class: _vm.otp[index] == "" ? "" : "active__input",
                            attrs: {
                              type: "text",
                              maxlength: "1",
                              required: "",
                            },
                            domProps: { value: _vm.otp[index] },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(_vm.otp, index, $event.target.value)
                                },
                                function ($event) {
                                  return _vm.handleInput(index, $event)
                                },
                              ],
                              keydown: function ($event) {
                                return _vm.handleOtherKeys(index, $event)
                              },
                            },
                          })
                        }),
                        _c("span", {
                          staticClass: "focus-input100",
                          attrs: { "data-placeholder": "Email" },
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "container-login100-form-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "login100-form-btn",
                          attrs: { disabled: !_vm.isValidOTP },
                          on: { click: _vm.submitOTP },
                        },
                        [_vm._v("Submit")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "login100-form-btn",
                          on: { click: _vm.goBack },
                        },
                        [_vm._v("Go Back")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.showErrorBanner
                ? _c(
                    "div",
                    { staticClass: "wrap-error" },
                    [
                      _c("transition", { attrs: { name: "shake" } }, [
                        _c("p", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.errorMessage)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentSection === "otp" && !_vm.isAccountLocked
                ? _c("div", { staticClass: "bottom-link" }, [
                    _vm.enableResendOtp
                      ? _c(
                          "p",
                          {
                            staticClass: "resend-active",
                            on: { click: _vm.requestOTP },
                          },
                          [
                            _vm._v(
                              " Didn't receive OTP yet? Click here to resend OTP "
                            ),
                          ]
                        )
                      : _vm.isOtpResent
                      ? _c("p", [_vm._v(" OTP has been sent.. ")])
                      : _c("p", [
                          _vm._v(
                            " You can resend OTP in " +
                              _vm._s(_vm.otpCounter) +
                              " secs "
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm.currentSection === "email"
                ? _c("div", { staticClass: "bottom-link" }, [
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({ name: "sign-up" })
                            },
                          },
                        },
                        [_vm._v("Don't have an account? Register")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "otp-input-label" }, [
      _c("p", [
        _vm._v(" Please enter the OTP sent to your registered email address "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }