var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-or-continue" },
    [
      _c("moon-loader", {
        attrs: {
          loading: _vm.isLoading,
          color: _vm.loaderColor,
          size: _vm.loaderSize,
          loaderMessage: _vm.loaderMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }