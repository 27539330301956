var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoading,
          expression: "!isLoading",
        },
      ],
      staticClass: "img-pic-viewer",
    },
    [
      _c("div", { staticClass: "img-header" }, [
        _c("div", [_c("p", [_vm._v(" " + _vm._s(_vm.fileName) + " ")])]),
        _c("div", { staticClass: "zoom-section" }, [
          _c("i", {
            staticClass: "fa fa-magnifying-glass-plus zoom-plus",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.zoomIn.apply(null, arguments)
              },
              mousedown: function ($event) {
                $event.preventDefault()
                return _vm.longPress(_vm.zoomIn)
              },
              mouseup: function ($event) {
                $event.preventDefault()
                return _vm.releasePress.apply(null, arguments)
              },
            },
          }),
          _c("div", { staticClass: "zoom-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scaleInput,
                  expression: "scaleInput",
                },
              ],
              attrs: { type: "text", maxlength: "3" },
              domProps: { value: _vm.scaleInput },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.scaleInput = $event.target.value
                  },
                  function ($event) {
                    return _vm.handleInput($event)
                  },
                ],
                blur: _vm.changeScale,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.changeScale.apply(null, arguments)
                },
              },
            }),
            _c("div", [_vm._v("%")]),
          ]),
          _c("i", {
            staticClass: "fa fa-magnifying-glass-minus zoom-minus",
            staticStyle: { "margin-left": "24px" },
            on: {
              click: _vm.zoomOut,
              mousedown: function ($event) {
                return _vm.longPress(_vm.zoomOut)
              },
              mouseup: _vm.releasePress,
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "img-container" }, [
        _c("img", {
          attrs: { src: _vm.imageUrl, id: "pic", alt: "Uploaded Image" },
        }),
        _c("div"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }