var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "sectionSeven", staticClass: "urla-section" }, [
    _c("h2", [_vm._v("Demographic Information")]),
    _c("p", { staticClass: "urla-section-description" }, [
      _vm._v(" This section asks about your ethnicity, sex, and race. "),
    ]),
    _vm._m(0),
    _c(
      "form",
      {
        attrs: { id: "section-7-form", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitSectionSeven.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("div", [
              _vm._m(1),
              _c("fieldset", { staticClass: "urla-radio" }, [
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerEthnicity
                              .ethnicity,
                          expression:
                            "\n                    section7DemographicInformation.customerEthnicity.ethnicity\n                  ",
                        },
                      ],
                      attrs: {
                        name: "ethnicity",
                        type: "radio",
                        id: "hispanic-or-latino",
                        value: "Hispanic or Latino",
                        required: "",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerEthnicity
                            .ethnicity,
                          "Hispanic or Latino"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation
                              .customerEthnicity,
                            "ethnicity",
                            "Hispanic or Latino"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        attrs: { for: "hispanic-or-latino" },
                      },
                      [_vm._v("Hispanic or Latino")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerEthnicity
                              .ethnicity,
                          expression:
                            "\n                    section7DemographicInformation.customerEthnicity.ethnicity\n                  ",
                        },
                      ],
                      attrs: {
                        name: "ethnicity",
                        type: "radio",
                        id: "not-hispanic-or-latino",
                        value: "Not Hispanic or Latino",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerEthnicity
                            .ethnicity,
                          "Not Hispanic or Latino"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation
                              .customerEthnicity,
                            "ethnicity",
                            "Not Hispanic or Latino"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        attrs: { for: "not-hispanic-or-latino" },
                      },
                      [_vm._v("Not Hispanic or Latino")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerEthnicity
                              .ethnicity,
                          expression:
                            "\n                    section7DemographicInformation.customerEthnicity.ethnicity\n                  ",
                        },
                      ],
                      attrs: {
                        name: "ethnicity",
                        type: "radio",
                        id: "ethnicity-do-not-wish-to-provide",
                        value: "I do not wish to provide this information",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerEthnicity
                            .ethnicity,
                          "I do not wish to provide this information"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation
                              .customerEthnicity,
                            "ethnicity",
                            "I do not wish to provide this information"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: { for: "ethnicity-do-not-wish-to-provide" },
                      },
                      [_vm._v("I do not wish to provide this information")]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "left-indent" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section7DemographicInformation.customerEthnicity
                    .ethnicity === "Hispanic or Latino"
                    ? _c("div", [
                        _c("hr", { staticClass: "urla-break" }),
                        _c("p", [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "sub-ethnicity" },
                            },
                            [
                              _vm._v(" Sub Ethnicity "),
                              _c("strong", [
                                _c(
                                  "abbr",
                                  {
                                    staticClass: "required",
                                    attrs: { title: "required" },
                                  },
                                  [_vm._v("*")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("fieldset", { staticClass: "urla-radio" }, [
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerEthnicity.subEthnicity,
                                    expression:
                                      "\n                        section7DemographicInformation.customerEthnicity\n                          .subEthnicity\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "sub-ethnicity",
                                  type: "radio",
                                  id: "mexican",
                                  value: "Mexican",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerEthnicity.subEthnicity,
                                    "Mexican"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerEthnicity,
                                      "subEthnicity",
                                      "Mexican"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "mexican" },
                                },
                                [_vm._v("Mexican")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerEthnicity.subEthnicity,
                                    expression:
                                      "\n                        section7DemographicInformation.customerEthnicity\n                          .subEthnicity\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "sub-ethnicity",
                                  type: "radio",
                                  id: "puerto-rican",
                                  value: "Puerto Rican",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerEthnicity.subEthnicity,
                                    "Puerto Rican"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerEthnicity,
                                      "subEthnicity",
                                      "Puerto Rican"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "puerto-rican" },
                                },
                                [_vm._v("Puerto Rican")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerEthnicity.subEthnicity,
                                    expression:
                                      "\n                        section7DemographicInformation.customerEthnicity\n                          .subEthnicity\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "sub-ethnicity",
                                  type: "radio",
                                  id: "cuban",
                                  value: "Cuban",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerEthnicity.subEthnicity,
                                    "Cuban"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerEthnicity,
                                      "subEthnicity",
                                      "Cuban"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "cuban" },
                                },
                                [_vm._v("Cuban")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerEthnicity.subEthnicity,
                                    expression:
                                      "\n                        section7DemographicInformation.customerEthnicity\n                          .subEthnicity\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "sub-ethnicity",
                                  type: "radio",
                                  id: "other-hispanic-or-latino",
                                  value: "Other Hispanic or Latino",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerEthnicity.subEthnicity,
                                    "Other Hispanic or Latino"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerEthnicity,
                                      "subEthnicity",
                                      "Other Hispanic or Latino"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "other-hispanic-or-latino" },
                                },
                                [_vm._v("Other Hispanic or Latino")]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section7DemographicInformation.customerEthnicity
                    .ethnicity === "Hispanic or Latino" &&
                  _vm.section7DemographicInformation.customerEthnicity
                    .subEthnicity === "Other Hispanic or Latino"
                    ? _c("div", { staticClass: "left-indent" }, [
                        _c(
                          "p",
                          { staticClass: "col-6 col-12-xsmall left-indent" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "hidden-label",
                                attrs: { for: "other-hispanic-or-latino" },
                              },
                              [
                                _c("span", [_vm._v("Please specify:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.otherHispanicOrLatino,
                                  expression: "otherHispanicOrLatino",
                                },
                              ],
                              staticClass: "medium-input",
                              attrs: {
                                id: "other-hispanic-or-latino",
                                type: "text",
                                placeholder: "e.g. Argentinean",
                                required: "",
                              },
                              domProps: { value: _vm.otherHispanicOrLatino },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.otherHispanicOrLatino =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("div", [
              _vm._m(2),
              _c("fieldset", { staticClass: "urla-radio" }, [
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.section7DemographicInformation.customerSex,
                          expression:
                            "section7DemographicInformation.customerSex",
                        },
                      ],
                      attrs: {
                        name: "sex",
                        type: "radio",
                        id: "female",
                        value: "Female",
                        required: "",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerSex,
                          "Female"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation,
                            "customerSex",
                            "Female"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: { for: "female" },
                      },
                      [_vm._v("Female")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.section7DemographicInformation.customerSex,
                          expression:
                            "section7DemographicInformation.customerSex",
                        },
                      ],
                      attrs: {
                        name: "sex",
                        type: "radio",
                        id: "male",
                        value: "Male",
                        required: "",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerSex,
                          "Male"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation,
                            "customerSex",
                            "Male"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: { for: "male" },
                      },
                      [_vm._v("Male")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.section7DemographicInformation.customerSex,
                          expression:
                            "section7DemographicInformation.customerSex",
                        },
                      ],
                      attrs: {
                        name: "sex",
                        type: "radio",
                        id: "sex-do-not-wish-to-provide",
                        value: "I do not wish to provide this information",
                        required: "",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerSex,
                          "I do not wish to provide this information"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation,
                            "customerSex",
                            "I do not wish to provide this information"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: { for: "sex-do-not-wish-to-provide" },
                      },
                      [_vm._v("I do not wish to provide this information")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("div", [
              _vm._m(3),
              _c("fieldset", { staticClass: "urla-radio" }, [
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerRace
                              .race,
                          expression:
                            "section7DemographicInformation.customerRace.race",
                        },
                      ],
                      attrs: {
                        name: "race",
                        type: "radio",
                        id: "american-indian-or-alaska-native",
                        value: "American Indian or Alaska Native",
                        required: "",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerRace.race,
                          "American Indian or Alaska Native"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation.customerRace,
                            "race",
                            "American Indian or Alaska Native"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: { for: "american-indian-or-alaska-native" },
                      },
                      [_vm._v("American Indian or Alaska Native")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerRace
                              .race,
                          expression:
                            "section7DemographicInformation.customerRace.race",
                        },
                      ],
                      attrs: {
                        name: "race",
                        type: "radio",
                        id: "asian",
                        value: "Asian",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerRace.race,
                          "Asian"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation.customerRace,
                            "race",
                            "Asian"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        attrs: { for: "asian" },
                      },
                      [_vm._v("Asian")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerRace
                              .race,
                          expression:
                            "section7DemographicInformation.customerRace.race",
                        },
                      ],
                      attrs: {
                        name: "race",
                        type: "radio",
                        id: "black-or-african-american",
                        value: "Black or African American",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerRace.race,
                          "Black or African American"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation.customerRace,
                            "race",
                            "Black or African American"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        attrs: { for: "black-or-african-american" },
                      },
                      [_vm._v("Black or African American")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerRace
                              .race,
                          expression:
                            "section7DemographicInformation.customerRace.race",
                        },
                      ],
                      attrs: {
                        name: "race",
                        type: "radio",
                        id: "native-hawaiian-or-other-pacific-islander",
                        value: "Native Hawaiian or Other Pacific Islander",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerRace.race,
                          "Native Hawaiian or Other Pacific Islander"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation.customerRace,
                            "race",
                            "Native Hawaiian or Other Pacific Islander"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: {
                          for: "native-hawaiian-or-other-pacific-islander",
                        },
                      },
                      [_vm._v("Native Hawaiian or Other Pacific Islander")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerRace
                              .race,
                          expression:
                            "section7DemographicInformation.customerRace.race",
                        },
                      ],
                      attrs: {
                        name: "race",
                        type: "radio",
                        id: "white",
                        value: "White",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerRace.race,
                          "White"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation.customerRace,
                            "race",
                            "White"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        attrs: { for: "white" },
                      },
                      [_vm._v("White")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section7DemographicInformation.customerRace
                              .race,
                          expression:
                            "section7DemographicInformation.customerRace.race",
                        },
                      ],
                      attrs: {
                        name: "race",
                        type: "radio",
                        id: "race-do-not-wish-to-provide",
                        value: "I do not wish to provide this information",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section7DemographicInformation.customerRace.race,
                          "I do not wish to provide this information"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section7DemographicInformation.customerRace,
                            "race",
                            "I do not wish to provide this information"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "font-weight-normal",
                        staticStyle: { width: "auto" },
                        attrs: { for: "race-do-not-wish-to-provide" },
                      },
                      [_vm._v("I do not wish to provide this information")]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "left-indent" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section7DemographicInformation.customerRace.race ===
                  "Asian"
                    ? _c("div", [
                        _c("hr", { staticClass: "urla-break" }),
                        _c("p", [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "asian-sub-race" },
                            },
                            [
                              _vm._v(" Asian Sub Race "),
                              _c("strong", [
                                _c(
                                  "abbr",
                                  {
                                    staticClass: "required",
                                    attrs: { title: "required" },
                                  },
                                  [_vm._v("*")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("fieldset", { staticClass: "urla-radio" }, [
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "asian-indian",
                                  value: "Asian Indian",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Asian Indian"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Asian Indian"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "asian-indian" },
                                },
                                [_vm._v("Asian Indian")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "chinese",
                                  value: "Chinese",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Chinese"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Chinese"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "chinese" },
                                },
                                [_vm._v("Chinese")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "filipino",
                                  value: "Filipino",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Filipino"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Filipino"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "filipino" },
                                },
                                [_vm._v("Filipino")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "japanese",
                                  value: "Japanese",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Japanese"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Japanese"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "japanese" },
                                },
                                [_vm._v("Japanese")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "korean",
                                  value: "Korean",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Korean"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Korean"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "korean" },
                                },
                                [_vm._v("Korean")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "vietnamese",
                                  value: "Vietnamese",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Vietnamese"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Vietnamese"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "vietnamese" },
                                },
                                [_vm._v("Vietnamese")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "asian-sub-race",
                                  type: "radio",
                                  id: "other-asian",
                                  value: "Other Asian",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Other Asian"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Other Asian"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "other-asian" },
                                },
                                [_vm._v("Other Asian")]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section7DemographicInformation.customerRace.race ===
                    "Asian" &&
                  _vm.section7DemographicInformation.customerRace.subRace ===
                    "Other Asian"
                    ? _c("div", { staticClass: "left-indent" }, [
                        _c(
                          "p",
                          { staticClass: "col-6 col-12-xsmall left-indent" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "hidden-label",
                                attrs: { for: "other-asian-group" },
                              },
                              [
                                _c("span", [_vm._v("Please specify:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.otherAsian,
                                  expression: "otherAsian",
                                },
                              ],
                              staticClass: "medium-input",
                              attrs: {
                                id: "other-asian-group",
                                type: "text",
                                placeholder: "e.g. Thai",
                                required: "",
                              },
                              domProps: { value: _vm.otherAsian },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.otherAsian = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section7DemographicInformation.customerRace.race ===
                  "Native Hawaiian or Other Pacific Islander"
                    ? _c("div", [
                        _c("hr", { staticClass: "urla-break" }),
                        _c("p", [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "native-hawaiian-sub-race" },
                            },
                            [
                              _vm._v(" Native Hawaiian Sub Race "),
                              _c("strong", [
                                _c(
                                  "abbr",
                                  {
                                    staticClass: "required",
                                    attrs: { title: "required" },
                                  },
                                  [_vm._v("*")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("fieldset", { staticClass: "urla-radio" }, [
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "native-hawaiian-sub-race",
                                  type: "radio",
                                  id: "native-hawaiian",
                                  value: "Native Hawaiian",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Native Hawaiian"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Native Hawaiian"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "native-hawaiian" },
                                },
                                [_vm._v("Native Hawaiian")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "native-hawaiian-sub-race",
                                  type: "radio",
                                  id: "guamanian-or-chamorro",
                                  value: "Guamanian or Chamorro",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Guamanian or Chamorro"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Guamanian or Chamorro"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "guamanian-or-chamorro" },
                                },
                                [_vm._v("Guamanian or Chamorro")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "native-hawaiian-sub-race",
                                  type: "radio",
                                  id: "samoan",
                                  value: "Samoan",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Samoan"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Samoan"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "samoan" },
                                },
                                [_vm._v("Samoan")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section7DemographicInformation
                                        .customerRace.subRace,
                                    expression:
                                      "\n                        section7DemographicInformation.customerRace.subRace\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "native-hawaiian-sub-race",
                                  type: "radio",
                                  id: "other-pacific-islander",
                                  value: "Other Pacific Islander",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section7DemographicInformation
                                      .customerRace.subRace,
                                    "Other Pacific Islander"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section7DemographicInformation
                                        .customerRace,
                                      "subRace",
                                      "Other Pacific Islander"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "other-pacific-islander" },
                                },
                                [_vm._v("Other Pacific Islander")]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section7DemographicInformation.customerRace.race ===
                    "Native Hawaiian or Other Pacific Islander" &&
                  _vm.section7DemographicInformation.customerRace.subRace ===
                    "Other Pacific Islander"
                    ? _c("div", { staticClass: "left-indent" }, [
                        _c(
                          "p",
                          { staticClass: "col-6 col-12-xsmall left-indent" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "hidden-label",
                                attrs: { for: "other-pacific-islander-race" },
                              },
                              [
                                _c("span", [_vm._v("Please specify:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.otherPacificIslander,
                                  expression: "otherPacificIslander",
                                },
                              ],
                              staticClass: "medium-input",
                              attrs: {
                                id: "other-pacific-islander-race",
                                type: "text",
                                placeholder: "e.g. Fijian",
                                required: "",
                              },
                              domProps: { value: _vm.otherPacificIslander },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.otherPacificIslander = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "button",
            {
              staticClass: "primary wizard__back pull-left",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goBack()
                },
              },
            },
            [_vm._m(4)]
          ),
          _vm._m(5),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("label", { attrs: { for: "ethnicity" } }, [
        _vm._v(" Ethnicity: "),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("label", { attrs: { for: "sex" } }, [
        _vm._v(" Sex: "),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("label", { attrs: { for: "race" } }, [
        _vm._v(" Race: "),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-arrow-left fa-lg" }),
      _vm._v(" back "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "primary wizard__next pull-right",
        attrs: { type: "submit" },
      },
      [
        _c("span", [
          _c("i", {
            staticClass: "fa fa-floppy-o",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" save "),
          _c("i", {
            staticClass: "fa fa-arrow-right fa-lg",
            attrs: { "aria-hidden": "true" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }