var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "contact-wrapper" } }, [
    _c("div", { staticClass: "inner" }, [
      _c("section", { staticClass: "info" }, [
        _c("h3", [_vm._v("About Us")]),
        _c("div", { staticClass: "about" }, [
          _c("p", [
            _vm._v(
              " American Smart Lending is a leading mortgage brokerage that is built primarily with a focus on providing customers with lowest possible mortgage rates with unmatched customer service "
            ),
          ]),
          _c("div", { staticClass: "licence" }, [
            _c("h3", [_vm._v("License Information")]),
            _c(
              "p",
              [
                _c("strong", { staticClass: "name" }, [
                  _vm._v("American Smart Lending"),
                ]),
                _vm._v(
                  " Nationwide Multistate Licensing System(NMLS) # 1456732 "
                ),
                _c(
                  "router-link",
                  { attrs: { to: { name: "LicensingInfo" } } },
                  [
                    _c("h4", [
                      _c("img", {
                        staticClass: "vue-photo-thumb",
                        staticStyle: { width: "300px", height: "160px" },
                        style: _vm.thumbStyle,
                        attrs: {
                          src: require("@/assets/images/usa_map.jpg"),
                          nopin: "nopin",
                          alt: "",
                        },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.updateHoverState(true)
                          },
                          mouseleave: function ($event) {
                            return _vm.updateHoverState(false)
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._m(0),
          _vm._m(1),
        ]),
        _vm._m(2),
      ]),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "licence" }, [
      _c("p", [
        _c("strong", { staticClass: "name" }, [_vm._v("Hours of Operation")]),
        _c("br"),
        _vm._v("Mon to Sat 9:00AM - 6:00PM "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "file-upload" }, [
      _c("strong", { staticClass: "name" }, [_vm._v("File upload location:")]),
      _c("br"),
      _c(
        "a",
        {
          attrs: {
            href: "https://americansmartlending.com/upload",
            target: "_blank",
          },
        },
        [_vm._v("https://americansmartlending.com/upload")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team" }, [
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/kc.jpeg"), alt: "" },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("KC Kadaru")]),
          _c("span", { staticClass: "title" }, [_vm._v("President and CEO")]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #1168294")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/doug-tuckett.jpg"),
              alt: "",
            },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Doug Tuckett")]),
          _c("span", { staticClass: "title" }, [
            _vm._v("Chief Mortgage Officer"),
          ]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #304633")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/harry-dunmire.jpg"),
              alt: "",
            },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Harry Dunmire")]),
          _c("span", { staticClass: "title" }, [_vm._v("Vice President")]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #258067")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/pradip.jpeg"), alt: "" },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Pradip Thakkar")]),
          _c("span", { staticClass: "title" }, [
            _vm._v("Sr. Mortgage Consultant"),
          ]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #750779")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/ramesh.jpg"), alt: "" },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Ramesh Menon")]),
          _c("span", { staticClass: "title" }, [
            _vm._v("Sr. Mortgage Consultant"),
          ]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #1999524")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/venu.jpeg"), alt: "" },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Venu Arety")]),
          _c("span", { staticClass: "title" }, [_vm._v("Mortgage Consultant")]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #2331228")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/AnjaniSattiraju.jpeg"),
              alt: "",
            },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Anjani Sattiraju")]),
          _c("span", { staticClass: "title" }, [_vm._v("Mortgage Consultant")]),
          _c("br"),
          _c("span", { staticClass: "title" }, [_vm._v("NMLS #2300014")]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/mahesh.jpeg"), alt: "" },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("MK Jalli")]),
          _c("span", { staticClass: "title" }, [
            _vm._v("Director of Operations and Market Research"),
          ]),
        ]),
      ]),
      _c("article", [
        _c("span", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/sitara.jpeg"), alt: "" },
          }),
        ]),
        _c("p", [
          _c("strong", { staticClass: "name" }, [_vm._v("Sitara Jagannath")]),
          _c("span", { staticClass: "title" }, [
            _vm._v("Director of Funding Operations and Post-Closing"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "contact" }, [
      _c("h3", [_vm._v("Contact Us")]),
      _c("ul", { staticClass: "contact-icons" }, [
        _c("li", { staticClass: "icon fa-home" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://goo.gl/maps/wXxVgidiQs3N17RPA",
                target: "_blank",
              },
            },
            [
              _vm._v(" Kadlogic Inc DBA American Smart Lending"),
              _c("br"),
              _vm._v(" ISAOA ATIMA "),
              _c("br"),
              _vm._v(" 1560 E.Southlake Blvd Ste.100"),
              _c("br"),
              _vm._v(" Southlake , TX, 76092"),
              _c("br"),
            ]
          ),
        ]),
        _c("li", { staticClass: "icon fa-phone" }, [
          _c("a", { attrs: { href: "tel:469-525-6725" } }, [
            _vm._v("Office: +1(469)525-6725"),
          ]),
        ]),
        _c("li", { staticClass: "icon fa-fax" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Fax: (888)506-9603")]),
        ]),
        _c("li", { staticClass: "icon fa-envelope" }, [
          _c("a", { attrs: { href: "mailto:info@AmericanSmartLending.com" } }, [
            _vm._v("info@AmericanSmartLending.com"),
          ]),
        ]),
        _c("li", { staticClass: "icon fa-facebook" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("facebook.com/tslg")]),
        ]),
        _c("li", { staticClass: "icon fa-twitter" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("twitter.com/tslg")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }