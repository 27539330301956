var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wizard" }, [
    _c(
      "ul",
      { staticClass: "wizard__steps" },
      _vm._l(_vm.steps, function (step, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "wizard__step",
            class: {
              active: _vm.isMobile
                ? _vm.currentStep === index
                : _vm.currentStep >= index,
              "vgw-mobile": _vm.isMobile,
            },
            style: _vm.wizardStepStyle,
          },
          [
            _c("span", {
              staticClass: "wizard__step__line",
              class: { "vgw-mobile": _vm.isMobile },
            }),
            _c("span", { staticClass: "wizard__step__indicator" }),
          ]
        )
      }),
      0
    ),
    _c("span", {
      staticClass: "wizard__arrow",
      style: { left: _vm.arrowPosition },
    }),
    _c(
      "div",
      {
        ref: "wizard-body",
        staticClass: "wizard__body",
        class: { "vgw-mobile": _vm.isMobile },
      },
      [
        _c(
          "div",
          { key: _vm.currentSlot, staticClass: "wizard__body__step" },
          [_vm._t(_vm.currentSlot)],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }