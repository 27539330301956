var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c("section", [
            _c("h1", [_vm._v("Mortgage Process Jargon")]),
            _c("h3", [_vm._v("Adjustable-Rate Mortgage (ARM)")]),
            _c("p", [
              _vm._v(
                "Mortgage in which the rate of interest is adjusted based on a standard rate index. Most ARM’s have a cap on how much the rate may increase"
              ),
            ]),
            _c("h3", [_vm._v("Amortization")]),
            _c("p", [
              _vm._v(
                "The process through which the mortgage debt is altered, usually declining, as payments are made to the lender. “Negative-amortization” occurs when monthly payments are too small to cover either the principal or interest reductions"
              ),
            ]),
            _c("h3", [_vm._v("Annual Percentage Rate (APR)")]),
            _c("p", [
              _vm._v(
                "The rate of interest to be paid on a loan projected life; sometimes referred to as the “true” rate of interest"
              ),
            ]),
            _c("h3", [_vm._v("Appraisal")]),
            _c("p", [
              _vm._v(
                "A professional evaluation of the value of a home or other piece of property. It is often required by the lender"
              ),
            ]),
            _c("h3", [_vm._v("Balloon Mortgage")]),
            _c("p", [
              _vm._v(
                "A real estate loan in which some portion of the debt will remain unpaid at the end of the term of the loan. A balloon will usually result in a single large payment due when the loan ends"
              ),
            ]),
            _c("h3", [_vm._v("Cap")]),
            _c("p", [
              _vm._v(
                "A limit on how much a mortgage interest rate may increase or decrease for an adjustable rate mortgage"
              ),
            ]),
            _c("h3", [_vm._v("Conventional Mortgage")]),
            _c("p", [
              _vm._v(
                "A mortgage loan with terms and conditions that meet funding criteria of Fannie Mae and Freddie Mac. Rates can be fixed or adjustable. Also known as a conforming loan"
              ),
            ]),
            _c("h3", [_vm._v("Debt-To-Income Ratio")]),
            _c("p", [
              _vm._v(
                "A ratio used by lending institutions to determine whether a person is qualified for a mortgage. Debt-to-income is the total amount of debt, including credit cards and other loans, divided by the total gross monthly income"
              ),
            ]),
            _c("h3", [_vm._v("Default")]),
            _c("p", [
              _vm._v(
                "Failure to pay the mortgage payments over a specified period of time"
              ),
            ]),
            _c("h3", [_vm._v("Discount Points")]),
            _c("p", [
              _vm._v(
                "A percentage of the mortgage paid to the lender to lower the interest rate on a loan. One point equals one percent of the mortgage"
              ),
            ]),
            _c("h3", [_vm._v("Equity")]),
            _c("p", [
              _vm._v(
                "The difference between the market value of a house and the amount still owed on the mortgage"
              ),
            ]),
            _c("h3", [_vm._v("Escrow")]),
            _c("p", [
              _vm._v(
                "Money and documents deposited in a trust account to be held by one party for another. Often used by brokers to hold deposit money prior to closing. Also used by lenders to hold money for taxes and insurance on a home"
              ),
            ]),
            _c("h3", [_vm._v("FHA Loan")]),
            _c("p", [
              _vm._v(
                "A loan guaranteed by the Federal Housing Administration and which has a mandatory mortgage insurance. It should not need to use this product unless you want to do less than 5% down"
              ),
            ]),
            _c("h3", [_vm._v("Loan-To-Value Ratio (LTV)")]),
            _c("p", [
              _vm._v(
                "In the case of a purchase it is the amount of the loan divided by the purchase price or appraisal value of the house whichever is lower. In the situation of a refinance, the loan is divided by appraised value"
              ),
            ]),
            _c("h3", [_vm._v("Margin")]),
            _vm._v(
              "A set number of percentage points a lender adds to the index to determine the interest rate for an ARM "
            ),
            _c("h3", [_vm._v("Mortgage Insurance (MI)")]),
            _vm._v(
              "Insurance designed to cover the lender should the borrower default on the loan. if the loan to value is less than 80, this is mandatory "
            ),
            _c("h3", [_vm._v("PITI")]),
            _vm._v(
              "PITI stands for principal, interest, taxes, and insurance "
            ),
            _c("h3", [_vm._v("Points")]),
            _vm._v(
              "An interest fee charged by the lender. One point is equal to one percent of the mortgage "
            ),
            _c("h3", [_vm._v("Prepayment Penalty")]),
            _vm._v(
              "A fee imposed on a borrower who pays off a mortgage before it is due. We have ZERO pre-payment penalty on all of our first loans "
            ),
            _c("h3", [_vm._v("Principal")]),
            _vm._v("The amount of the loan "),
            _c("h3", [_vm._v("Second Mortgage")]),
            _vm._v(
              "An additional mortgage on a property. It often carries a shorter term and a higher interest rate than the first mortgage "
            ),
            _c("h3", [_vm._v("Title Company")]),
            _vm._v(
              "A company that searches for titles and insurance claims. Your loan will close at a title company "
            ),
            _c("h3", [_vm._v("Truth in Lending Act")]),
            _vm._v(
              "A federal law that requires lenders to reveal all the terms of the mortgage "
            ),
            _c("h3", [_vm._v("VA")]),
            _vm._v(
              "A VA loan is a lower cost mortgage loan guaranteed by the US Department of Veterans Affairs (VA). It is designed to provide long-term financing to eligible American veterans or their surviving spouses (provided they do not remarry) "
            ),
          ]),
          _c("section", { staticClass: "spotlights alt" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }