var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "rounded",
    },
    [
      _c("div", { staticClass: "v-spinner" }, [
        _c("div", { staticClass: "v-moon v-moon1", style: _vm.spinnerStyle }, [
          _c("div", {
            staticClass: "v-moon v-moon2",
            style: [_vm.spinnerMoonStyle, _vm.animationStyle2],
          }),
          _c("div", {
            staticClass: "v-moon v-moon3",
            style: [_vm.spinnerStyle, _vm.animationStyle3],
          }),
        ]),
      ]),
      _c("p", { staticClass: "loading-message" }, [
        _vm._v(_vm._s(_vm.loaderMessage) + "..."),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }