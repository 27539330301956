var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "sectionEight", staticClass: "urla-section" }, [
    _c("h2", [_vm._v("Loan Originator Information")]),
    _c("p", { staticClass: "urla-section-description" }, [
      _vm._v(" This section asks about Loan Originator and Reference. "),
    ]),
    _vm._m(0),
    _c(
      "form",
      {
        attrs: { id: "section-8-form", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.nextPage.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "loan-originator row" }, [
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _vm._m(1),
            _c("fieldset", { staticClass: "urla-radio" }, [
              _c("ul", [
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "kc",
                      value: "KC",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "KC"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "KC"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "kc" },
                    },
                    [_vm._v("KC Kadaru")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "doug",
                      value: "Doug",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "Doug"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "Doug"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "doug" },
                    },
                    [_vm._v("Doug Tuckett")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "harry",
                      value: "Harry",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "Harry"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "Harry"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "harry" },
                    },
                    [_vm._v("Harry Dunmire")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "pradip",
                      value: "Pradip",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "Pradip"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "Pradip"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "pradip" },
                    },
                    [_vm._v("Pradip Thakkar")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "ramesh",
                      value: "Ramesh",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "Ramesh"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "Ramesh"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "ramesh" },
                    },
                    [_vm._v("Ramesh Menon")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "anjani",
                      value: "Anjani",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "Anjani"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "Anjani"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "anjani" },
                    },
                    [_vm._v("Anjani Sattiraju")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section8LoanOriginatorInformation
                            .loanOriginatorName,
                        expression:
                          "section8LoanOriginatorInformation.loanOriginatorName",
                      },
                    ],
                    attrs: {
                      name: "loan-officer",
                      type: "radio",
                      id: "venu",
                      value: "Venu",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section8LoanOriginatorInformation
                          .loanOriginatorName,
                        "Venu"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section8LoanOriginatorInformation,
                          "loanOriginatorName",
                          "Venu"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-normal",
                      staticStyle: { width: "auto" },
                      attrs: { for: "venu" },
                    },
                    [_vm._v("Venu Arety")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("p", { staticClass: "reffered-by-heading" }, [
              _vm._v("Referred By:"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.section8LoanOriginatorInformation.referredBy,
                  expression: "section8LoanOriginatorInformation.referredBy",
                },
              ],
              attrs: { type: "text", placeholder: "E.g. Elon Musk, Google" },
              domProps: {
                value: _vm.section8LoanOriginatorInformation.referredBy,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.section8LoanOriginatorInformation,
                    "referredBy",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "button",
            {
              staticClass: "primary wizard__back pull-left",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goBack()
                },
              },
            },
            [_vm._m(2)]
          ),
          _vm._m(3),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("label", { attrs: { for: "loan-officer" } }, [
        _vm._v(" Loan Officer: "),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-arrow-left fa-lg" }),
      _vm._v(" back "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "primary wizard__next pull-right",
        attrs: { type: "submit" },
      },
      [
        _c("span", [
          _vm._v(" next "),
          _c("i", {
            staticClass: "fa fa-arrow-right fa-lg",
            attrs: { "aria-hidden": "true" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }