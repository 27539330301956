var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "wrapper" }, [
      _c("section", { staticClass: "main" }, [
        _c(
          "section",
          { attrs: { id: "banner" } },
          [_c("slider", { attrs: { images: _vm.images } })],
          1
        ),
        _vm._m(0),
        _c("section", { staticClass: "features alt" }, [
          _c(
            "article",
            { staticClass: "icon fa-diamond" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "popular-loan-programs" } } },
                [_c("h3", [_vm._v("our popular loan programs")])]
              ),
            ],
            1
          ),
          _c(
            "article",
            { staticClass: "icon fa-floppy-o" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "steps-in-mortgage-process" } } },
                [_c("h3", [_vm._v("steps in the mortgage process")])]
              ),
              _c("p", [
                _vm._v(
                  "This article describing the steps in the mortgage process is most appropriate light reading for a first time home buyer"
                ),
              ]),
            ],
            1
          ),
          _c(
            "article",
            { staticClass: "icon fa-laptop" },
            [
              _c("router-link", { attrs: { to: { name: "faq" } } }, [
                _c("h3", [_vm._v("FAQ")]),
              ]),
              _c("p", [
                _vm._v(
                  "frequently asked questions while navigating through the mortgage process"
                ),
              ]),
            ],
            1
          ),
          _c(
            "article",
            { staticClass: "icon fa-paper-plane" },
            [
              _c("router-link", { attrs: { to: { name: "dos-and-donts" } } }, [
                _c("h3", [_vm._v("Do and Donts")]),
              ]),
              _c("p", [
                _vm._v(
                  "Here are the important Dos and DONTs for a customer looking for a mortgage for a primary residence, secondary residence, investment home and land loans"
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("section", { staticClass: "spotlights alt" }, [
          _c("article", [
            _c(
              "a",
              {
                staticClass: "image fit",
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "docs-for-processing" })
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/MortgageApplicationApproved.jpeg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c("h3", [_vm._v("Documents needed for loan processing")]),
            _c("p", [
              _vm._v(
                "Please click the link below to get the list of documents that we need for the mortgage processing. We would like to gather the complete and comprehensive list of documents required for the loan before we submit the loan application. Submitting a complete list to the underwriter results in lesser number of touch points and a quicker processing time and better results"
              ),
            ]),
            _c("ul", { staticClass: "actions" }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button",
                      attrs: { to: { name: "docs-for-processing" } },
                    },
                    [_vm._v("Click here for the detailed list")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("article", [
            _c(
              "a",
              {
                staticClass: "image fit",
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "mortgage-definitions" })
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/MortgageJargon.jpeg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c("h3", [_vm._v("Mortgage Loan Process Glossary")]),
            _c("p", [
              _vm._v(
                "We understand that the jargon in the mortgage industry can be confusing and confounding for the first time home buyer as well as someone who has done a transaction a few years ago. We want to help you understand and navigate the process with confidence. Click the link below to learn the definitions of the most frequently used terms"
              ),
            ]),
            _c("ul", { staticClass: "actions" }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button",
                      attrs: { to: { name: "mortgage-definitions" } },
                    },
                    [_vm._v("Learn More")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._m(1),
          _vm._m(2),
        ]),
      ]),
      _vm._m(3),
    ]),
    _c("div", { staticClass: "home-footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "quick-links" }, [
          _c("h4", [_vm._v("Quick Links")]),
          _c("ul", { staticClass: "quickLinks-icons" }, [
            _vm._m(4),
            _vm._m(5),
            _c("li", [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "amortization-calculator",
                      })
                    },
                  },
                },
                [_vm._v(" Amortization Calculator ")]
              ),
            ]),
          ]),
        ]),
        _vm._m(6),
        _vm._m(7),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c("h1", [_vm._v("Best possible mortgage experience with us")]),
      _c("p", [
        _vm._v(
          "American Smart Lending and its professionals are truly dedicated to delivering the best possible outcomes to your mortgage process. We are the best at suggesting customized loan programs to your specific needs based on the financial profile, needs and goals."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("article", [
      _c("a", { staticClass: "image fit" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/MortgageProfessor.jpeg"),
            alt: "",
          },
        }),
      ]),
      _c("h3", [_vm._v("Remove the complexity from mortgage")]),
      _c("p", [
        _vm._v(
          "We strive hard to make the whole mortgage process simple and easy. We make it easy to understand the quotes, deal with the multiple parties involved in the process and close the transaction on time."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("article", [
      _c("h3", [_vm._v("How to make Lending Process Smart?")]),
      _c("p", [
        _vm._v(
          "Our goal in entering the mortgage industry is to bring the smart into the mortgage process. We strive to make it smart in pricing, processing, people and technology to make things more efficient and cost effective"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("aside", { staticClass: "sidebar" }, [
      _c("section", [
        _c("h2", [_vm._v("Why choose American Smart Lending")]),
        _c("p", [
          _vm._v(
            "We strive to produce the best possible results for you while valuing your time."
          ),
        ]),
        _c("ul", [
          _c("li", [_vm._v("Lowest possible interest rates")]),
          _c("li", [_vm._v("Lowest possible closing cost")]),
          _c("li", [_vm._v("100% on-time closing from our side")]),
          _c("li", [_vm._v("Daily status updates on your loan")]),
          _c("li", [_vm._v("Efficient use of technology")]),
          _c("li", [_vm._v("Smooth execution")]),
        ]),
      ]),
      _c("section", { staticClass: "fheo-container" }, [
        _c("div", { staticClass: "fheo-left" }, [
          _c("span", { staticClass: "fheo-image" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/fheo-logo-80.png"),
                alt: "",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "fheo-right" }, [
          _c("p", [
            _vm._v(
              "American Smart Lending is an Equal Housing Opportunity Lender."
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "featured-posts" }, [
        _c("h2", [_vm._v("Refinancing your primary and investment homes")]),
        _c("article", [
          _c("a", { staticClass: "image fit" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/RefinanceYourMortgage.jpeg"),
                alt: "",
              },
            }),
          ]),
          _c("header", [
            _c("span", { staticClass: "date" }, [_vm._v("September 19, 2016")]),
            _c("h3", [
              _c("a", { attrs: { href: "#" } }, [
                _vm._v("our edge in refinancing"),
              ]),
            ]),
          ]),
          _c("p", [
            _vm._v(
              "we are constantly on the watch for lower interest rates for our customers who have purchase primary homes and investment homes through us. We take pride in saving our customers thousands of dollars while helping truly own their home by paying off the mortgage by rolling into lower interest payment programs, lower interest rate programs and shorter duration fixed loan programs."
            ),
          ]),
        ]),
      ]),
      _c("section", [
        _c("h2", [_vm._v("Recent Mortgage related News")]),
        _c("ul", { staticClass: "posts" }, [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.forbes.com/sites/johnkosar/2018/10/01/get-ready-for-a-big-increase-in-interest-rates/#3805c50d6c71",
                },
              },
              [_vm._v("Interest rates are rising fast")]
            ),
            _c("span", { staticClass: "date" }, [_vm._v("November 15, 2018")]),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.nytimes.com/2018/11/08/us/politics/fed-midterm-elections.html",
                },
              },
              [_vm._v("Fed is poised to raise rates in december 2018")]
            ),
            _c("span", { staticClass: "date" }, [_vm._v("November 15, 2018")]),
          ]),
        ]),
        _c("ul", { staticClass: "actions" }, [
          _c("li", [
            _c("a", { staticClass: "button", attrs: { href: "#" } }, [
              _vm._v("More Posts"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "http://www.consumerfinance.gov/f/201503_cfpb_your-home-loan-toolkit-web.pdf",
            target: "_blank",
          },
        },
        [_vm._v(" Home Loan Toolkit ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.nmlsconsumeraccess.org/",
            target: "_blank",
          },
        },
        [_vm._v(" NMLS Consumer Access ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "follow-us" }, [
      _c("h4", [_vm._v("Follow Us")]),
      _c("ul", { staticClass: "followUs-icons" }, [
        _c("li", { staticClass: "icon fa-facebook" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("facebook.com/tslg")]),
        ]),
        _c("li", { staticClass: "icon fa-twitter" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("twitter.com/tslg")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact" }, [
      _c("h4", [_vm._v("Contact Us")]),
      _c("ul", { staticClass: "contact-icons" }, [
        _c("li", { staticClass: "icon fa-home" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://goo.gl/maps/wXxVgidiQs3N17RPA",
                target: "_blank",
              },
            },
            [
              _vm._v(" Kadlogic Inc DBA American Smart Lending"),
              _c("br"),
              _vm._v(" ISAOA ATIMA "),
              _c("br"),
              _vm._v(" 1560 E.Southlake Blvd Ste.100"),
              _c("br"),
              _vm._v(" Southlake , TX, 76092"),
              _c("br"),
            ]
          ),
        ]),
        _c("li", { staticClass: "icon fa-phone" }, [
          _c("a", { attrs: { href: "tel:469-525-6725" } }, [
            _vm._v("Office: +1(469)525-6725"),
          ]),
        ]),
        _c("li", { staticClass: "icon fa-fax" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Fax: (888)506-9603")]),
        ]),
        _c("li", { staticClass: "icon fa-envelope" }, [
          _c("a", { attrs: { href: "mailto:info@AmericanSmartLending.com" } }, [
            _vm._v("info@AmericanSmartLending.com"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }