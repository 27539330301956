var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "folder__box" },
    _vm._l(_vm.nodes, function (node) {
      return _c(
        "div",
        {
          key: node.id,
          staticClass: "folder__child",
          attrs: { "data-node-id": node.id },
        },
        [
          _c(
            "div",
            {
              staticClass: "folder__details",
              class: { highlight_selection: node.isSelectionEnabled },
              on: {
                click: function ($event) {
                  return _vm.selectFolder($event, node)
                },
                dblclick: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleDoubleClick(node)
                },
              },
            },
            [
              _c("div", { staticClass: "folder__icon" }, [
                _c(
                  "div",
                  {
                    staticClass: "folder__arrow",
                    class:
                      node.nodes.length > 0 && node.type == "Folder"
                        ? "show__arrow"
                        : "hide__arrow",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectArrow(node)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa",
                      class: node.isActive ? "fa-angle-down" : "fa-angle-right",
                    }),
                  ]
                ),
                node.type == "File" && node.isSelectionEnabled == true
                  ? _c("div", { staticClass: "file__info" })
                  : _vm._e(),
                node.type === "Folder"
                  ? _c("div", { staticClass: "folder__info" }, [
                      _c("div", [
                        !node.isActive || node.nodes.length == 0
                          ? _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "28",
                                  height: "28",
                                  fill: "currentColor",
                                  viewBox: "0 0 512 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z",
                                  },
                                }),
                              ]
                            )
                          : _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "30",
                                  height: "30",
                                  fill: "currentColor",
                                  viewBox: "0 0 576 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z",
                                  },
                                }),
                              ]
                            ),
                      ]),
                      _c("div", { staticClass: "folder__name" }, [
                        _c("p", [_vm._v(_vm._s(_vm.setProperName(node.name)))]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(node.fileCount) +
                              " " +
                              _vm._s(node.fileCount == 1 ? "File" : "Files") +
                              _vm._s(_vm.subFolderDetails(node)) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                node.type == "File"
                  ? _c(
                      "div",
                      { staticClass: "file__info" },
                      [_c("FileItem", { attrs: { fileInfo: node } })],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            { class: { hide__child: !node.isActive }, attrs: { open: "" } },
            [
              node.nodes
                ? _c("FolderItem", {
                    attrs: {
                      nodes: node.nodes,
                      isContextEnabled: _vm.isContextEnabled,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }