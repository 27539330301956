var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-photo-collage-wrapper" },
    [
      _c("photo-collage", {
        attrs: {
          disabled: _vm.disabled,
          width: _vm.width,
          height: _vm.internalHeight,
          layout: _vm.layout,
          layoutPhotoMaps: _vm.layoutPhotoMaps,
          layoutNum: _vm.layoutNum,
          remainingNum: _vm.remainingNum,
          showNumOfRemainingPhotos: _vm.showNumOfRemainingPhotos,
        },
        on: { itemClick: () => _vm.$emit("itemClick") },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }