var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "main row" }, [
      _c("div", { staticClass: "left-section col-5 col-12-medium" }, [
        _c(
          "div",
          [
            _c("moon-loader", {
              staticClass: "moon-loader",
              attrs: {
                loading: _vm.isLoading,
                color: _vm.loaderColor,
                size: _vm.loaderSize,
                loaderMessage: _vm.loaderMessage,
              },
            }),
            _c(
              "div",
              { staticClass: "file-explorer" },
              [
                _c("folderview-panel", {
                  attrs: {
                    isInitialLoadingDone: _vm.isInitialLoadingDone,
                    isFilesUploaded: _vm.isFilesUploaded,
                    isLoading: _vm.isLoading,
                    uploadedFilesList: _vm.uploadedFilesList,
                    existingFiles: _vm.existingFiles,
                  },
                  on: { "file-selected": _vm.displayFilePreview },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "right-section col-7 col-12-medium" }, [
        !_vm.isHandHeldDevice
          ? _c("div", { staticClass: "tabs-bar" }, [
              _c("div", { staticClass: "tab-selection" }, [
                _c(
                  "div",
                  {
                    staticClass: "tabs",
                    class: _vm.isActiveTab ? "show__tab" : "hide__tab",
                    attrs: { id: "uploaded-documents" },
                    on: { click: _vm.tabChangeClick },
                  },
                  [_vm._v(" Preview File ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tabs",
                    class: !_vm.isActiveTab ? "show__tab" : "hide__tab",
                    attrs: { id: "upload-new" },
                    on: { click: _vm.tabChangeClick },
                  },
                  [_vm._v(" Upload Files ")]
                ),
              ]),
              _c("hr", { staticClass: "tabs-seperation" }),
            ])
          : _vm._e(),
        _vm.isActiveTab
          ? _c(
              "div",
              { attrs: { id: "file-previewer-tab" } },
              [
                !_vm.isFilesLoaded
                  ? _c("p", { staticClass: "preview-message" }, [
                      _vm._v(" There are no files to preview "),
                    ])
                  : (_vm.imageLink == null &&
                      _vm.src == null &&
                      _vm.isFilesLoaded) ||
                    _vm.tabSelected == "Folder"
                  ? _c("p", { staticClass: "preview-message" }, [
                      _vm._v(" Click on a file from left to preview "),
                    ])
                  : _vm._e(),
                _vm.tabSelected == "File"
                  ? _c("moon-loader", {
                      staticClass: "moon-loader-pdf",
                      attrs: {
                        loading: _vm.isLoadingPdf,
                        color: _vm.loaderColor,
                        size: _vm.loaderSize,
                        loaderMessage: _vm.loaderMessagePdf,
                      },
                    })
                  : _vm._e(),
                _vm.statusPdf404 == true &&
                _vm.statusPdfNot200 == false &&
                _vm.tabSelected == "File"
                  ? _c("p", { staticClass: "preview-message" }, [
                      _vm._v(" File Not Found "),
                    ])
                  : _vm._e(),
                _vm.statusPdf404 == false &&
                _vm.statusPdfNot200 == true &&
                _vm.tabSelected == "File"
                  ? _c("p", { staticClass: "preview-message" }, [
                      _vm._v(" Unable to load the file "),
                    ])
                  : _vm._e(),
                _vm.tabSelected == "File"
                  ? _c("div", [
                      _vm.imageLink == null &&
                      _vm.currentFileFormat == "application/pdf"
                        ? _c("div", { staticClass: "pdf-viewer" }, [
                            _c("iframe", {
                              staticClass: "google-pdf-viewer",
                              attrs: { src: _vm.src, embedded: "true" },
                            }),
                          ])
                        : _vm.isFileImage
                        ? _c(
                            "div",
                            { staticClass: "img-viewer" },
                            [
                              _c("img-viewer", {
                                attrs: {
                                  isLoading: _vm.isLoading,
                                  imageUrl: _vm.src,
                                  fileName: _vm.fileNamePdf,
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div"),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        !_vm.isActiveTab
          ? _c(
              "div",
              {
                staticClass: "new-file-upload",
                class: { activeSection: !_vm.isActiveTab },
              },
              [
                _c(
                  "fieldset",
                  { attrs: { disabled: !_vm.isFileUploadFormEnabled } },
                  [
                    _c(
                      "form",
                      {
                        attrs: { id: "file-upload", method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createLinkAndUploadFiles.apply(
                              null,
                              arguments
                            )
                          },
                          drop: function ($event) {
                            $event.preventDefault()
                            return _vm.addDroppedFiles.apply(null, arguments)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "drop-zone-wrapper" }, [
                          _c("div", { staticClass: "upload" }, [
                            _c(
                              "div",
                              {
                                staticClass: "select-folder-message",
                                class: { shake: _vm.showWarning },
                              },
                              [_vm._v(" " + _vm._s(_vm.folderMessage) + " ")]
                            ),
                            _c("label", { attrs: { for: "drop-zone" } }, [
                              _vm._v(" Drag files here or "),
                              _c(
                                "span",
                                {
                                  staticClass: "browse__button",
                                  on: { click: _vm.displayWarning },
                                },
                                [_vm._v("Browse")]
                              ),
                            ]),
                            _c("div", [
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "input-file",
                                attrs: {
                                  hidden: "",
                                  id: "drop-zone",
                                  multiple: "",
                                  type: "file",
                                  disabled: _vm.currentPath == null,
                                },
                                on: {
                                  change: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addSelectedFiles.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._m(0),
                          _c(
                            "div",
                            { staticClass: "wrap-error" },
                            [
                              _c("transition", { attrs: { name: "shake" } }, [
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isUnsupportedFileAdded,
                                        expression: "isUnsupportedFileAdded",
                                      },
                                    ],
                                    staticClass: "error",
                                  },
                                  [
                                    _vm._v(
                                      " Please remove unsupported file(s) "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "summary-wrapper" },
                            _vm._l(
                              _vm.filesToUpload,
                              function (fileWrapper, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "selected-file-wrapper",
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-file" }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "file",
                                        class: fileWrapper.isValid
                                          ? "valid-file"
                                          : "invalid-file",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "selected-file-info" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "file-name-str" },
                                              [_vm._v(_vm._s(fileWrapper.name))]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "file-name-size" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.bytesToSize(
                                                        fileWrapper.size
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !fileWrapper.isUploaded
                                              ? _c("i", {
                                                  staticClass: "fa fa-times",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.removeFile(
                                                        index
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "file-name-path" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.showUploadMessage(
                                                  fileWrapper
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "progress file-upload-progress",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "progress-bar progress-bar-animated",
                                              class: [
                                                fileWrapper.isUploadFailing
                                                  ? "bg-failure"
                                                  : "bg-success",
                                                fileWrapper.isUploaded
                                                  ? ""
                                                  : "progress-bar-striped",
                                              ],
                                              style: {
                                                width: fileWrapper.uploadStatus,
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "container-upload100-form-btn" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "upload100-form-btn",
                                  attrs: {
                                    type: "submit",
                                    disabled:
                                      _vm.isUnsupportedFileAdded ||
                                      _vm.isDisableUploadButton,
                                  },
                                },
                                [
                                  _vm._v(" Upload "),
                                  _c("i", {
                                    staticClass: "fa fa-cloud-upload fa-lg",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "max-size-instruction" }, [
      _c("p", [_vm._v("Max. file size: 10MB")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }