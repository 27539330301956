var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "wrapper" }, [
      _c("section", { staticClass: "main" }, [
        _c(
          "section",
          [
            _c("h1", [_vm._v("Most Recent Customer Reviews")]),
            _c("p", [
              _vm._v(
                "KC Kadaru is a licensed mortgage Broker and a licensed real estate broker in the state of texas for many many years. He has helped tens of hundreds of people buy a home, get a loan and achieve their peice of american dream. A few of the testimonials from our customers are listed below and we are in the proces of updating this page with more recent information"
              ),
            ]),
            _c("br"),
            _vm._l(_vm.reviews, function (review) {
              return _c(
                "div",
                {
                  key: review.reviewid,
                  staticClass: "box",
                  attrs: { review: review },
                },
                [
                  _c("h2", [_vm._v(_vm._s(review.headline))]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("h4", { staticClass: "col-3 col-12-medium" }, [
                        _vm._v("Local knowledge:"),
                      ]),
                      _c("star-rating", {
                        staticClass: "col-3 col-12-medium",
                        attrs: {
                          rating: review.knowledge,
                          "show-rating": true,
                          inline: true,
                          "border-width": 2,
                          "rounded-corners": true,
                          "border-color": "#2ebfac",
                          "star-size": 20,
                          "inactive-color": "#ffffff",
                          "active-color": "#2ebfac",
                          "read-only": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("h4", { staticClass: "col-3 col-12-medium" }, [
                        _vm._v("Process expertise:"),
                      ]),
                      _c("star-rating", {
                        staticClass: "col-3 col-12-medium",
                        attrs: {
                          rating: review.expertise,
                          "show-rating": true,
                          inline: true,
                          "border-width": 2,
                          "rounded-corners": true,
                          "border-color": "#2ebfac",
                          "star-size": 20,
                          "inactive-color": "#ffffff",
                          "active-color": "#2ebfac",
                          "read-only": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("h4", { staticClass: "col-3 col-12-medium" }, [
                        _vm._v("Responsiveness:"),
                      ]),
                      _c("star-rating", {
                        staticClass: "col-3 col-12-medium",
                        attrs: {
                          rating: review.responsiveness,
                          "show-rating": true,
                          inline: true,
                          "border-width": 2,
                          "rounded-corners": true,
                          "border-color": "#2ebfac",
                          "star-size": 20,
                          "inactive-color": "#ffffff",
                          "active-color": "#2ebfac",
                          "read-only": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("h4", { staticClass: "col-3 col-12-medium" }, [
                        _vm._v("Negotiation:"),
                      ]),
                      _c("star-rating", {
                        staticClass: "col-3 col-12-medium",
                        attrs: {
                          rating: review.negotiation,
                          "show-rating": true,
                          inline: true,
                          "border-width": 2,
                          "rounded-corners": true,
                          "border-color": "#2ebfac",
                          "star-size": 20,
                          "inactive-color": "#ffffff",
                          "active-color": "#2ebfac",
                          "read-only": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "note" }, [
                    _vm._v(" " + _vm._s(review.note) + " "),
                    _c("h4", { staticClass: "row" }, [
                      _c("span", { staticClass: "col-9 col-12-medium" }),
                      _vm._v(" - " + _vm._s(review.userName) + " "),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }