var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-photo-collage",
      class: [_vm.disabled && "vue-photo-collage--disabled"],
      style: _vm.photoCollageStyle,
    },
    _vm._l(_vm.layout, function (data, i) {
      return _c("row-photos", {
        key: i,
        attrs: {
          height: _vm.height[i],
          photos: _vm.layoutPhotoMaps[i],
          layoutNum: _vm.layoutNum,
          remainingNum: _vm.remainingNum,
          showNumOfRemainingPhotos: _vm.showNumOfRemainingPhotos,
        },
        on: { itemClick: () => !_vm.disabled && _vm.$emit("itemClick") },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }