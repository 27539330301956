var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("footer", { attrs: { id: "footer" } }, [
      _c("div", { staticClass: "copyright" }, [
        _c("span", [
          _vm._v(
            " © American Smart Lending " +
              _vm._s(new Date().getFullYear()) +
              " "
          ),
        ]),
        _c("span", { staticClass: "app-version" }, [
          _vm._v("   v2024.12.31.1 "),
        ]),
        _c("div", { staticClass: "footer-text" }, [
          _c(
            "p",
            [
              _c("router-link", { attrs: { to: { name: "disclosure" } } }, [
                _vm._v("Disclosure"),
              ]),
              _c("span", { staticClass: "seperator" }, [_vm._v("|")]),
              _c("router-link", { attrs: { to: { name: "contact" } } }, [
                _vm._v("Licensing information"),
              ]),
              _c("span", { staticClass: "seperator" }, [_vm._v("|")]),
              _c("router-link", { attrs: { to: { name: "resources" } } }, [
                _vm._v("Resources"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }