var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "tabs" }, [
      _c(
        "ul",
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "li",
            { key: index, staticClass: "tab", class: { active: tab.isActive } },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectTab(tab)
                    },
                  },
                },
                [_vm._v(_vm._s(tab.name))]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "tabs-details" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }