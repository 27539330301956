var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "sectionThree", staticClass: "urla-section" }, [
    _c("h2", [_vm._v("Real Estate")]),
    _c("p", { staticClass: "urla-section-description" }, [
      _vm._v(
        " This section asks you to list all properties you currently own and what you owe on them. "
      ),
    ]),
    _vm._m(0),
    _c(
      "form",
      {
        attrs: { id: "section-3-form", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitSectionThree.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticStyle: { "min-height": "175px" } },
          [
            _c("div", [
              _vm._m(1),
              _c(
                "fieldset",
                {
                  staticClass: "urla-radio radio-inline",
                  staticStyle: { display: "inline" },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.section3RealEstate.ownRealEstate,
                            expression: "section3RealEstate.ownRealEstate",
                          },
                        ],
                        attrs: {
                          name: "own-real-estate",
                          type: "radio",
                          id: "own-real-estate-no",
                          required: "",
                        },
                        domProps: {
                          value: false,
                          checked: _vm._q(
                            _vm.section3RealEstate.ownRealEstate,
                            false
                          ),
                        },
                        on: {
                          change: [
                            function ($event) {
                              return _vm.$set(
                                _vm.section3RealEstate,
                                "ownRealEstate",
                                false
                              )
                            },
                            _vm.skipSection,
                          ],
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "font-weight-normal small-label",
                          attrs: { for: "own-real-estate-no" },
                        },
                        [_vm._v("No")]
                      ),
                    ]),
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.section3RealEstate.ownRealEstate,
                            expression: "section3RealEstate.ownRealEstate",
                          },
                        ],
                        attrs: {
                          name: "own-real-estate",
                          type: "radio",
                          id: "own-real-estate-yes",
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(
                            _vm.section3RealEstate.ownRealEstate,
                            true
                          ),
                        },
                        on: {
                          change: [
                            function ($event) {
                              return _vm.$set(
                                _vm.section3RealEstate,
                                "ownRealEstate",
                                true
                              )
                            },
                            _vm.skipSection,
                          ],
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "font-weight-normal small-label",
                          attrs: { for: "own-real-estate-yes" },
                        },
                        [_vm._v("Yes")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c(
              "transition-group",
              { attrs: { name: "list", tag: "div" } },
              _vm._l(
                _vm.section3RealEstate.propertiesOwned,
                function (property, index) {
                  return _c(
                    "div",
                    {
                      key: "property-" + index,
                      staticClass:
                        "list-item-transition box item-action-wrapper",
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "p",
                          { staticClass: "col-4 col-12-xsmall" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "small-label",
                                attrs: { for: "property-value-" + index },
                              },
                              [
                                _c("span", [_vm._v("Property Value:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.lazy",
                                  value: property.propertyValue,
                                  expression: "property.propertyValue",
                                  modifiers: { lazy: true },
                                },
                                {
                                  name: "money",
                                  rawName: "v-money",
                                  value:
                                    property.propertyValue !== null
                                      ? _vm.currencyFormat
                                      : null,
                                  expression:
                                    "\n                  property.propertyValue !== null ? currencyFormat : null\n                ",
                                },
                              ],
                              staticClass: "small-input",
                              attrs: {
                                id: "property-value-" + index,
                                type: "text",
                                required: "",
                              },
                              domProps: { value: property.propertyValue },
                              on: {
                                blur: function ($event) {
                                  return _vm.validatePropertyValue(
                                    $event,
                                    index
                                  )
                                },
                                invalid: function ($event) {
                                  _vm.validations.propertiesOwned[
                                    index
                                  ].propertyValue = 0
                                },
                                change: function ($event) {
                                  return _vm.$set(
                                    property,
                                    "propertyValue",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("validation-icon", {
                              attrs: {
                                isValid:
                                  _vm.validations.propertiesOwned[index]
                                    .propertyValue,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-8 col-12-xsmall" }, [
                          _c(
                            "button",
                            {
                              key: index + 1,
                              staticClass: "remove-button",
                              attrs: { title: "Remove Property" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeProperty(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-lg fa-times" }),
                              _vm._v(" Delete Property "),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-12 col-12-xsmall" }, [
                          _c("p", { staticStyle: { display: "inline" } }, [
                            _c(
                              "label",
                              {
                                staticClass: "urla-radio-label small-label",
                                attrs: { for: "status-" + index },
                              },
                              [
                                _c("span", [_vm._v("Status:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "fieldset",
                            {
                              staticClass: "urla-radio radio-inline",
                              staticStyle: { display: "inline" },
                            },
                            [
                              _c(
                                "ul",
                                { staticStyle: { "padding-left": "0" } },
                                [
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: property.status,
                                          expression: "property.status",
                                        },
                                      ],
                                      attrs: {
                                        name: "status-" + index,
                                        type: "radio",
                                        id: "retained-" + index,
                                        value: "Retained",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          property.status,
                                          "Retained"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            property,
                                            "status",
                                            "Retained"
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: { for: "retained-" + index },
                                      },
                                      [_vm._v("Retained")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: property.status,
                                          expression: "property.status",
                                        },
                                      ],
                                      attrs: {
                                        name: "status-" + index,
                                        type: "radio",
                                        id: "sold-" + index,
                                        value: "Sold",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          property.status,
                                          "Sold"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            property,
                                            "status",
                                            "Sold"
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: { for: "sold-" + index },
                                      },
                                      [_vm._v("Sold")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: property.status,
                                          expression: "property.status",
                                        },
                                      ],
                                      attrs: {
                                        name: "status-" + index,
                                        type: "radio",
                                        id: "pending-sale-" + index,
                                        value: "Pending Sale",
                                        required: "",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          property.status,
                                          "Pending Sale"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            property,
                                            "status",
                                            "Pending Sale"
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal medium-label",
                                        attrs: { for: "pending-sale-" + index },
                                      },
                                      [_vm._v("Pending Sale")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-12-xsmall" }, [
                          _c("h4", [_vm._v("Property Address:")]),
                        ]),
                        _c("div", { staticClass: "col-6 col-12-xsmall" }, [
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "street-" + index },
                                },
                                [
                                  _c("span", [_vm._v("Street:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: property.propertyAddress.street,
                                    expression:
                                      "property.propertyAddress.street",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "street-" + index,
                                  type: "text",
                                  placeholder: "Street",
                                  required: "",
                                },
                                domProps: {
                                  value: property.propertyAddress.street,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        property.propertyAddress,
                                        "street",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateStreet($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.propertiesOwned[
                                      index
                                    ].propertyAddress.street = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.propertiesOwned[index]
                                      .propertyAddress.street,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "unit-" + index },
                                },
                                [_vm._v("Unit:")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: property.propertyAddress.unit,
                                    expression: "property.propertyAddress.unit",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "unit-" + index,
                                  type: "text",
                                  placeholder: "unit",
                                },
                                domProps: {
                                  value: property.propertyAddress.unit,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        property.propertyAddress,
                                        "unit",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateUnit($event, index)
                                    },
                                  ],
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.propertiesOwned[index]
                                      .propertyAddress.unit,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "zip-" + index },
                                },
                                [
                                  _c("span", [_vm._v("Zip:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: property.propertyAddress.zip,
                                    expression: "property.propertyAddress.zip",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "zip-" + index,
                                  type: "text",
                                  minlength: "5",
                                  maxlength: "5",
                                  placeholder: "00000",
                                  required: "",
                                },
                                domProps: {
                                  value: property.propertyAddress.zip,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        property.propertyAddress,
                                        "zip",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateZip($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.propertiesOwned[
                                      index
                                    ].propertyAddress.zip = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.propertiesOwned[index]
                                      .propertyAddress.zip,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-6 col-12-xsmall" }, [
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "city-" + index },
                                },
                                [
                                  _c("span", [_vm._v("City:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: property.propertyAddress.city,
                                    expression: "property.propertyAddress.city",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "city-" + index,
                                  type: "text",
                                  placeholder: "City",
                                  required: "",
                                },
                                domProps: {
                                  value: property.propertyAddress.city,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        property.propertyAddress,
                                        "city",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateCity($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.propertiesOwned[
                                      index
                                    ].propertyAddress.city = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.propertiesOwned[index]
                                      .propertyAddress.city,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "state-" + index },
                                },
                                [
                                  _c("span", [_vm._v("State:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: property.propertyAddress.state,
                                    expression:
                                      "property.propertyAddress.state",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "state-" + index,
                                  type: "text",
                                  placeholder: "State",
                                  required: "",
                                },
                                domProps: {
                                  value: property.propertyAddress.state,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        property.propertyAddress,
                                        "state",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateState($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.propertiesOwned[
                                      index
                                    ].propertyAddress.state = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.propertiesOwned[index]
                                      .propertyAddress.state,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "county-" + index },
                                },
                                [_vm._v("County:")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: property.propertyAddress.county,
                                    expression:
                                      "property.propertyAddress.county",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "county" + index,
                                  type: "text",
                                  placeholder: "County",
                                },
                                domProps: {
                                  value: property.propertyAddress.county,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        property.propertyAddress,
                                        "county",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateCounty($event, index)
                                    },
                                  ],
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.propertiesOwned[index]
                                      .propertyAddress.county,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("transition", { attrs: { name: "fade" } }, [
                        property.status === "Retained"
                          ? _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-4 col-12-xsmall" },
                                  [
                                    _c("p", [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "urla-radio-label",
                                          attrs: { for: "occupancy-" + index },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Occupancy after closing this loan:"
                                            ),
                                          ]),
                                          _c("strong", [
                                            _c(
                                              "abbr",
                                              {
                                                staticClass: "required",
                                                attrs: { title: "required" },
                                              },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "fieldset",
                                      { staticClass: "urla-radio" },
                                      [
                                        _c("ul", [
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: property.occupancy,
                                                  expression:
                                                    "property.occupancy",
                                                },
                                              ],
                                              attrs: {
                                                name: "occupancy-" + index,
                                                type: "radio",
                                                id:
                                                  "primary-residence-" + index,
                                                value: "Primary Residence",
                                                required: "",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  property.occupancy,
                                                  "Primary Residence"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    property,
                                                    "occupancy",
                                                    "Primary Residence"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal",
                                                attrs: {
                                                  for:
                                                    "primary-residence-" +
                                                    index,
                                                },
                                              },
                                              [_vm._v("Primary Residence")]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: property.occupancy,
                                                  expression:
                                                    "property.occupancy",
                                                },
                                              ],
                                              attrs: {
                                                name: "occupancy-" + index,
                                                type: "radio",
                                                id: "second-home-" + index,
                                                value: "Second Home",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  property.occupancy,
                                                  "Second Home"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    property,
                                                    "occupancy",
                                                    "Second Home"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal",
                                                attrs: {
                                                  for: "second-home-" + index,
                                                },
                                              },
                                              [_vm._v("Second Home")]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: property.occupancy,
                                                  expression:
                                                    "property.occupancy",
                                                },
                                              ],
                                              attrs: {
                                                name: "occupancy-" + index,
                                                type: "radio",
                                                id:
                                                  "investment-property-" +
                                                  index,
                                                value: "Investment Property",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  property.occupancy,
                                                  "Investment Property"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    property,
                                                    "occupancy",
                                                    "Investment Property"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal",
                                                attrs: {
                                                  for:
                                                    "investment-property-" +
                                                    index,
                                                },
                                              },
                                              [_vm._v("Investment Property")]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: property.occupancy,
                                                  expression:
                                                    "property.occupancy",
                                                },
                                              ],
                                              attrs: {
                                                name: "occupancy-" + index,
                                                type: "radio",
                                                id:
                                                  "fha-secondary-residence-" +
                                                  index,
                                                value:
                                                  "FHA Secondary Residence",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  property.occupancy,
                                                  "FHA Secondary Residence"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    property,
                                                    "occupancy",
                                                    "FHA Secondary Residence"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal",
                                                attrs: {
                                                  for:
                                                    "fha-secondary-residence-" +
                                                    index,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "FHA Secondary Residence"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: property.occupancy,
                                                  expression:
                                                    "property.occupancy",
                                                },
                                              ],
                                              attrs: {
                                                name: "occupancy-" + index,
                                                type: "radio",
                                                id: "commercial-space-" + index,
                                                value: "Commercial Space",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  property.occupancy,
                                                  "Commercial Space"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    property,
                                                    "occupancy",
                                                    "Commercial Space"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal",
                                                attrs: {
                                                  for:
                                                    "commerical-space-" + index,
                                                },
                                              },
                                              [_vm._v("Commercial Space")]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-8 col-12-xsmall" },
                                  [
                                    _c(
                                      "transition",
                                      { attrs: { name: "fade" } },
                                      [
                                        property.occupancy ===
                                        "Investment Property"
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-6 col-12-xsmall",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "medium-label",
                                                          attrs: {
                                                            for:
                                                              "purchased-on-" +
                                                              index,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Purchased On:"
                                                            ),
                                                          ]),
                                                          _c("strong", [
                                                            _c(
                                                              "abbr",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                                attrs: {
                                                                  title:
                                                                    "required",
                                                                },
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("date-pick", {
                                                        directives: [
                                                          {
                                                            name: "mask",
                                                            rawName: "v-mask",
                                                            value: "##/##/####",
                                                            expression:
                                                              "'##/##/####'",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "date-pick small-input",
                                                        attrs: {
                                                          id:
                                                            "purchased-on-" +
                                                            index,
                                                          inputAttributes: {
                                                            placeholder:
                                                              "MM/DD/YYYY",
                                                            required:
                                                              "required",
                                                          },
                                                          isDateDisabled:
                                                            _vm.isFutureDate,
                                                          displayFormat:
                                                            "MM/DD/YYYY",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.validatePurchasedOn(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          invalid: function (
                                                            $event
                                                          ) {
                                                            _vm.validations.propertiesOwned[
                                                              index
                                                            ].purchasedOn = 0
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            property.purchasedOn,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              property,
                                                              "purchasedOn",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "property.purchasedOn",
                                                        },
                                                      }),
                                                      _c("validation-icon", {
                                                        attrs: {
                                                          isValid:
                                                            _vm.validations
                                                              .propertiesOwned[
                                                              index
                                                            ].purchasedOn,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "medium-label",
                                                          attrs: {
                                                            for:
                                                              "monthly-rental-income-" +
                                                              index,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Monthly Rental Income:"
                                                            ),
                                                          ]),
                                                          _c("strong", [
                                                            _c(
                                                              "abbr",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                                attrs: {
                                                                  title:
                                                                    "required",
                                                                },
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName:
                                                              "v-model.lazy",
                                                            value:
                                                              property.monthlyRentalIncome,
                                                            expression:
                                                              "property.monthlyRentalIncome",
                                                            modifiers: {
                                                              lazy: true,
                                                            },
                                                          },
                                                          {
                                                            name: "money",
                                                            rawName: "v-money",
                                                            value:
                                                              property.monthlyRentalIncome !==
                                                              null
                                                                ? _vm.currencyFormat
                                                                : null,
                                                            expression:
                                                              "\n                              property.monthlyRentalIncome !== null\n                                ? currencyFormat\n                                : null\n                            ",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "small-input",
                                                        attrs: {
                                                          id:
                                                            "monthly-rental-income-" +
                                                            index,
                                                          type: "text",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            property.monthlyRentalIncome,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.validateMonthlyRentalIncome(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          invalid: function (
                                                            $event
                                                          ) {
                                                            _vm.validations.propertiesOwned[
                                                              index
                                                            ].monthlyRentalIncome = 0
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              property,
                                                              "monthlyRentalIncome",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("validation-icon", {
                                                        attrs: {
                                                          isValid:
                                                            _vm.validations
                                                              .propertiesOwned[
                                                              index
                                                            ]
                                                              .monthlyRentalIncome,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "medium-label",
                                                          attrs: {
                                                            for:
                                                              "investment-property-since-" +
                                                              index,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Investment Property since:"
                                                            ),
                                                          ]),
                                                          _c("strong", [
                                                            _c(
                                                              "abbr",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                                attrs: {
                                                                  title:
                                                                    "required",
                                                                },
                                                              },
                                                              [_vm._v("*")]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("date-pick", {
                                                        directives: [
                                                          {
                                                            name: "mask",
                                                            rawName: "v-mask",
                                                            value: "##/##/####",
                                                            expression:
                                                              "'##/##/####'",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "date-pick small-input",
                                                        attrs: {
                                                          id:
                                                            "investment-property-since-" +
                                                            index,
                                                          inputAttributes: {
                                                            placeholder:
                                                              "MM/DD/YYYY",
                                                            required:
                                                              "required",
                                                          },
                                                          isDateDisabled:
                                                            _vm.isFutureDate,
                                                          displayFormat:
                                                            "MM/DD/YYYY",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.validateInvestmentPropertySince(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          invalid: function (
                                                            $event
                                                          ) {
                                                            _vm.validations.propertiesOwned[
                                                              index
                                                            ].investmentPropertySince = 0
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            property.investmentPropertySince,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              property,
                                                              "investmentPropertySince",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "property.investmentPropertySince",
                                                        },
                                                      }),
                                                      _c("validation-icon", {
                                                        attrs: {
                                                          isValid:
                                                            _vm.validations
                                                              .propertiesOwned[
                                                              index
                                                            ]
                                                              .investmentPropertySince,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-6 col-12-xsmall",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "medium-label",
                                                          attrs: {
                                                            for:
                                                              "monthly-insurance-" +
                                                              index,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Insurance (per month):"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName:
                                                              "v-model.lazy",
                                                            value:
                                                              property.monthlyInsurance,
                                                            expression:
                                                              "property.monthlyInsurance",
                                                            modifiers: {
                                                              lazy: true,
                                                            },
                                                          },
                                                          {
                                                            name: "money",
                                                            rawName: "v-money",
                                                            value:
                                                              property.monthlyInsurance !==
                                                              null
                                                                ? _vm.currencyFormat
                                                                : null,
                                                            expression:
                                                              "\n                              property.monthlyInsurance !== null\n                                ? currencyFormat\n                                : null\n                            ",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "small-input",
                                                        attrs: {
                                                          id:
                                                            "monthly-insurance-" +
                                                            index,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            property.monthlyInsurance,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.validateMonthlyInsurance(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              property,
                                                              "monthlyInsurance",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("validation-icon", {
                                                        attrs: {
                                                          isValid:
                                                            _vm.validations
                                                              .propertiesOwned[
                                                              index
                                                            ].monthlyInsurance,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "medium-label",
                                                          attrs: {
                                                            for:
                                                              "monthly-taxes-" +
                                                              index,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Taxes (per month):"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName:
                                                              "v-model.lazy",
                                                            value:
                                                              property.monthlyTaxes,
                                                            expression:
                                                              "property.monthlyTaxes",
                                                            modifiers: {
                                                              lazy: true,
                                                            },
                                                          },
                                                          {
                                                            name: "money",
                                                            rawName: "v-money",
                                                            value:
                                                              property.monthlyTaxes !==
                                                              null
                                                                ? _vm.currencyFormat
                                                                : null,
                                                            expression:
                                                              "\n                              property.monthlyTaxes !== null\n                                ? currencyFormat\n                                : null\n                            ",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "small-input",
                                                        attrs: {
                                                          id:
                                                            "monthly-taxes-" +
                                                            index,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            property.monthlyTaxes,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.validateMonthlyTaxes(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              property,
                                                              "monthlyTaxes",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("validation-icon", {
                                                        attrs: {
                                                          isValid:
                                                            _vm.validations
                                                              .propertiesOwned[
                                                              index
                                                            ].monthlyTaxes,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "medium-label",
                                                          attrs: {
                                                            for:
                                                              "monthly-hoa-" +
                                                              index,
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "HoA Fee (per month):"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName:
                                                              "v-model.lazy",
                                                            value:
                                                              property.monthlyHoaFee,
                                                            expression:
                                                              "property.monthlyHoaFee",
                                                            modifiers: {
                                                              lazy: true,
                                                            },
                                                          },
                                                          {
                                                            name: "money",
                                                            rawName: "v-money",
                                                            value:
                                                              property.monthlyHoaFee !==
                                                              null
                                                                ? _vm.currencyFormat
                                                                : null,
                                                            expression:
                                                              "\n                              property.monthlyHoaFee !== null\n                                ? currencyFormat\n                                : null\n                            ",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "small-input",
                                                        attrs: {
                                                          id:
                                                            "monthly-hoa-" +
                                                            index,
                                                          type: "text",
                                                        },
                                                        domProps: {
                                                          value:
                                                            property.monthlyHoaFee,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.validateMonthlyHoaFee(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              property,
                                                              "monthlyHoaFee",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("validation-icon", {
                                                        attrs: {
                                                          isValid:
                                                            _vm.validations
                                                              .propertiesOwned[
                                                              index
                                                            ].monthlyHoaFee,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "line-height": "25px",
                              "padding-bottom": "0",
                            },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticStyle: { width: "auto" },
                                attrs: {
                                  for: "other-loans-on-property-" + index,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-normal" },
                                  [
                                    _vm._v(" Do you have any "),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Mortgage Loans")]
                                    ),
                                    _vm._v(" on this property? "),
                                  ]
                                ),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "fieldset",
                          { staticClass: "urla-radio radio-inline" },
                          [
                            _c("ul", [
                              _c("li", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: property.hasLoansOnProperty,
                                      expression: "property.hasLoansOnProperty",
                                    },
                                  ],
                                  attrs: {
                                    name: "other-loans-on-property-" + index,
                                    type: "radio",
                                    id: "other-loans-on-property-no-" + index,
                                    required: "",
                                  },
                                  domProps: {
                                    value: false,
                                    checked: _vm._q(
                                      property.hasLoansOnProperty,
                                      false
                                    ),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        return _vm.$set(
                                          property,
                                          "hasLoansOnProperty",
                                          false
                                        )
                                      },
                                      function ($event) {
                                        return _vm.skipLoansOnProperty(index)
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "font-weight-normal small-label",
                                    attrs: {
                                      for:
                                        "other-loans-on-property-no-" + index,
                                    },
                                  },
                                  [_vm._v("No")]
                                ),
                              ]),
                              _c("li", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: property.hasLoansOnProperty,
                                      expression: "property.hasLoansOnProperty",
                                    },
                                  ],
                                  attrs: {
                                    name: "other-loans-on-property-" + index,
                                    type: "radio",
                                    id: "other-loans-on-property-yes-" + index,
                                  },
                                  domProps: {
                                    value: true,
                                    checked: _vm._q(
                                      property.hasLoansOnProperty,
                                      true
                                    ),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        return _vm.$set(
                                          property,
                                          "hasLoansOnProperty",
                                          true
                                        )
                                      },
                                      function ($event) {
                                        return _vm.skipLoansOnProperty(index)
                                      },
                                    ],
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "font-weight-normal small-label",
                                    attrs: {
                                      for:
                                        "other-loans-on-property-yes-" + index,
                                    },
                                  },
                                  [_vm._v("Yes")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("transition", { attrs: { name: "fade" } }, [
                        property.mortgageLoansOnThisProperty.length !== 0
                          ? _c(
                              "div",
                              [
                                _c("hr", { staticClass: "urla-break" }),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-4 col-12-xsmall" },
                                    [
                                      _c("h4", [
                                        _vm._v(
                                          "Mortgage Loans on this property:"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "transition-group",
                                  { attrs: { name: "list", tag: "div" } },
                                  _vm._l(
                                    property.mortgageLoansOnThisProperty,
                                    function (loan, j) {
                                      return _c(
                                        "div",
                                        {
                                          key: "loan-" + index + "-" + j,
                                          staticClass: "list-item-transition",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row inner-item-action-wrapper",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall",
                                                },
                                                [
                                                  _vm._v(
                                                    " Mortgage Loan " +
                                                      _vm._s(j + 1) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-8 col-12-xsmall",
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "inner-remove-button",
                                                      staticStyle: {
                                                        float: "none",
                                                      },
                                                      attrs: {
                                                        title:
                                                          "Remove Loan on Property",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeLoanOnProperty(
                                                            index,
                                                            j
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-lg fa-times",
                                                      }),
                                                      _vm._v(" Delete loan "),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "medium-label",
                                                      attrs: {
                                                        for:
                                                          "creditor-name-" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                    },
                                                    [_vm._v("Creditor Name:")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          loan.creditorName,
                                                        expression:
                                                          "loan.creditorName",
                                                      },
                                                    ],
                                                    staticClass: "small-input",
                                                    attrs: {
                                                      id:
                                                        "creditor-name-" +
                                                        index +
                                                        "-" +
                                                        j,
                                                      type: "text",
                                                      placeholder:
                                                        "Creditor Name",
                                                    },
                                                    domProps: {
                                                      value: loan.creditorName,
                                                    },
                                                    on: {
                                                      input: [
                                                        function ($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            loan,
                                                            "creditorName",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.validateCreditorName(
                                                            $event,
                                                            index,
                                                            j
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _c("validation-icon", {
                                                    attrs: {
                                                      isValid:
                                                        _vm.validations
                                                          .propertiesOwned[
                                                          index
                                                        ]
                                                          .mortgageLoansOnThisProperty[
                                                          j
                                                        ].creditorName,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall urla-middle-section",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "medium-label",
                                                      attrs: {
                                                        for:
                                                          "account-number-" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                    },
                                                    [_vm._v("Account Number:")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          loan.accountNumber,
                                                        expression:
                                                          "loan.accountNumber",
                                                      },
                                                    ],
                                                    staticClass: "small-label",
                                                    attrs: {
                                                      id:
                                                        "account-number-" +
                                                        index +
                                                        "-" +
                                                        j,
                                                      type: "text",
                                                      placeholder: "123456789",
                                                    },
                                                    domProps: {
                                                      value: loan.accountNumber,
                                                    },
                                                    on: {
                                                      input: [
                                                        function ($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            loan,
                                                            "accountNumber",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.validateAccountNumber(
                                                            $event,
                                                            index,
                                                            j
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _c("validation-icon", {
                                                    attrs: {
                                                      isValid:
                                                        _vm.validations
                                                          .propertiesOwned[
                                                          index
                                                        ]
                                                          .mortgageLoansOnThisProperty[
                                                          j
                                                        ].accountNumber,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "medium-label",
                                                      attrs: {
                                                        for:
                                                          "monthly-mortgage-payment" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Mortgage Payment:"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model.lazy",
                                                        value:
                                                          loan.monthlyMortgagePayment,
                                                        expression:
                                                          "loan.monthlyMortgagePayment",
                                                        modifiers: {
                                                          lazy: true,
                                                        },
                                                      },
                                                      {
                                                        name: "money",
                                                        rawName: "v-money",
                                                        value:
                                                          loan.monthlyMortgagePayment !==
                                                          null
                                                            ? _vm.currencyFormat
                                                            : null,
                                                        expression:
                                                          "\n                          loan.monthlyMortgagePayment !== null\n                            ? currencyFormat\n                            : null\n                        ",
                                                      },
                                                    ],
                                                    staticClass: "small-input",
                                                    attrs: {
                                                      id:
                                                        "monthly-mortgage-payment-" +
                                                        index +
                                                        "-" +
                                                        j,
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value:
                                                        loan.monthlyMortgagePayment,
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.validateMonthlyMortgagePayment(
                                                          $event,
                                                          index,
                                                          j
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          loan,
                                                          "monthlyMortgagePayment",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("validation-icon", {
                                                    attrs: {
                                                      isValid:
                                                        _vm.validations
                                                          .propertiesOwned[
                                                          index
                                                        ]
                                                          .mortgageLoansOnThisProperty[
                                                          j
                                                        ]
                                                          .monthlyMortgagePayment,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "medium-label",
                                                      attrs: {
                                                        for:
                                                          "unpaid-balance-" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                    },
                                                    [_vm._v("Unpaid Balance:")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model.lazy",
                                                        value:
                                                          loan.unpaidBalance,
                                                        expression:
                                                          "loan.unpaidBalance",
                                                        modifiers: {
                                                          lazy: true,
                                                        },
                                                      },
                                                      {
                                                        name: "money",
                                                        rawName: "v-money",
                                                        value:
                                                          loan.unpaidBalance !==
                                                          null
                                                            ? _vm.currencyFormat
                                                            : null,
                                                        expression:
                                                          "\n                          loan.unpaidBalance !== null ? currencyFormat : null\n                        ",
                                                      },
                                                    ],
                                                    staticClass: "small-input",
                                                    attrs: {
                                                      id:
                                                        "unpaid-balance-" +
                                                        index +
                                                        "-" +
                                                        j,
                                                      type: "text",
                                                      placeholder:
                                                        "Unpaid Balance",
                                                    },
                                                    domProps: {
                                                      value: loan.unpaidBalance,
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.validateUnpaidBalance(
                                                          $event,
                                                          index,
                                                          j
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          loan,
                                                          "unpaidBalance",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("validation-icon", {
                                                    attrs: {
                                                      isValid:
                                                        _vm.validations
                                                          .propertiesOwned[
                                                          index
                                                        ]
                                                          .mortgageLoansOnThisProperty[
                                                          j
                                                        ].unpaidBalance,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall urla-middle-section",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "medium-label",
                                                      attrs: {
                                                        for:
                                                          "loan-type-" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                    },
                                                    [_vm._v("Type:")]
                                                  ),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: loan.loanType,
                                                          expression:
                                                            "loan.loanType",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "small-input",
                                                      attrs: {
                                                        id:
                                                          "loan-type-" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            loan,
                                                            "loanType",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value:
                                                              "Conventional",
                                                            selected:
                                                              "selected",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Conventional "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "FHA",
                                                          },
                                                        },
                                                        [_vm._v("FHA")]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "USDA-RD",
                                                          },
                                                        },
                                                        [_vm._v("USDA-RD")]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "VA",
                                                          },
                                                        },
                                                        [_vm._v("VA")]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Other",
                                                          },
                                                        },
                                                        [_vm._v("Other")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "col-4 col-12-xsmall",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "medium-label",
                                                      attrs: {
                                                        for:
                                                          "credit-limit-" +
                                                          index +
                                                          "-" +
                                                          j,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Credit Limit (if applicable):"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model.lazy",
                                                        value: loan.creditLimit,
                                                        expression:
                                                          "loan.creditLimit",
                                                        modifiers: {
                                                          lazy: true,
                                                        },
                                                      },
                                                      {
                                                        name: "money",
                                                        rawName: "v-money",
                                                        value:
                                                          loan.creditLimit !==
                                                          null
                                                            ? _vm.currencyFormat
                                                            : null,
                                                        expression:
                                                          "\n                          loan.creditLimit !== null ? currencyFormat : null\n                        ",
                                                      },
                                                    ],
                                                    staticClass: "small-input",
                                                    attrs: {
                                                      id:
                                                        "credit-limit-" +
                                                        index +
                                                        "-" +
                                                        j,
                                                      type: "text",
                                                      placeholder:
                                                        "Creditor Limit",
                                                    },
                                                    domProps: {
                                                      value: loan.creditLimit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.validateCreditLimit(
                                                          $event,
                                                          index,
                                                          j
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          loan,
                                                          "creditLimit",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("validation-icon", {
                                                    attrs: {
                                                      isValid:
                                                        _vm.validations
                                                          .propertiesOwned[
                                                          index
                                                        ]
                                                          .mortgageLoansOnThisProperty[
                                                          j
                                                        ].creditLimit,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("div", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      display: "inline",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "urla-radio-label",
                                                        staticStyle: {
                                                          width: "300px",
                                                        },
                                                        attrs: {
                                                          for:
                                                            "to-be-paid-off-" +
                                                            index +
                                                            "-" +
                                                            j,
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "To be paid off at or before closing?"
                                                          ),
                                                        ]),
                                                        _c("strong", [
                                                          _c(
                                                            "abbr",
                                                            {
                                                              staticClass:
                                                                "required",
                                                              attrs: {
                                                                title:
                                                                  "required",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "fieldset",
                                                  {
                                                    staticClass:
                                                      "urla-radio radio-inline",
                                                    staticStyle: {
                                                      display: "inline",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticStyle: {
                                                          "padding-left": "0",
                                                        },
                                                      },
                                                      [
                                                        _c("li", [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  loan.toBePaidOff,
                                                                expression:
                                                                  "loan.toBePaidOff",
                                                              },
                                                            ],
                                                            attrs: {
                                                              name:
                                                                "to-be-paid-off-" +
                                                                index +
                                                                "-" +
                                                                j,
                                                              type: "radio",
                                                              id:
                                                                "no-" +
                                                                index +
                                                                "-" +
                                                                j,
                                                            },
                                                            domProps: {
                                                              value: false,
                                                              checked: _vm._q(
                                                                loan.toBePaidOff,
                                                                false
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  loan,
                                                                  "toBePaidOff",
                                                                  false
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-normal small-label",
                                                              attrs: {
                                                                for:
                                                                  "no-" +
                                                                  index +
                                                                  "-" +
                                                                  j,
                                                              },
                                                            },
                                                            [_vm._v("No")]
                                                          ),
                                                        ]),
                                                        _c("li", [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  loan.toBePaidOff,
                                                                expression:
                                                                  "loan.toBePaidOff",
                                                              },
                                                            ],
                                                            attrs: {
                                                              name:
                                                                "to-be-paid-off-" +
                                                                index +
                                                                "-" +
                                                                j,
                                                              type: "radio",
                                                              id:
                                                                "yes-" +
                                                                index +
                                                                "-" +
                                                                j,
                                                              required: "",
                                                            },
                                                            domProps: {
                                                              value: true,
                                                              checked: _vm._q(
                                                                loan.toBePaidOff,
                                                                true
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  loan,
                                                                  "toBePaidOff",
                                                                  true
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-normal small-label",
                                                              attrs: {
                                                                for:
                                                                  "yes-" +
                                                                  index +
                                                                  "-" +
                                                                  j,
                                                              },
                                                            },
                                                            [_vm._v("Yes")]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("hr", {
                                            staticClass: "urla-break",
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button add-button",
                                    staticStyle: { "font-size": "13px" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addLoanOnProperty(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-plus" }),
                                    _vm._v(" Loan "),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }
              ),
              0
            ),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.section3RealEstate.propertiesOwned.length !== 0
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button add-button",
                        staticStyle: { margin: "1rem" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addProperty()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-lg fa-plus" }),
                        _vm._v(" Property "),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "button",
            {
              staticClass: "primary wizard__back pull-left",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goBack()
                },
              },
            },
            [_vm._m(2)]
          ),
          _vm._m(3),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "inline" } }, [
      _c("label", { attrs: { for: "own-real-estate" } }, [
        _c("span", { staticClass: "font-weight-normal" }, [
          _vm._v("Do you own any other Real Estate?"),
        ]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-arrow-left fa-lg" }),
      _vm._v(" back "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "primary wizard__next pull-right",
        attrs: { type: "submit" },
      },
      [
        _c("span", [
          _c("i", {
            staticClass: "fa fa-floppy-o",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" save "),
          _c("i", {
            staticClass: "fa fa-arrow-right fa-lg",
            attrs: { "aria-hidden": "true" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }