var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "main" }, [
    _c(
      "section",
      { staticClass: "wizard" },
      [
        _c("moon-loader", {
          attrs: {
            loading: _vm.isLoading,
            color: _vm.loaderColor,
            size: _vm.loaderSize,
            loaderMessage: _vm.loaderMessage,
          },
        }),
        _c(
          "form-wizard",
          { ref: "urlaWizard", attrs: { steps: _vm.steps } },
          [
            _c("section-1", {
              attrs: {
                slot: "section1",
                sourceSection1: _vm.urla.section1BorrowerInformation,
              },
              slot: "section1",
            }),
            _c("section-3", {
              attrs: {
                slot: "section3",
                sourceSection3: _vm.urla.section3RealEstate,
              },
              slot: "section3",
            }),
            _c("section-4", {
              attrs: {
                slot: "section4",
                sourceSection4: _vm.urla.section4LoanAndPropertyInformation,
              },
              slot: "section4",
            }),
            _c("section-5", {
              attrs: {
                slot: "section5",
                sourceSection5: _vm.urla.section5Declarations,
              },
              slot: "section5",
            }),
            _c("section-7", {
              attrs: {
                slot: "section7",
                sourceSection7: _vm.urla.section7DemographicInformation,
              },
              slot: "section7",
            }),
            _c("section-8", {
              attrs: {
                slot: "section8",
                sourceSection8: _vm.urla.section8LoanOriginatorInformation,
              },
              slot: "section8",
            }),
            _c("disclaimer", {
              attrs: {
                slot: "disclaimer",
                sourceSection8: _vm.urla.section8LoanOriginatorInformation,
              },
              slot: "disclaimer",
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }