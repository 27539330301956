var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c("section", [
            _c("h1", [_vm._v("Documents needed for mortgage processing")]),
            _c("h4", [
              _vm._v(
                "Note: We require all the documents to be submitted in PDF format for quicker processing. If you cannot read something clearly, we cannot and the underwriter cannot"
              ),
            ]),
            _c("h3", [_vm._v("Mortgage for a purchase")]),
            _c("ul", { staticStyle: { "list-style-type": "square" } }, [
              _c("li", [
                _vm._v("Fully executed purchase contract with all amendments"),
              ]),
              _c("li", [
                _vm._v(
                  "Two recent bank statements spanning at least 2 months of transaction history"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Bank statements containing the withdrawal transaction of the earnest money and any option money checks along with check proofs"
                ),
              ]),
              _c("li", [_vm._v("Two recent W2s")]),
              _c("li", [_vm._v("Two recent tax returns")]),
              _c("li", [_vm._v("Two recent pay stubs")]),
              _c("li", [
                _vm._v(
                  "Any other stock brokerage or 401k investments statements that can serve as reserve to show the strength of the application"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Need properly scanned copy of front and back of the drivers license"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "If on a work visa then we need most recent H1B approval - I-797"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "If on a Green card copy then we need properly scanned copy of front and back sides"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "If on a work visa then we need the valid visa on the Passport"
                ),
              ]),
            ]),
            _c("h3", [
              _vm._v("Mortgage for a rate term refinance or cashout refinance"),
            ]),
            _c("ul", { staticStyle: { "list-style-type": "square" } }, [
              _c("li", [_vm._v("Signed HUD-1")]),
              _c("li", [
                _vm._v(
                  "Previous Loan Certificate form - We require a Deed document that you must have received at the time of closing containing your wet signature along with a seal by a notary or a similar authority"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Two recent bank statements spanning at least 2 months of transaction history"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Bank statements containing the withdrawal transaction of the earnest money and any option money checks along with check proofs"
                ),
              ]),
              _c("li", [_vm._v("Two recent W2s")]),
              _c("li", [_vm._v("Two recent tax returns")]),
              _c("li", [_vm._v("Two recent pay stubs")]),
              _c("li", [
                _vm._v(
                  "Any other stock brokerage or 401k investments statements that can serve as reserve to show the strength of the application"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Need properly scanned copy of front and back of the drivers license"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "If on a work visa then we need most recent H1B approval - I-797"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "If on a Green card copy then we need properly scanned copy of front and back sides"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "If on a work visa then we need the valid visa on the Passport"
                ),
              ]),
            ]),
          ]),
          _c("section", { staticClass: "spotlights alt" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }