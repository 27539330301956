var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "main" }, [
    _c("section", { staticClass: "quote-summary" }, [
      _c("h1", [_vm._v("Quote Requested")]),
      _vm._m(0),
      _c("h2", [_vm._v("Summary of your Request")]),
      _vm.quoteRequest !== null
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-12-medium" }, [
              _c("h3", [_vm._v("Mortgage Information")]),
              _c("p", [
                _c("span", { staticClass: "field" }, [
                  _vm._v("Purchase price:"),
                ]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.quoteRequest.purchasePrice)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [_vm._v("Down payment:")]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.quoteRequest.downPayment)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [
                  _vm._v("Loan programs:"),
                ]),
                _c(
                  "span",
                  { staticClass: "field-value" },
                  _vm._l(_vm.quoteRequest.loanProgram, function (term, index) {
                    return _c(
                      "span",
                      { key: index, attrs: { schedule: term } },
                      [
                        _vm._v(" " + _vm._s(term) + " "),
                        index !== _vm.quoteRequest.loanProgram.length - 1
                          ? _c("span", [_vm._v(", ")])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _c("br"),
                _c("span", { staticClass: "field" }, [
                  _vm._v("Closing On/Before:"),
                ]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.quoteRequest.closingDate)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [_vm._v("House Type:")]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.quoteRequest.houseType)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [
                  _vm._v("Residence Type:"),
                ]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.quoteRequest.residenceType)),
                ]),
                _c("br"),
              ]),
            ]),
            _c("div", { staticClass: "col-4 col-12-medium" }, [
              _c("h3", [_vm._v("Contact Information")]),
              _c("p", [
                _c("span", { staticClass: "field" }, [_vm._v("First Name:")]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.mortgageCustomer.firstName)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [_vm._v("Last Name:")]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.mortgageCustomer.lastName)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [
                  _vm._v("Email Address:"),
                ]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.mortgageCustomer.primaryEmail)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [_vm._v("Phone Number:")]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.mortgageCustomer.phoneNumber)),
                ]),
                _c("br"),
                _c("span", { staticClass: "field" }, [
                  _vm._v("WhatsApp Number:"),
                ]),
                _c("span", { staticClass: "field-value" }, [
                  _vm._v(_vm._s(_vm.mortgageCustomer.whatsAppNumber)),
                ]),
                _c("br"),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " We appreciate you for considering American Smart Lending for your mortgage needs. "
      ),
      _c("br"),
      _vm._v("We will get back to you by the end of the business day. "),
      _c("br"),
      _vm._v("Please do not hesitate to reach us at "),
      _c("a", { attrs: { href: "tel:469-525-6725" } }, [
        _vm._v("+1(469)525-6725"),
      ]),
      _vm._v(" or "),
      _c("a", { attrs: { href: "mailto:kc@texsmartlending.com" } }, [
        _vm._v("kc@texsmartlending.com"),
      ]),
      _vm._v(" in case you need to reach us before the end of the day. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }