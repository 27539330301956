var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [_c("AppHeader"), _c("AppContent"), _c("AppFooter")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }