var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "custom-model-main", class: { "model-open": _vm.status } },
      [
        _c("div", { staticClass: "custom-model-inner" }, [
          _c("div", { staticClass: "custom-model-wrap" }, [
            _c("div", { staticClass: "pop-up-content-wrap" }, [
              _vm._v(" " + _vm._s(_vm.statusMessage) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "bg-overlay" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }