var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "limiter" }, [
      _c("div", { staticClass: "container-login100" }, [
        _vm.currentSection === "sign-up"
          ? _c("div", { staticClass: "wrap-login100 p-t-25 p-b-20" }, [
              _c("span", { staticClass: "login100-form-title p-b-20" }, [
                _vm._v("Join now!"),
              ]),
              _c(
                "form",
                {
                  staticClass: "login100-form validate-form",
                  attrs: { id: "sign-in-form", method: "post" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.signUpUser.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("moon-loader", {
                    attrs: {
                      loading: _vm.isLoading,
                      color: _vm.loaderColor,
                      size: _vm.loaderSize,
                      loaderMessage: _vm.loaderMessage,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "wrap-input100 validate-input my-1",
                      class:
                        _vm.validations.customer.firstName === 1
                          ? ""
                          : "alert-validate",
                      attrs: { "data-validate": "Enter valid name" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.firstName,
                            expression: "customer.firstName",
                          },
                        ],
                        staticClass: "input100",
                        class: _vm.customer.firstName !== null ? "has-val" : "",
                        attrs: {
                          id: "first-name",
                          type: "text",
                          name: "firstName",
                          required: "",
                        },
                        domProps: { value: _vm.customer.firstName },
                        on: {
                          change: function ($event) {
                            return _vm.validateFirstName($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer,
                              "firstName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "focus-input100",
                        attrs: { "data-placeholder": "First Name" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wrap-input100 validate-input my-1",
                      class:
                        _vm.validations.customer.lastName === 1
                          ? ""
                          : "alert-validate",
                      attrs: { "data-validate": "Enter valid name" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.lastName,
                            expression: "customer.lastName",
                          },
                        ],
                        staticClass: "input100",
                        class: _vm.customer.lastName !== null ? "has-val" : "",
                        attrs: {
                          id: "last-name",
                          type: "text",
                          name: "lastName",
                          required: "",
                        },
                        domProps: { value: _vm.customer.lastName },
                        on: {
                          change: function ($event) {
                            return _vm.validateLastName($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer,
                              "lastName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "focus-input100",
                        attrs: { "data-placeholder": "Last Name" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wrap-input100 validate-input my-1",
                      class:
                        _vm.validations.customer.primaryEmail === 1
                          ? ""
                          : "alert-validate",
                      attrs: { "data-validate": "Enter valid email" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.primaryEmail,
                            expression: "customer.primaryEmail",
                          },
                        ],
                        staticClass: "input100",
                        class:
                          _vm.customer.primaryEmail !== null ? "has-val" : "",
                        attrs: {
                          id: "login-email",
                          type: "email",
                          name: "email",
                          required: "",
                        },
                        domProps: { value: _vm.customer.primaryEmail },
                        on: {
                          change: function ($event) {
                            return _vm.validateEmail($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.customer,
                              "primaryEmail",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "focus-input100",
                        attrs: { "data-placeholder": "Email" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wrap-input100 validate-input my-1",
                      class:
                        _vm.validations.customer.dateOfBirth === 1
                          ? ""
                          : "alert-validate",
                      attrs: { "data-validate": "Enter valid date" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.dateOfBirthModel,
                            expression: "dateOfBirthModel",
                          },
                        ],
                        staticClass: "input100",
                        class:
                          _vm.customer.dateOfBirth !== null ? "has-val" : "",
                        attrs: {
                          id: "date-of-birth",
                          type: "text",
                          maxlength: "10",
                          name: "dateOfBirth",
                          required: "",
                        },
                        domProps: { value: _vm.dateOfBirthModel },
                        on: {
                          change: function ($event) {
                            return _vm.validateDateOfBirth()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.dateOfBirthModel = $event.target.value
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "focus-input100",
                        attrs: {
                          "data-placeholder": "Date of Birth (MM/DD/YYYY)",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wrap-input100 validate-input my-1",
                      class:
                        _vm.validations.customer.phoneNumber === 1
                          ? ""
                          : "alert-validate",
                      attrs: { "data-validate": "Enter valid phone number" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(###) ###-####",
                            expression: "'(###) ###-####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.phoneNumberModel,
                            expression: "phoneNumberModel",
                          },
                        ],
                        staticClass: "input100",
                        class:
                          _vm.customer.phoneNumber !== null ? "has-val" : "",
                        attrs: {
                          id: "phone-number",
                          type: "text",
                          maxlength: "14",
                          name: "phoneNumber",
                          required: "",
                        },
                        domProps: { value: _vm.phoneNumberModel },
                        on: {
                          change: function ($event) {
                            return _vm.validatePhoneNumber()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.phoneNumberModel = $event.target.value
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "focus-input100",
                        attrs: {
                          "data-placeholder": "Phone Number (###) ###-####",
                        },
                      }),
                    ]
                  ),
                  _c("span", { staticClass: "same-as-above" }, [
                    _c("label"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sameAsAbove,
                          expression: "sameAsAbove",
                        },
                      ],
                      attrs: { type: "checkbox", id: "same-as-above" },
                      domProps: {
                        checked: Array.isArray(_vm.sameAsAbove)
                          ? _vm._i(_vm.sameAsAbove, null) > -1
                          : _vm.sameAsAbove,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.sameAsAbove,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.sameAsAbove = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.sameAsAbove = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.sameAsAbove = $$c
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "same-as-above" } }, [
                      _vm._v("Same as above"),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "wrap-input100 validate-input my-1",
                      class:
                        _vm.validations.customer.whatsAppNumber === 1
                          ? ""
                          : "alert-validate",
                      attrs: { "data-validate": "Enter valid Whatsapp number" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(###) ###-####",
                            expression: "'(###) ###-####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.whatsAppNumberModel,
                            expression: "whatsAppNumberModel",
                          },
                        ],
                        staticClass: "input100",
                        class:
                          _vm.customer.whatsAppNumber !== null ? "has-val" : "",
                        attrs: {
                          id: "whatsapp-number",
                          type: "text",
                          maxlength: "14",
                          name: "whatsappNumber",
                          disabled: _vm.sameAsAbove ? "disabled" : null,
                          required: "",
                        },
                        domProps: { value: _vm.whatsAppNumberModel },
                        on: {
                          change: function ($event) {
                            return _vm.validateWhatsAppNumber()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.whatsAppNumberModel = $event.target.value
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "focus-input100",
                        attrs: {
                          "data-placeholder": "Whats app Number (###) ###-####",
                        },
                      }),
                    ]
                  ),
                  _vm.errorMessage
                    ? _c("p", { staticClass: "error-text" }, [
                        _vm._v(_vm._s(_vm.errorMessage)),
                      ])
                    : _vm._e(),
                  _vm._m(0),
                  _c("div", { staticClass: "bottom-link" }, [
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({ name: "sign-in" })
                            },
                          },
                        },
                        [_vm._v("Already have an account?")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.currentSection === "otp"
          ? _c("div", [
              _c("span", { staticClass: "login101-form-title p-b-6" }, [
                _vm._v("Please Enter the Verification Code"),
              ]),
              _c("div", { staticClass: "otp-input-label" }, [
                _c("p", [
                  _vm._v(
                    " An OTP has been sent to your email address " +
                      _vm._s(_vm.customer.primaryEmail) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "otp-input-row" },
                [
                  _vm._l(_vm.otp, function (digit, index) {
                    return _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.otp[index],
                          expression: "otp[index]",
                        },
                      ],
                      key: index,
                      ref: "otpInput",
                      refInFor: true,
                      staticClass: "otp-input",
                      class: _vm.otp[index] == "" ? "" : "active__input",
                      attrs: { type: "text", maxlength: "1", required: "" },
                      domProps: { value: _vm.otp[index] },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.otp, index, $event.target.value)
                          },
                          function ($event) {
                            return _vm.handleInput(index, $event)
                          },
                        ],
                        keydown: function ($event) {
                          return _vm.handleOtherKeys(index, $event)
                        },
                      },
                    })
                  }),
                  _c("span", {
                    staticClass: "focus-input100",
                    attrs: { "data-placeholder": "Email" },
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "container-login100-form-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "login100-form-btn",
                    attrs: { disabled: !_vm.isValidOTP },
                    on: { click: _vm.submitOTP },
                  },
                  [_vm._v("Submit OTP")]
                ),
              ]),
              _vm.showErrorBanner
                ? _c(
                    "div",
                    { staticClass: "wrap-error" },
                    [
                      _c("transition", { attrs: { name: "shake" } }, [
                        _c("p", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.errorMessage)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentSection === "otp"
                ? _c("div", { staticClass: "bottom-link" }, [
                    _vm.enableResendOtp
                      ? _c(
                          "p",
                          {
                            staticClass: "resend-active",
                            on: { click: _vm.requestOTP },
                          },
                          [
                            _vm._v(
                              " Didn't receive OTP yet? Click here to resend OTP "
                            ),
                          ]
                        )
                      : _vm.isOtpResent
                      ? _c("p", [_vm._v(" OTP has been sent.. ")])
                      : _c("p", [
                          _vm._v(
                            " You can resend OTP in " +
                              _vm._s(_vm.otpCounter) +
                              " secs "
                          ),
                        ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-login100-form-btn" }, [
      _c("button", { staticClass: "login100-form-btn" }, [_vm._v(" Sign up ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }