var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rounded" }, [
    _c("div", { staticClass: "loader" }),
    _c("p", { staticClass: "loading-message" }, [
      _vm._v(_vm._s(_vm.loaderMessage) + "..."),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }