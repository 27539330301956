var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c("section", [
            _c("h1", { staticClass: "disclosure-heading" }, [
              _vm._v("TEXAS RECOVERY FUND"),
            ]),
            _c("br"),
            _c("p", { staticClass: "disclosure-text" }, [
              _vm._v(
                " “CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A COMPANY OR A RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM TO THE TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE DEPARTMENT’S WEBSITE AT "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.sml.texas.gov",
                    target: "_blank",
                  },
                },
                [_vm._v("WWW.SML.TEXAS.GOV")]
              ),
              _vm._v(". A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT "),
              _c("a", { attrs: { href: "tel:469-525-6725" } }, [
                _vm._v("1-877-276-5550"),
              ]),
              _vm._v(". "),
            ]),
            _c("p", { staticClass: "disclosure-text" }, [
              _vm._v(
                " THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS OF LICENSED RESIDENTIALMORTGAGE LOAN ORIGINATORS. A WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND, PLEASE CONSULT THE DEPARTMENT’S WEBSITE AT "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.sml.texas.gov",
                    target: "_blank",
                  },
                },
                [_vm._v("WWW.SML.TEXAS.GOV")]
              ),
              _vm._v('." '),
            ]),
          ]),
          _c("section", { staticClass: "spotlights alt" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }