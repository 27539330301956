var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("section", { staticClass: "main" }, [
      _c("section", [
        _c(
          "form",
          {
            staticClass: "gtr-uniform",
            attrs: { id: "quote", method: "post" },
            on: { submit: _vm.postReview },
          },
          [
            _c("h1", [_vm._v("Write your review")]),
            _vm._m(0),
            _c("br"),
            _c("section", [
              _c("p", { ref: "firstName" }, [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.firstName,
                      expression: "customer.firstName",
                    },
                  ],
                  attrs: {
                    id: "first-name",
                    type: "text",
                    placeholder: "First Name",
                    required: "",
                  },
                  domProps: { value: _vm.customer.firstName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.customer, "firstName", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("p", { ref: "lastName" }, [
                _vm._m(2),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.lastName,
                      expression: "customer.lastName",
                    },
                  ],
                  attrs: {
                    id: "last-name",
                    type: "text",
                    placeholder: "Last Name",
                    required: "",
                  },
                  domProps: { value: _vm.customer.lastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.customer, "lastName", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("p", { ref: "email" }, [
                _vm._m(3),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.email,
                      expression: "customer.email",
                    },
                  ],
                  attrs: {
                    id: "email",
                    type: "email",
                    placeholder: "dan@gmail.com",
                    required: "",
                  },
                  domProps: { value: _vm.customer.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.customer, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { ref: "localKnowledge" },
                [
                  _vm._m(4),
                  _c(
                    "star-rating",
                    _vm._b(
                      {
                        staticClass: "star-rating",
                        attrs: { id: "local-knowledge" },
                        on: { "rating-selected": _vm.validateLocalKnowledge },
                        model: {
                          value: _vm.review.localKnowledge,
                          callback: function ($$v) {
                            _vm.$set(_vm.review, "localKnowledge", $$v)
                          },
                          expression: "review.localKnowledge",
                        },
                      },
                      "star-rating",
                      _vm.starRatingConfig,
                      false
                    )
                  ),
                ],
                1
              ),
              _c(
                "div",
                { ref: "processExpertise" },
                [
                  _vm._m(5),
                  _c(
                    "star-rating",
                    _vm._b(
                      {
                        staticClass: "star-rating",
                        attrs: { id: "process-expertise" },
                        on: { "rating-selected": _vm.validateProcessExpertise },
                        model: {
                          value: _vm.review.processExpertise,
                          callback: function ($$v) {
                            _vm.$set(_vm.review, "processExpertise", $$v)
                          },
                          expression: "review.processExpertise",
                        },
                      },
                      "star-rating",
                      _vm.starRatingConfig,
                      false
                    )
                  ),
                ],
                1
              ),
              _c(
                "div",
                { ref: "responsiveness" },
                [
                  _vm._m(6),
                  _c(
                    "star-rating",
                    _vm._b(
                      {
                        staticClass: "star-rating",
                        attrs: { id: "responsiveness" },
                        on: { "rating-selected": _vm.validateResponsiveness },
                        model: {
                          value: _vm.review.responsiveness,
                          callback: function ($$v) {
                            _vm.$set(_vm.review, "responsiveness", $$v)
                          },
                          expression: "review.responsiveness",
                        },
                      },
                      "star-rating",
                      _vm.starRatingConfig,
                      false
                    )
                  ),
                ],
                1
              ),
              _c(
                "div",
                { ref: "negotiationSkills" },
                [
                  _vm._m(7),
                  _c(
                    "star-rating",
                    _vm._b(
                      {
                        staticClass: "star-rating",
                        attrs: { id: "negotiation-skills" },
                        on: {
                          "rating-selected": _vm.validateNegotiationSkills,
                        },
                        model: {
                          value: _vm.review.negotiationSkills,
                          callback: function ($$v) {
                            _vm.$set(_vm.review, "negotiationSkills", $$v)
                          },
                          expression: "review.negotiationSkills",
                        },
                      },
                      "star-rating",
                      _vm.starRatingConfig,
                      false
                    )
                  ),
                ],
                1
              ),
              _c("br"),
              _c("fieldset", [
                _vm._m(8),
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.review.headline,
                          expression: "review.headline",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "highly-likely",
                        value: "Highly likely to recommend",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.review.headline,
                          "Highly likely to recommend"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.review,
                            "headline",
                            "Highly likely to recommend"
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "highly-likely" } }, [
                      _vm._v("Highly likely"),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.review.headline,
                          expression: "review.headline",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "very-likely",
                        value: "Very likely to recommend",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.review.headline,
                          "Very likely to recommend"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.review,
                            "headline",
                            "Very likely to recommend"
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "very-likely" } }, [
                      _vm._v("Very likely"),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.review.headline,
                          expression: "review.headline",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "somewhat-likely",
                        value: "Somoewhat likely to recommend",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.review.headline,
                          "Somoewhat likely to recommend"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.review,
                            "headline",
                            "Somoewhat likely to recommend"
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "somewhat-likely" } }, [
                      _vm._v("Somewhat likely"),
                    ]),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.review.headline,
                          expression: "review.headline",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "not-likely",
                        value: "Not very likely to recommend",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.review.headline,
                          "Not very likely to recommend"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.review,
                            "headline",
                            "Not very likely to recommend"
                          )
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "not-likely" } }, [
                      _vm._v("Not very likely"),
                    ]),
                  ]),
                ]),
              ]),
              _c("p", { staticClass: "note-wrapper" }, [
                _c("label", { attrs: { for: "note" } }, [
                  _vm._v("Write your review:"),
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.review.note,
                      expression: "review.note",
                    },
                  ],
                  attrs: {
                    id: "note",
                    rows: "4",
                    maxlength: _vm.noteMaxLength,
                  },
                  domProps: { value: _vm.review.note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.review, "note", $event.target.value)
                    },
                  },
                }),
                _c("span", { staticClass: "note-info" }, [
                  _vm._v(
                    _vm._s(_vm.review.note.length) +
                      "/" +
                      _vm._s(_vm.noteMaxLength)
                  ),
                ]),
              ]),
              _c("br"),
              _vm._m(9),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "first-name" } }, [
      _c("span", [_vm._v("First Name:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "last-name" } }, [
      _c("span", [_vm._v("Last Name:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "email" } }, [
      _c("span", [_vm._v("Email Address:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "local-knowledge" } }, [
      _c("span", [_vm._v("Local knowledge:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "process-expertise" } }, [
      _c("span", [_vm._v("Process expertise:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "responsiveness" } }, [
      _c("span", [_vm._v("Responsiveness:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "negotiation-skills" } }, [
      _c("span", [_vm._v("Negotiation skills:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("legend", [
      _c("span", [
        _vm._v(
          "How likely are you to recommed American Smart Lending to a friend of colleague?"
        ),
      ]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("button", { staticClass: "primary", attrs: { type: "submit" } }, [
        _vm._v("Submit"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }