var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-footer" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "quick-links" }, [
        _c("h4", [_vm._v("Quick Links")]),
        _c("ul", { staticClass: "quickLinks-icons" }, [
          _vm._m(0),
          _vm._m(1),
          _c("li", [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "amortization-calculator" })
                  },
                },
              },
              [_vm._v(" Amortization Calculator ")]
            ),
          ]),
        ]),
      ]),
      _vm._m(2),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "http://www.consumerfinance.gov/f/201503_cfpb_your-home-loan-toolkit-web.pdf",
            target: "_blank",
          },
        },
        [_vm._v(" Home Loan Toolkit ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.nmlsconsumeraccess.org/",
            target: "_blank",
          },
        },
        [_vm._v(" NMLS Consumer Access ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "follow-us" }, [
      _c("h4", [_vm._v("Follow Us")]),
      _c("ul", { staticClass: "followUs-icons" }, [
        _c("li", { staticClass: "icon fa-facebook" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("facebook.com/tslg")]),
        ]),
        _c("li", { staticClass: "icon fa-twitter" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("twitter.com/tslg")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact" }, [
      _c("h4", [_vm._v("Contact Us")]),
      _c("ul", { staticClass: "contact-icons" }, [
        _c("li", { staticClass: "icon fa-home" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://goo.gl/maps/wXxVgidiQs3N17RPA",
                target: "_blank",
              },
            },
            [
              _vm._v(" Kadlogic Inc DBA American Smart Lending"),
              _c("br"),
              _vm._v(" ISAOA ATIMA "),
              _c("br"),
              _vm._v(" 1560 E.Southlake Blvd Ste.100"),
              _c("br"),
              _vm._v(" Southlake , TX, 76092"),
              _c("br"),
            ]
          ),
        ]),
        _c("li", { staticClass: "icon fa-phone" }, [
          _c("a", { attrs: { href: "tel:469-525-6725" } }, [
            _vm._v("Office: +1(469)525-6725"),
          ]),
        ]),
        _c("li", { staticClass: "icon fa-fax" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Fax: (888)506-9603")]),
        ]),
        _c("li", { staticClass: "icon fa-envelope" }, [
          _c("a", { attrs: { href: "mailto:info@AmericanSmartLending.com" } }, [
            _vm._v("info@AmericanSmartLending.com"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }