var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "photo-row",
    { attrs: { rowHeight: _vm.height } },
    _vm._l(_vm.photos, function (data, i) {
      return _c(
        "photo-grid",
        {
          key: i,
          on: {
            click: function ($event) {
              return _vm.$emit("itemClick")
            },
          },
        },
        [
          _vm.showNumOfRemainingPhotos &&
          _vm.remainingNum > 0 &&
          data.id === _vm.layoutNum - 1
            ? [
                _c("photo-mask"),
                _c(
                  "view-more",
                  [_c("num-of-remaining", [_vm._v(_vm._s(_vm.remainingNum))])],
                  1
                ),
              ]
            : _vm._e(),
          _c("photo-thumb", { attrs: { thumb: data.source } }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }