var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "#f8f8f8",
                "text-align": "center",
              },
            },
            [
              _c("h1", [_vm._v("Our valued clients!")]),
              _c(
                "photo-collage-wrapper",
                _vm._b(
                  {
                    attrs: { gapSize: "7px" },
                    on: { itemClick: _vm.itemClickHandler },
                  },
                  "photo-collage-wrapper",
                  _vm.collage,
                  false
                )
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("quickLinks"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }