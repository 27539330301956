var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "sectionEight", staticClass: "urla-section" }, [
    _c("h2", [_vm._v("Disclaimer")]),
    _vm._m(0),
    _vm._m(1),
    _c(
      "form",
      {
        attrs: { id: "disclaimer-section-form", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitSectionEightFromDisclaimer.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row margin-top-bottom" }, [
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.disclaimerFlag,
                  expression: "disclaimerFlag",
                },
              ],
              attrs: {
                name: "disclaimer",
                type: "checkbox",
                id: "disclaimer",
                value: "disclaimer",
              },
              domProps: {
                checked: Array.isArray(_vm.disclaimerFlag)
                  ? _vm._i(_vm.disclaimerFlag, "disclaimer") > -1
                  : _vm.disclaimerFlag,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.disclaimerFlag,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "disclaimer",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.disclaimerFlag = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.disclaimerFlag = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.disclaimerFlag = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "font-weight-normal",
                staticStyle: { width: "auto", "padding-right": "0px" },
                attrs: { for: "disclaimer" },
              },
              [_vm._v("I Agree to terms and conditions ")]
            ),
            _vm._m(2),
          ]),
          _c("div", { staticClass: "col-4 col-12-xsmall align-right" }),
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("span", { staticClass: "reffered-by-heading" }, [
              _vm._v("Signature: "),
            ]),
            _vm._m(3),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.signature,
                  expression: "signature",
                },
              ],
              attrs: { type: "text", placeholder: "Enter your name" },
              domProps: { value: _vm.signature },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.signature = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "button",
            {
              staticClass: "primary wizard__back pull-left",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goBack()
                },
              },
            },
            [_vm._m(4)]
          ),
          _c(
            "button",
            {
              staticClass: "primary wizard__next pull-right",
              class: { disabled: _vm.disableSubmit() },
              attrs: { type: "submit" },
            },
            [_c("span", [_vm._v("Submit")])]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "urla-section-description" }, [
      _vm._v(
        " I attest that the information submitted is true to the best of my knowledge at this point of time."
      ),
      _c("br"),
      _vm._v(
        " I agree to credit check from the lender for evaluating my application and to provide the basic disclosures and pricing estimated. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
        _vm._v("*"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
        _vm._v("* "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-arrow-left fa-lg" }),
      _vm._v(" back "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }