var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header", { attrs: { id: "header" } }, [
        _c(
          "span",
          { staticClass: "logo" },
          [
            _c("router-link", { attrs: { to: { name: "home" } } }, [
              _vm._v(" American Smart Lending "),
            ]),
          ],
          1
        ),
        _c("nav", { attrs: { id: "nav" } }, [
          _c("ul", [
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: { name: "urla" } } }, [
                  _vm._v("Apply for a loan"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: { name: "upload" } } }, [
                  _vm._v("Upload Files"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              {
                ref: "services",
                staticClass: "link depth-0",
                on: {
                  mouseover: function ($event) {
                    return _vm.expandDropdown("services", "servicesList", 100)
                  },
                  mouseleave: function ($event) {
                    return _vm.collapseDropdown("services")
                  },
                },
              },
              [
                _vm._v(" Services "),
                _c("span", {
                  staticClass: "fa fa-angle-down",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: { name: "contact" } } }, [
                  _vm._v("Contact"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "success-stories" } } },
                  [_vm._v("Success Stories")]
                ),
              ],
              1
            ),
            _c("li"),
          ]),
        ]),
        !_vm.isSignedIn && !_vm.isHandHeldDevice
          ? _c("div", { attrs: { id: "userLogin" } }, [
              _c(
                "div",
                {
                  ref: "login",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "sign-in" })
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-arrow-right-to-bracket" }),
                  _vm._v("   SignIn  "),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "sign-up" })
                    },
                  },
                },
                [_vm._v("Register ")]
              ),
            ])
          : _vm._e(),
        _vm.isSignedIn && !_vm.isHandHeldDevice
          ? _c(
              "div",
              {
                staticClass: "signin__block",
                on: {
                  click: function ($event) {
                    return _vm.signOut()
                  },
                },
              },
              [
                _vm._v(" Sign out    "),
                _c("i", { staticClass: "fa fa-arrow-right-from-bracket" }),
              ]
            )
          : _vm._e(),
        _c(
          "a",
          {
            staticClass: "navPanelToggle",
            attrs: { href: "#navPanel" },
            on: {
              click: function ($event) {
                _vm.isVisible = true
              },
            },
          },
          [_vm._v("Menu")]
        ),
      ]),
      _c(
        "div",
        { class: { visible: _vm.isVisible }, attrs: { id: "navPanel" } },
        [
          _vm.isSignedIn
            ? _c("div", { staticClass: "userProfile" }, [
                _c("div", [
                  _vm._m(0),
                  _c("div", [_vm._v(_vm._s(_vm.username))]),
                ]),
              ])
            : _c("div", { staticClass: "userLogin" }, [
                !_vm.isSignedIn
                  ? _c(
                      "a",
                      {
                        staticClass: "button link depth-0 nav-link",
                        staticStyle: {
                          width: "30%",
                          "line-height": "normal",
                          "border-radius": "8px 0 0 8px",
                          "padding-left": "0%",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isVisible = false
                            _vm.$router.push({ name: "sign-in" })
                          },
                        },
                      },
                      [_vm._v(" Sign In ")]
                    )
                  : _vm._e(),
                !_vm.isSignedIn
                  ? _c(
                      "a",
                      {
                        staticClass: "button link depth-0 nav-link",
                        staticStyle: {
                          width: "30%",
                          "line-height": "normal",
                          "border-radius": "0 8px 8px 0",
                          "padding-left": "0%",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isVisible = false
                            _vm.$router.push({ name: "sign-up" })
                          },
                        },
                      },
                      [_vm._v(" Register ")]
                    )
                  : _vm._e(),
              ]),
          _c("a", {
            staticClass: "close",
            attrs: { href: "#navPanel" },
            on: {
              click: function ($event) {
                _vm.isVisible = false
              },
            },
          }),
          _c("nav", { attrs: { id: "nav" } }, [
            _c(
              "a",
              {
                staticClass: "link depth-0 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "home" })
                  },
                },
              },
              [_vm._v("Home")]
            ),
            _c("a", { staticClass: "link depth-0 nav-link" }, [
              _vm._v("Services"),
            ]),
            _c(
              "a",
              {
                staticClass: "link depth-1 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "amortization-calculator" })
                  },
                },
              },
              [
                _c("span", { staticClass: "indent-1" }),
                _vm._v(" Amortization Calculator "),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "link depth-1 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "upload" })
                  },
                },
              },
              [
                _c("span", { staticClass: "indent-1" }),
                _vm._v(" Upload Files "),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "link depth-1 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "new-or-continue" })
                  },
                },
              },
              [
                _c("span", { staticClass: "indent-1" }),
                _vm._v(" Apply for Mortgage/Refinance "),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "link depth-1 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "faq" })
                  },
                },
              },
              [_c("span", { staticClass: "indent-1" }), _vm._v(" FAQ ")]
            ),
            _c(
              "a",
              {
                staticClass: "link depth-0 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "contact" })
                  },
                },
              },
              [_vm._v("Contact")]
            ),
            _c(
              "a",
              {
                staticClass: "link depth-0 nav-link",
                on: {
                  click: function ($event) {
                    _vm.isVisible = false
                    _vm.$router.push({ name: "success-stories" })
                  },
                },
              },
              [_vm._v("Success Stories")]
            ),
            _c("div", { staticClass: "navPanel__signout" }, [
              _vm.isSignedIn
                ? _c(
                    "a",
                    {
                      staticClass: "button link depth-0",
                      on: {
                        click: function ($event) {
                          return _vm.signOut()
                        },
                      },
                    },
                    [_vm._v("Sign out")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c("transition", { attrs: { name: "fade", tag: "ul" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDropDownFor.services,
                expression: "showDropDownFor.services",
              },
            ],
            ref: "servicesList",
            staticClass: "dropotron level-0 right droplist services",
            on: {
              mouseover: function ($event) {
                return _vm.expandDropdown("services", "servicesList", 100)
              },
              mouseleave: function ($event) {
                return _vm.collapseDropdown("services")
              },
            },
          },
          [
            _c("li", { staticStyle: { "white-space": "nowrap" } }, [
              _c(
                "a",
                {
                  staticClass: "link",
                  staticStyle: { display: "block" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "amortization-calculator",
                      })
                    },
                  },
                },
                [_vm._v("Amortization Calculator")]
              ),
            ]),
            _c(
              "li",
              { staticStyle: { "white-space": "nowrap" } },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "link",
                    staticStyle: { display: "block" },
                    attrs: { to: { name: "upload" } },
                    on: {
                      click: function ($event) {
                        _vm.isVisible = false
                      },
                    },
                  },
                  [_vm._v(" Upload Files ")]
                ),
              ],
              1
            ),
            _c(
              "li",
              { staticStyle: { "white-space": "nowrap" } },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "link",
                    staticStyle: { display: "block" },
                    attrs: { to: { name: "new-or-continue" } },
                    on: {
                      click: function ($event) {
                        _vm.isVisible = false
                      },
                    },
                  },
                  [_vm._v("Apply for Mortgage/Refinance ")]
                ),
              ],
              1
            ),
            _c("li", { staticStyle: { "white-space": "nowrap" } }, [
              _c(
                "a",
                {
                  staticClass: "link",
                  staticStyle: { display: "block" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "contact" })
                    },
                  },
                },
                [_vm._v("Licensing information")]
              ),
            ]),
            _c("li", { staticStyle: { "white-space": "nowrap" } }, [
              _c(
                "a",
                {
                  staticClass: "link",
                  staticStyle: { display: "block" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "disclosure" })
                    },
                  },
                },
                [_vm._v("Important Disclosure")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "link",
                  staticStyle: { display: "block" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "faq" })
                    },
                  },
                },
                [_vm._v("FAQ")]
              ),
            ]),
          ]
        ),
      ]),
      _c("transition", { attrs: { name: "fade", tag: "ul" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDropDownFor.users,
                expression: "showDropDownFor.users",
              },
            ],
            ref: "usersList",
            staticClass: "dropotron level-0 right droplist users",
            attrs: { id: "signout__button" },
            on: {
              mouseover: function ($event) {
                return _vm.expandDropdown("users", "usersList", 50)
              },
              mouseleave: function ($event) {
                return _vm.collapseDropdown("users")
              },
            },
          },
          [
            _c(
              "li",
              {
                staticStyle: {
                  "white-space": "nowrap",
                  "text-align": "center",
                },
              },
              [
                _vm.isSignedIn
                  ? _c(
                      "a",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "line-height": "normal",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.signOut()
                          },
                        },
                      },
                      [
                        _vm._v("Sign out  "),
                        _c("i", {
                          staticClass: "fa fa-arrow-right-from-bracket",
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
      _c("transition", { attrs: { name: "fade", tag: "ul" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDropDownFor.login,
                expression: "showDropDownFor.login",
              },
            ],
            ref: "loginList",
            staticClass: "dropotron level-0 right droplist login",
            attrs: { id: "signout__button" },
            on: {
              mouseover: function ($event) {
                return _vm.expandDropdown("login", "loginList", 50)
              },
              mouseleave: function ($event) {
                return _vm.collapseDropdown("login")
              },
            },
          },
          [
            _c("li", { staticStyle: { "white-space": "nowrap" } }, [
              _c(
                "a",
                {
                  staticClass: "link depth-0",
                  staticStyle: {
                    display: "inline-block",
                    "line-height": "normal",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "sign-up" })
                    },
                  },
                },
                [_vm._v("New Customer? Sign Up ")]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "fa fa-circle-user" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }