var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "validation-icon" } }, [
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isValid === 1,
          expression: "isValid === 1",
        },
      ],
      staticClass: "fa fa-2x fa-check valid",
      attrs: { "aria-hidden": "true" },
    }),
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isValid === 0,
          expression: "isValid === 0",
        },
      ],
      staticClass: "fa fa-2x fa-times invalid",
      attrs: { "aria-hidden": "true" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }