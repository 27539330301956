var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "main" }, [
    _c("section", { staticClass: "urla-summary" }, [
      _c("h2", [_vm._v("Application submitted!")]),
      _c("h3", { staticClass: "upload-docs-msg" }, [
        _vm._v(" Please upload the below documents "),
        _c(
          "a",
          {
            staticClass: "link",
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "upload" })
              },
            },
          },
          [_vm._v(" here "), _c("i", { staticClass: "icon fa-external-link" })]
        ),
      ]),
      _c("h4", [
        _vm._v("Property related documentation required to be uploaded"),
      ]),
      _c("ol", [
        _c("li", [_vm._v("Most recent mortgage statement")]),
        _c("li", [
          _vm._v(
            " Survey of the property given during the original purchase of the house "
          ),
        ]),
        _c("li", [
          _vm._v(
            " HOA statement or payment history showing the HOA contact details and the monthly payment liability "
          ),
        ]),
        _c("li", [
          _vm._v(
            " current insurance binder and insurance agent email address and phone number "
          ),
        ]),
        _c("li", [
          _vm._v(
            " NOTE, deed of trust and closing disclosure from the most recent purchase or refinance transaction "
          ),
        ]),
        "PURCHASE" === _vm.loanPurpose.toUpperCase()
          ? _c("li", [_vm._v(" Purchase Order ")])
          : _vm._e(),
      ]),
      _c("h4", [_vm._v("Income and asset requirements")]),
      _vm._m(0),
      _c("h4", [_vm._v("Identification and work eligibility")]),
      _vm._m(1),
      _c("p", [
        _vm._v(
          " We appreciate you for considering American Smart Lending for your mortgage needs. We will get back to you as soon as possible. "
        ),
      ]),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("Three recent paystubs")]),
      _c("li", [_vm._v("Two recent W2s")]),
      _c("li", [_vm._v("Two recent bank statements")]),
      _c("li", [_vm._v("Most recent tax return")]),
      _c("li", [
        _vm._v("Bank statement showing the payment or refund from the IRS"),
      ]),
      _c("li", [
        _vm._v("If you own more than 25% of any company we need the following"),
      ]),
      _c("ul", [
        _c("li", [_vm._v("K1 showing income from the company")]),
        _c("li", [_vm._v("Most recent business tax returns")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          " if you are a U.S.citizen please provide clear front and back pictures of your Drivers license "
        ),
      ]),
      _c("li", [
        _vm._v(
          " If you are green card holder please provide clear front and back pictures of your green card "
        ),
      ]),
      _c("li", [_vm._v("if you are on work visa, please provide")]),
      _c("ul", { staticClass: "circle" }, [
        _c("li", [_vm._v("recent valid passport picture")]),
        _c("li", [_vm._v("Valid I-797 both pages clearly scanned as a PDF")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Please do not hesitate to reach us at "),
      _c("a", { attrs: { href: "tel:469-525-6725" } }, [
        _vm._v("+1(469)525-6725"),
      ]),
      _vm._v(" or "),
      _c("a", { attrs: { href: "mailto:kc@texsmartlending.com" } }, [
        _vm._v("kc@texsmartlending.com"),
      ]),
      _vm._v(" in case you need to reach us before the end of the day. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }