var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "sectionFour", staticClass: "urla-section" }, [
    _c("h2", [_vm._v("Loan and Property Information")]),
    _c("p", { staticClass: "urla-section-description" }, [
      _vm._v(
        " This section asks about the loan's purpose and the property you want to purchase or refinance. "
      ),
    ]),
    _vm._m(0),
    _c(
      "form",
      {
        attrs: { id: "section-4-form", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitSectionFour.apply(null, arguments)
          },
        },
      },
      [
        _c("div", [
          _c(
            "div",
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 col-12-xsmall urla-left-section" },
                  [
                    _c(
                      "p",
                      [
                        _vm._m(1),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.lazy",
                              value:
                                _vm.section4LoanAndPropertyInformation
                                  .part4aLoanInformation.propertyValue,
                              expression:
                                "\n                  section4LoanAndPropertyInformation.part4aLoanInformation\n                    .propertyValue\n                ",
                              modifiers: { lazy: true },
                            },
                            {
                              name: "money",
                              rawName: "v-money",
                              value:
                                _vm.section4LoanAndPropertyInformation
                                  .part4aLoanInformation.propertyValue !== null
                                  ? _vm.currencyFormat
                                  : null,
                              expression:
                                "\n                  section4LoanAndPropertyInformation.part4aLoanInformation\n                    .propertyValue !== null\n                    ? currencyFormat\n                    : null\n                ",
                            },
                          ],
                          staticClass: "small-input",
                          attrs: {
                            id: "property-value",
                            type: "text",
                            required: "",
                          },
                          domProps: {
                            value:
                              _vm.section4LoanAndPropertyInformation
                                .part4aLoanInformation.propertyValue,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.validatePropertyValue($event)
                            },
                            invalid: function ($event) {
                              _vm.validations.part4aLoanInformation.propertyValue = 0
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.section4LoanAndPropertyInformation
                                  .part4aLoanInformation,
                                "propertyValue",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("validation-icon", {
                          attrs: {
                            isValid:
                              _vm.validations.part4aLoanInformation
                                .propertyValue,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      [
                        _vm._m(2),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.lazy",
                              value:
                                _vm.section4LoanAndPropertyInformation
                                  .part4aLoanInformation.loanAmount,
                              expression:
                                "\n                  section4LoanAndPropertyInformation.part4aLoanInformation\n                    .loanAmount\n                ",
                              modifiers: { lazy: true },
                            },
                            {
                              name: "money",
                              rawName: "v-money",
                              value:
                                _vm.section4LoanAndPropertyInformation
                                  .part4aLoanInformation.loanAmount !== null
                                  ? _vm.currencyFormat
                                  : null,
                              expression:
                                "\n                  section4LoanAndPropertyInformation.part4aLoanInformation\n                    .loanAmount !== null\n                    ? currencyFormat\n                    : null\n                ",
                            },
                          ],
                          staticClass: "small-input",
                          attrs: {
                            id: "loan-amount",
                            type: "text",
                            required: "",
                          },
                          domProps: {
                            value:
                              _vm.section4LoanAndPropertyInformation
                                .part4aLoanInformation.loanAmount,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.validateLoanAmount($event)
                            },
                            invalid: function ($event) {
                              _vm.validations.part4aLoanInformation.loanAmount = 0
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.section4LoanAndPropertyInformation
                                  .part4aLoanInformation,
                                "loanAmount",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("validation-icon", {
                          attrs: {
                            isValid:
                              _vm.validations.part4aLoanInformation.loanAmount,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._m(3),
                      _c(
                        "fieldset",
                        {
                          staticClass: "urla-radio radio-inline",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c("ul", { staticClass: "loan-purpose" }, [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.loanPurpose,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.loanPurpose\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "loan-purpose",
                                  type: "radio",
                                  id: "purchase",
                                  value: "Purchase",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.loanPurpose,
                                    "Purchase"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation,
                                        "loanPurpose",
                                        "Purchase"
                                      )
                                    },
                                    _vm.setLoansOnProperty,
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "purchase" },
                                },
                                [_vm._v("Purchase")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.loanPurpose,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.loanPurpose\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "loan-purpose",
                                  type: "radio",
                                  id: "refinance",
                                  value: "Refinance",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.loanPurpose,
                                    "Refinance"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation,
                                        "loanPurpose",
                                        "Refinance"
                                      )
                                    },
                                    _vm.setLoansOnProperty,
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "refinance" },
                                },
                                [_vm._v("Refinance")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.loanPurpose,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.loanPurpose\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "loan-purpose",
                                  type: "radio",
                                  id: "pre-approval-letter",
                                  value: "Pre-approval letter",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.loanPurpose,
                                    "Pre-approval letter"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation,
                                        "loanPurpose",
                                        "Pre-approval letter"
                                      )
                                    },
                                    _vm.setLoansOnProperty,
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "pre-approval-letter" },
                                },
                                [_vm._v("Pre-approval letter")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4 col-12-xsmall" }, [
                        _vm._m(4),
                        _c("fieldset", { staticClass: "urla-radio" }, [
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .preferredLoanProgram,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.preferredLoanProgram\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "preferred-loan-program",
                                  type: "radio",
                                  id: "fixed-30",
                                  value: "30-Year Fixed",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .preferredLoanProgram,
                                    "30-Year Fixed"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "preferredLoanProgram",
                                      "30-Year Fixed"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "fixed-30" },
                                },
                                [_vm._v("30-Year Fixed")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .preferredLoanProgram,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.preferredLoanProgram\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "preferred-loan-program",
                                  type: "radio",
                                  id: "fixed-20",
                                  value: "20-Year Fixed",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .preferredLoanProgram,
                                    "20-Year Fixed"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "preferredLoanProgram",
                                      "20-Year Fixed"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "fixed-20" },
                                },
                                [_vm._v("20-Year Fixed")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .preferredLoanProgram,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.preferredLoanProgram\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "preferred-loan-program",
                                  type: "radio",
                                  id: "fixed-15",
                                  value: "15-Year Fixed",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .preferredLoanProgram,
                                    "15-Year Fixed"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "preferredLoanProgram",
                                      "15-Year Fixed"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "fixed-15" },
                                },
                                [_vm._v("15-Year Fixed")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .preferredLoanProgram,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.preferredLoanProgram\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "preferred-loan-program",
                                  type: "radio",
                                  id: "arm-7",
                                  value: "7-Year ARM",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .preferredLoanProgram,
                                    "7-Year ARM"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "preferredLoanProgram",
                                      "7-Year ARM"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "arm-7" },
                                },
                                [_vm._v("7-Year ARM")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-1 urla-left-section" }),
                      _c("div", { staticClass: "col-1" }),
                      _c("div", { staticClass: "col-6 col-12-xsmall" }, [
                        _vm._m(5),
                        _c("fieldset", { staticClass: "urla-radio" }, [
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.occupancy,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.occupancy\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "occupancy",
                                  type: "radio",
                                  id: "primary-residence",
                                  value: "Primary Residence",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.occupancy,
                                    "Primary Residence"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "occupancy",
                                      "Primary Residence"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "primary-residence" },
                                },
                                [_vm._v("Primary Residence")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.occupancy,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.occupancy\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "occupancy",
                                  type: "radio",
                                  id: "second-home",
                                  value: "Second Home",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.occupancy,
                                    "Second Home"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "occupancy",
                                      "Second Home"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "second-home" },
                                },
                                [_vm._v("Second Home")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.occupancy,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.occupancy\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "occupancy",
                                  type: "radio",
                                  id: "investment-property",
                                  value: "Investment Property",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.occupancy,
                                    "Investment Property"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "occupancy",
                                      "Investment Property"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "investment-property" },
                                },
                                [_vm._v("Investment Property")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.occupancy,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.occupancy\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "occupancy",
                                  type: "radio",
                                  id: "fha-secondary-residence",
                                  value: "FHA Secondary Residence",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.occupancy,
                                    "FHA Secondary Residence"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "occupancy",
                                      "FHA Secondary Residence"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "fha-secondary-residence" },
                                },
                                [_vm._v("FHA Secondary Residence")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.occupancy,
                                    expression:
                                      "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.occupancy\n                        ",
                                  },
                                ],
                                attrs: {
                                  name: "occupancy",
                                  type: "radio",
                                  id: "commercial-space",
                                  value: "Commercial Space",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.occupancy,
                                    "Commercial Space"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "occupancy",
                                      "Commercial Space"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal",
                                  attrs: { for: "commercial-space" },
                                },
                                [_vm._v("Commercial Space")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "question-styling" }, [
                      _vm._m(6),
                      _c(
                        "fieldset",
                        {
                          staticClass: "urla-radio radio-inline",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c("ul", { staticClass: "type-of-house" }, [
                            _c("li", { staticStyle: { display: "block" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.typeOfHouse,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.typeOfHouse\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "type-of-house",
                                  type: "radio",
                                  id: "Condo",
                                  value: "Condo",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.typeOfHouse,
                                    "Condo"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "typeOfHouse",
                                      "Condo"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "Condo" },
                                },
                                [_vm._v("Condo")]
                              ),
                            ]),
                            _c("li", { staticStyle: { display: "block" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.typeOfHouse,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.typeOfHouse\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "type-of-house",
                                  type: "radio",
                                  id: "Townhome",
                                  value: "Townhome",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.typeOfHouse,
                                    "Townhome"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "typeOfHouse",
                                      "Townhome"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "Townhome" },
                                },
                                [_vm._v("Townhome")]
                              ),
                            ]),
                            _c("li", { staticStyle: { display: "block" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.typeOfHouse,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.typeOfHouse\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "type-of-house",
                                  type: "radio",
                                  id: "Single-family-home",
                                  value: "Single-family home",
                                  required: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.typeOfHouse,
                                    "Single-family home"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "typeOfHouse",
                                      "Single-family home"
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  staticStyle: { "white-space": "nowrap" },
                                  attrs: { for: "Single-family-home" },
                                },
                                [_vm._v("Single-family home")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", [
                      _vm._m(7),
                      _c(
                        "fieldset",
                        { staticClass: "urla-radio radio-inline" },
                        [
                          _c("ul", [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .isManufacturedHome,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.isManufacturedHome\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "manufactured-home",
                                  type: "radio",
                                  id: "manufactured-home-yes",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.isManufacturedHome,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "isManufacturedHome",
                                      true
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "manufactured-home-yes" },
                                },
                                [_vm._v("Yes")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .isManufacturedHome,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.isManufacturedHome\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "manufactured-home",
                                  type: "radio",
                                  id: "manufactured-home-no",
                                  required: "",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.isManufacturedHome,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "isManufacturedHome",
                                      false
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "manufactured-home-no" },
                                },
                                [_vm._v("No")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.loanPurpose === "Refinance"
                        ? _c("div", [
                            _c("div", { staticClass: "question-styling" }, [
                              _c("p", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "urla-radio-label large-label",
                                    attrs: { for: "refinance-current-address" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Are you refinancing your current address?"
                                      ),
                                    ]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "urla-radio radio-inline",
                                  staticStyle: { display: "inline" },
                                },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4aLoanInformation
                                                .refinanceCurrentAddress,
                                            expression:
                                              "\n                            section4LoanAndPropertyInformation\n                              .part4aLoanInformation.refinanceCurrentAddress\n                          ",
                                          },
                                        ],
                                        attrs: {
                                          name: "refinance-current-address",
                                          type: "radio",
                                          id: "refinance-current-address-yes",
                                          required: "",
                                        },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .refinanceCurrentAddress,
                                            true
                                          ),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              return _vm.$set(
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4aLoanInformation,
                                                "refinanceCurrentAddress",
                                                true
                                              )
                                            },
                                            _vm.prefillPropertyAddress,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-normal small-label",
                                          attrs: {
                                            for: "refinance-current-address-yes",
                                          },
                                        },
                                        [_vm._v("Yes")]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4aLoanInformation
                                                .refinanceCurrentAddress,
                                            expression:
                                              "\n                            section4LoanAndPropertyInformation\n                              .part4aLoanInformation.refinanceCurrentAddress\n                          ",
                                          },
                                        ],
                                        attrs: {
                                          name: "refinance-current-address",
                                          type: "radio",
                                          id: "refinance-current-address-no",
                                          required: "",
                                        },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .refinanceCurrentAddress,
                                            false
                                          ),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              return _vm.$set(
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4aLoanInformation,
                                                "refinanceCurrentAddress",
                                                false
                                              )
                                            },
                                            _vm.prefillPropertyAddress,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-normal small-label",
                                          attrs: {
                                            for: "refinance-current-address-no",
                                          },
                                        },
                                        [_vm._v("No")]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 col-12-xsmall" },
                  [
                    _c("div", { staticClass: "question-styling" }, [
                      _vm._m(8),
                      _c(
                        "fieldset",
                        {
                          staticClass: "urla-radio radio-inline",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c("ul", { staticClass: "type-of-house" }, [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .isPropertyHeldInATrust,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.isPropertyHeldInATrust\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "isPropertyHeldInATrust",
                                  type: "radio",
                                  id: "isPropertyHeldInATrust-true",
                                  required: "",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .isPropertyHeldInATrust,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "isPropertyHeldInATrust",
                                      true
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "isPropertyHeldInATrust-true" },
                                },
                                [_vm._v("Yes")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .isPropertyHeldInATrust,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.isPropertyHeldInATrust\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "isPropertyHeldInATrust",
                                  type: "radio",
                                  id: "isPropertyHeldInATrust-false",
                                  required: "",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .isPropertyHeldInATrust,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "isPropertyHeldInATrust",
                                      false
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: {
                                    for: "isPropertyHeldInATrust-false",
                                  },
                                },
                                [_vm._v("No")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "question-styling" }, [
                      _vm._m(9),
                      _c(
                        "fieldset",
                        {
                          staticClass: "urla-radio radio-inline",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c("ul", { staticClass: "type-of-house" }, [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.hasSolarPanels,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.hasSolarPanels\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "hasSolarPanels",
                                  type: "radio",
                                  id: "hasSolarPanels-true",
                                  required: "",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.hasSolarPanels,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "hasSolarPanels",
                                      true
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "hasSolarPanels-true" },
                                },
                                [_vm._v("Yes")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.hasSolarPanels,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.hasSolarPanels\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "hasSolarPanels",
                                  type: "radio",
                                  id: "hasSolarPanels-false",
                                  required: "",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.hasSolarPanels,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "hasSolarPanels",
                                      false
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "hasSolarPanels-false" },
                                },
                                [_vm._v("No")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "question-styling" }, [
                      _vm._m(10),
                      _c(
                        "fieldset",
                        {
                          staticClass: "urla-radio radio-inline",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c("ul", { staticClass: "type-of-house" }, [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .isCondoWithoutSharedWalls,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.isCondoWithoutSharedWalls\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "isCondoWithoutSharedWalls",
                                  type: "radio",
                                  id: "Condo-true",
                                  required: "",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .isCondoWithoutSharedWalls,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "isCondoWithoutSharedWalls",
                                      true
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "Condo-true" },
                                },
                                [_vm._v("Yes")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation
                                        .isCondoWithoutSharedWalls,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.isCondoWithoutSharedWalls\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "isCondoWithoutSharedWalls",
                                  type: "radio",
                                  id: "Condo-false",
                                  required: "",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation
                                      .isCondoWithoutSharedWalls,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation,
                                      "isCondoWithoutSharedWalls",
                                      false
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: { for: "Condo-false" },
                                },
                                [_vm._v("No")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", [
                      _vm._m(11),
                      _c(
                        "fieldset",
                        {
                          staticClass: "urla-radio radio-inline",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c("ul", { staticClass: "type-of-house" }, [
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4eAdditionalLoanInformation
                                        .isTravellingDuringClosing,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4eAdditionalLoanInformation\n                          .isTravellingDuringClosing\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "isTravellingDuringClosing",
                                  type: "radio",
                                  id: "isTravellingDuringClosing-true",
                                  required: "",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4eAdditionalLoanInformation
                                      .isTravellingDuringClosing,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4eAdditionalLoanInformation,
                                      "isTravellingDuringClosing",
                                      true
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: {
                                    for: "isTravellingDuringClosing-true",
                                  },
                                },
                                [_vm._v("Yes")]
                              ),
                            ]),
                            _c("li", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4eAdditionalLoanInformation
                                        .isTravellingDuringClosing,
                                    expression:
                                      "\n                        section4LoanAndPropertyInformation\n                          .part4eAdditionalLoanInformation\n                          .isTravellingDuringClosing\n                      ",
                                  },
                                ],
                                attrs: {
                                  name: "isTravellingDuringClosing",
                                  type: "radio",
                                  id: "isTravellingDuringClosing-false",
                                  required: "",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.section4LoanAndPropertyInformation
                                      .part4eAdditionalLoanInformation
                                      .isTravellingDuringClosing,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4eAdditionalLoanInformation,
                                      "isTravellingDuringClosing",
                                      false
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "font-weight-normal small-label",
                                  attrs: {
                                    for: "isTravellingDuringClosing-false",
                                  },
                                },
                                [_vm._v("No")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.loanPurpose == "Purchase"
                        ? _c("div", [
                            _c("p", [
                              _c(
                                "label",
                                {
                                  staticClass: "urla-radio-label",
                                  attrs: { for: "builder-or-individual" },
                                },
                                [
                                  _c("span", [
                                    _vm._v("I am purchasing the house from"),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "fieldset",
                              {
                                staticClass: "urla-radio radio-inline",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "builder-or-individual" },
                                  [
                                    _c("li", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4aLoanInformation
                                                .isPurchasingFromBuilder,
                                            expression:
                                              "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.isPurchasingFromBuilder\n                        ",
                                          },
                                        ],
                                        attrs: {
                                          name: "builder-or-individual",
                                          type: "radio",
                                          id: "individualSeller",
                                          required: "",
                                        },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .isPurchasingFromBuilder,
                                            false
                                          ),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              return _vm.$set(
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4aLoanInformation,
                                                "isPurchasingFromBuilder",
                                                false
                                              )
                                            },
                                            _vm.setLoansOnProperty,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-normal medium-label",
                                          attrs: { for: "individualSeller" },
                                        },
                                        [_vm._v("Individual Seller")]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4aLoanInformation
                                                .isPurchasingFromBuilder,
                                            expression:
                                              "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.isPurchasingFromBuilder\n                        ",
                                          },
                                        ],
                                        attrs: {
                                          name: "builder-or-individual",
                                          type: "radio",
                                          id: "builder",
                                        },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .isPurchasingFromBuilder,
                                            true
                                          ),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              return _vm.$set(
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4aLoanInformation,
                                                "isPurchasingFromBuilder",
                                                true
                                              )
                                            },
                                            _vm.setLoansOnProperty,
                                          ],
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-normal medium-label",
                                          attrs: { for: "builder" },
                                        },
                                        [_vm._v("Builder")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("transition", { attrs: { name: "fade" } }, [
                                  _vm.section4LoanAndPropertyInformation
                                    .part4aLoanInformation
                                    .isPurchasingFromBuilder == true
                                    ? _c(
                                        "p",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "medium-label",
                                              attrs: { for: "builderName" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Builder Name:"),
                                              ]),
                                              _c("strong", [
                                                _c(
                                                  "abbr",
                                                  {
                                                    staticClass: "required",
                                                    attrs: {
                                                      title: "required",
                                                    },
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm
                                                    .section4LoanAndPropertyInformation
                                                    .part4aLoanInformation
                                                    .builderName,
                                                expression:
                                                  "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.builderName\n                        ",
                                              },
                                            ],
                                            staticClass: "medium-input",
                                            attrs: {
                                              required: "",
                                              type: "text",
                                              id: "builderName",
                                              placeholder: "Builder Name",
                                            },
                                            domProps: {
                                              value:
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4aLoanInformation
                                                  .builderName,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm
                                                      .section4LoanAndPropertyInformation
                                                      .part4aLoanInformation,
                                                    "builderName",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.validateBuilderName(
                                                    $event
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _c("validation-icon", {
                                            attrs: {
                                              isValid:
                                                _vm.validations
                                                  .part4aLoanInformation
                                                  .builderName,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _c("div", [
                              _c(
                                "p",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "medium-label",
                                      attrs: { for: "closingDate" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Closing Date (appx.):"),
                                      ]),
                                      _c("strong", [
                                        _c(
                                          "abbr",
                                          {
                                            staticClass: "required",
                                            attrs: { title: "required" },
                                          },
                                          [_vm._v("*")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("date-pick", {
                                    staticClass: "date-pick medium-input",
                                    attrs: {
                                      id: "lock-expires",
                                      inputAttributes: {
                                        placeholder: "MM/DD/YYYY",
                                        required: "required",
                                      },
                                      required: "",
                                      isDateDisabled: _vm.isPastDate,
                                      displayFormat: "MM/DD/YYYY",
                                    },
                                    model: {
                                      value:
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation
                                          .plannedClosingDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation,
                                          "plannedClosingDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        section4LoanAndPropertyInformation\n                          .part4aLoanInformation.plannedClosingDate\n                      ",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", [
                              _c("p", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "urla-radio-label",
                                    staticStyle: { width: "100%" },
                                    attrs: { for: "loan-purchase" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Is your realtor providing any credit towards the closing cost?"
                                      ),
                                    ]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "urla-radio radio-inline",
                                  staticStyle: { display: "inline" },
                                },
                                [
                                  _c("ul", { staticClass: "loan-purchase" }, [
                                    _c("li", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4eAdditionalLoanInformation
                                                .hasRealEstateCommission,
                                            expression:
                                              "\n                            section4LoanAndPropertyInformation\n                              .part4eAdditionalLoanInformation\n                              .hasRealEstateCommission\n                          ",
                                          },
                                        ],
                                        attrs: {
                                          name: "hasRealEstateCommission",
                                          type: "radio",
                                          id: "hasRealEstateCommission-true",
                                          required: "",
                                        },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4eAdditionalLoanInformation
                                              .hasRealEstateCommission,
                                            true
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4eAdditionalLoanInformation,
                                              "hasRealEstateCommission",
                                              true
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-normal medium-label",
                                          attrs: {
                                            for: "hasRealEstateCommission-true",
                                          },
                                        },
                                        [_vm._v("Yes")]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4eAdditionalLoanInformation
                                                .hasRealEstateCommission,
                                            expression:
                                              "\n                            section4LoanAndPropertyInformation\n                              .part4eAdditionalLoanInformation\n                              .hasRealEstateCommission\n                          ",
                                          },
                                        ],
                                        attrs: {
                                          name: "hasRealEstateCommission",
                                          type: "radio",
                                          id: "hasRealEstateCommission-false",
                                          required: "",
                                        },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4eAdditionalLoanInformation
                                              .hasRealEstateCommission,
                                            false
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4eAdditionalLoanInformation,
                                              "hasRealEstateCommission",
                                              false
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-normal medium-label",
                                          attrs: {
                                            for: "hasRealEstateCommission-false",
                                          },
                                        },
                                        [_vm._v("No")]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.loanPurpose == "Refinance"
                        ? _c("div", { staticClass: "question-styling" }, [
                            _c("p", { staticStyle: { display: "inline" } }, [
                              _c(
                                "label",
                                {
                                  staticClass: "urla-radio-label",
                                  staticStyle: { width: "100%" },
                                  attrs: { for: "isRefinancedBefore" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Was this property refinanced before?"
                                    ),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "fieldset",
                              {
                                staticClass: "urla-radio radio-inline",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c("ul", { staticClass: "type-of-house" }, [
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .isRefinancedBefore,
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.isRefinancedBefore\n                        ",
                                        },
                                      ],
                                      attrs: {
                                        name: "isRefinancedBefore",
                                        type: "radio",
                                        id: "isRefinancedBefore-true",
                                        required: "",
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .isRefinancedBefore,
                                          true
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation,
                                            "isRefinancedBefore",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for: "isRefinancedBefore-true",
                                        },
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .isRefinancedBefore,
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.isRefinancedBefore\n                        ",
                                        },
                                      ],
                                      attrs: {
                                        name: "isRefinancedBefore",
                                        type: "radio",
                                        id: "isRefinancedBefore-false",
                                        required: "",
                                      },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .isRefinancedBefore,
                                          false
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation,
                                            "isRefinancedBefore",
                                            false
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for: "isRefinancedBefore-false",
                                        },
                                      },
                                      [_vm._v("No")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.isRefinancedBefore == true &&
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.loanPurpose == "Refinance"
                        ? _c("div", { staticClass: "question-styling" }, [
                            _c("p", { staticStyle: { display: "inline" } }, [
                              _c(
                                "label",
                                {
                                  staticClass: "urla-radio-label",
                                  staticStyle: { width: "100%" },
                                  attrs: { for: "lastRefinanceDate" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "When was the last time this property was refinanced?"
                                    ),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "fieldset",
                              {
                                staticClass: "urla-radio radio-inline",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c("ul", { staticClass: "type-of-house" }, [
                                  _c(
                                    "li",
                                    [
                                      _c("date-pick", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##/##/####",
                                            expression: "'##/##/####'",
                                          },
                                        ],
                                        staticClass: "date-pick medium-input",
                                        attrs: {
                                          id: "lastRefinanceDate",
                                          inputAttributes: {
                                            placeholder: "MM/DD/YYYY",
                                            required: "required",
                                          },
                                          isDateDisabled: _vm.isFutureDate,
                                          displayFormat: "MM/DD/YYYY",
                                        },
                                        model: {
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .lastRefinanceDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm
                                                .section4LoanAndPropertyInformation
                                                .part4aLoanInformation,
                                              "lastRefinanceDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.lastRefinanceDate\n                        ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.isRefinancedBefore == true &&
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.loanPurpose == "Refinance"
                        ? _c("div", { staticClass: "question-styling" }, [
                            _c("p", { staticStyle: { display: "inline" } }, [
                              _c(
                                "label",
                                {
                                  staticClass: "urla-radio-label",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    for: "hasTakenHomeEquityLineOfCredit",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Did you ever take a cash-out loan on this property?"
                                    ),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "fieldset",
                              {
                                staticClass: "urla-radio radio-inline",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c("ul", { staticClass: "type-of-house" }, [
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .hasTakenHomeEquityLineOfCredit,
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation\n                            .hasTakenHomeEquityLineOfCredit\n                        ",
                                        },
                                      ],
                                      attrs: {
                                        name: "hasTakenHomeEquityLineOfCredit",
                                        type: "radio",
                                        id: "hasTakenHomeEquityLineOfCredit-true",
                                        required: "",
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .hasTakenHomeEquityLineOfCredit,
                                          true
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation,
                                            "hasTakenHomeEquityLineOfCredit",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for: "hasTakenHomeEquityLineOfCredit-true",
                                        },
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .hasTakenHomeEquityLineOfCredit,
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation\n                            .hasTakenHomeEquityLineOfCredit\n                        ",
                                        },
                                      ],
                                      attrs: {
                                        name: "hasTakenHomeEquityLineOfCredit",
                                        type: "radio",
                                        id: "hasTakenHomeEquityLineOfCredit-false",
                                        required: "",
                                      },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .hasTakenHomeEquityLineOfCredit,
                                          false
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation,
                                            "hasTakenHomeEquityLineOfCredit",
                                            false
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for: "hasTakenHomeEquityLineOfCredit-false",
                                        },
                                      },
                                      [_vm._v("No")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.occupancy == "Primary Residence"
                        ? _c("div", { staticClass: "question-styling" }, [
                            _c("p", [
                              _c(
                                "label",
                                {
                                  staticClass: "urla-radio-label",
                                  staticStyle: { width: "100%" },
                                  attrs: { for: "occupancy" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Is your spouse available during closing, since this is a primary residence?"
                                    ),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("fieldset", { staticClass: "urla-radio" }, [
                              _c("ul", { staticStyle: { display: "flex" } }, [
                                _c("li", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.section4LoanAndPropertyInformation
                                            .part4eAdditionalLoanInformation
                                            .isSpouseAvailableDuringClosing,
                                        expression:
                                          "\n                          section4LoanAndPropertyInformation\n                            .part4eAdditionalLoanInformation\n                            .isSpouseAvailableDuringClosing\n                        ",
                                      },
                                    ],
                                    attrs: {
                                      name: "isSpouseAvailableDuringClosing",
                                      type: "radio",
                                      id: "isSpouseAvailableDuringClosing-true",
                                    },
                                    domProps: {
                                      value: true,
                                      checked: _vm._q(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4eAdditionalLoanInformation
                                          .isSpouseAvailableDuringClosing,
                                        true
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4eAdditionalLoanInformation,
                                          "isSpouseAvailableDuringClosing",
                                          true
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-weight-normal small-label",
                                      attrs: {
                                        for: "isSpouseAvailableDuringClosing-true",
                                      },
                                    },
                                    [_vm._v("Yes")]
                                  ),
                                ]),
                                _c("li", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.section4LoanAndPropertyInformation
                                            .part4eAdditionalLoanInformation
                                            .isSpouseAvailableDuringClosing,
                                        expression:
                                          "\n                          section4LoanAndPropertyInformation\n                            .part4eAdditionalLoanInformation\n                            .isSpouseAvailableDuringClosing\n                        ",
                                      },
                                    ],
                                    attrs: {
                                      name: "isSpouseAvailableDuringClosing",
                                      type: "radio",
                                      id: "isSpouseAvailableDuringClosing-false",
                                      required: "",
                                    },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4eAdditionalLoanInformation
                                          .isSpouseAvailableDuringClosing,
                                        false
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4eAdditionalLoanInformation,
                                          "isSpouseAvailableDuringClosing",
                                          false
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-weight-normal small-label",
                                      attrs: {
                                        for: "isSpouseAvailableDuringClosing-false",
                                      },
                                    },
                                    [_vm._v("No")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.section4LoanAndPropertyInformation
                        .part4aLoanInformation.occupancy === "Primary Residence"
                        ? _c("div", { staticClass: "question-styling" }, [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "line-height": "25px",
                                  "padding-bottom": "0",
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticStyle: { display: "inline" },
                                    attrs: { for: "mixed-use-property" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" Mixed-Use Property. "),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-normal" },
                                        [
                                          _vm._v(
                                            "If you will occupy the property, will you set aside space within the property to operate your own business?"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "fieldset",
                              { staticClass: "urla-radio radio-inline" },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .isMixedUseProperty,
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.isMixedUseProperty\n                        ",
                                        },
                                      ],
                                      attrs: {
                                        name: "mixed-use-property",
                                        type: "radio",
                                        id: "mixed-use-property-yes",
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .isMixedUseProperty,
                                          true
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation,
                                            "isMixedUseProperty",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for: "mixed-use-property-yes",
                                        },
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation
                                              .isMixedUseProperty,
                                          expression:
                                            "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.isMixedUseProperty\n                        ",
                                        },
                                      ],
                                      attrs: {
                                        name: "mixed-use-property",
                                        type: "radio",
                                        id: "mixed-use-property-no",
                                        required: "",
                                      },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .isMixedUseProperty,
                                          false
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm
                                              .section4LoanAndPropertyInformation
                                              .part4aLoanInformation,
                                            "isMixedUseProperty",
                                            false
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: { for: "mixed-use-property-no" },
                                      },
                                      [_vm._v("No")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      [
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.section4LoanAndPropertyInformation
                            .part4aLoanInformation.occupancy ===
                          "Investment Property"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.loanPurpose ===
                                      "Refinance"
                                        ? _c(
                                            "p",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "medium-label",
                                                  attrs: {
                                                    for: "purchased-on",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Purchased On:"),
                                                  ]),
                                                  _c("strong", [
                                                    _c(
                                                      "abbr",
                                                      {
                                                        staticClass: "required",
                                                        attrs: {
                                                          title: "required",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c("date-pick", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##/##/####",
                                                    expression: "'##/##/####'",
                                                  },
                                                ],
                                                staticClass:
                                                  "date-pick medium-input",
                                                attrs: {
                                                  id: "purchased-on",
                                                  inputAttributes: {
                                                    placeholder: "MM/DD/YYYY",
                                                    required: "required",
                                                  },
                                                  isDateDisabled:
                                                    _vm.isFutureDate,
                                                  displayFormat: "MM/DD/YYYY",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validatePurchasedOn(
                                                      $event
                                                    )
                                                  },
                                                  invalid: function ($event) {
                                                    _vm.validations.part4aLoanInformation.purchasedOn = 0
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .section4LoanAndPropertyInformation
                                                      .part4aLoanInformation
                                                      .purchasedOn,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm
                                                        .section4LoanAndPropertyInformation
                                                        .part4aLoanInformation,
                                                      "purchasedOn",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.purchasedOn\n                        ",
                                                },
                                              }),
                                              _c("validation-icon", {
                                                attrs: {
                                                  isValid:
                                                    _vm.validations
                                                      .part4aLoanInformation
                                                      .purchasedOn,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      _c(
                                        "p",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticStyle: { width: "275px" },
                                              attrs: {
                                                for: "expected-monthly-rental-income",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4aLoanInformation
                                                  .loanPurpose === "Purchase"
                                                  ? _c("span", [
                                                      _vm._v("Expected"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(
                                                  " Monthly Rental Income: "
                                                ),
                                              ]),
                                              _c("strong", [
                                                _c(
                                                  "abbr",
                                                  {
                                                    staticClass: "required",
                                                    attrs: {
                                                      title: "required",
                                                    },
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.lazy",
                                                value:
                                                  _vm
                                                    .section4LoanAndPropertyInformation
                                                    .part4cRentalIncomeOnSubjectProperty
                                                    .expectedMonthlyRentalIncome,
                                                expression:
                                                  "\n                          section4LoanAndPropertyInformation\n                            .part4cRentalIncomeOnSubjectProperty\n                            .expectedMonthlyRentalIncome\n                        ",
                                                modifiers: { lazy: true },
                                              },
                                              {
                                                name: "money",
                                                rawName: "v-money",
                                                value:
                                                  _vm
                                                    .section4LoanAndPropertyInformation
                                                    .part4cRentalIncomeOnSubjectProperty
                                                    .expectedMonthlyRentalIncome !==
                                                  null
                                                    ? _vm.currencyFormat
                                                    : null,
                                                expression:
                                                  "\n                          section4LoanAndPropertyInformation\n                            .part4cRentalIncomeOnSubjectProperty\n                            .expectedMonthlyRentalIncome !== null\n                            ? currencyFormat\n                            : null\n                        ",
                                              },
                                            ],
                                            staticClass: "small-input",
                                            attrs: {
                                              id: "expected-monthly-rental-income",
                                              type: "text",
                                              required: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm
                                                  .section4LoanAndPropertyInformation
                                                  .part4cRentalIncomeOnSubjectProperty
                                                  .expectedMonthlyRentalIncome,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.validateExpectedMonthlyRentalIncome(
                                                  $event
                                                )
                                              },
                                              invalid: function ($event) {
                                                _vm.validations.part4cRentalIncomeOnSubjectProperty.expectedMonthlyRentalIncome = 0
                                              },
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm
                                                    .section4LoanAndPropertyInformation
                                                    .part4cRentalIncomeOnSubjectProperty,
                                                  "expectedMonthlyRentalIncome",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("validation-icon", {
                                            attrs: {
                                              isValid:
                                                _vm.validations
                                                  .part4cRentalIncomeOnSubjectProperty
                                                  .expectedMonthlyRentalIncome,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.loanPurpose ===
                                      "Refinance"
                                        ? _c(
                                            "p",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticStyle: {
                                                    width: "275px",
                                                  },
                                                  attrs: {
                                                    for: "investment-property-since",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Investment Property since:"
                                                    ),
                                                  ]),
                                                  _c("strong", [
                                                    _c(
                                                      "abbr",
                                                      {
                                                        staticClass: "required",
                                                        attrs: {
                                                          title: "required",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c("date-pick", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##/##/####",
                                                    expression: "'##/##/####'",
                                                  },
                                                ],
                                                staticClass:
                                                  "date-pick small-input",
                                                attrs: {
                                                  id: "investment-property-since",
                                                  inputAttributes: {
                                                    placeholder: "MM/DD/YYYY",
                                                    required: "required",
                                                  },
                                                  isDateDisabled:
                                                    _vm.isFutureDate,
                                                  displayFormat: "MM/DD/YYYY",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validateInvestmentPropertySince(
                                                      $event
                                                    )
                                                  },
                                                  invalid: function ($event) {
                                                    _vm.validations.part4aLoanInformation.investmentPropertySince = 0
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .section4LoanAndPropertyInformation
                                                      .part4aLoanInformation
                                                      .investmentPropertySince,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm
                                                        .section4LoanAndPropertyInformation
                                                        .part4aLoanInformation,
                                                      "investmentPropertySince",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                          section4LoanAndPropertyInformation\n                            .part4aLoanInformation.investmentPropertySince\n                        ",
                                                },
                                              }),
                                              _c("validation-icon", {
                                                attrs: {
                                                  isValid:
                                                    _vm.validations
                                                      .part4aLoanInformation
                                                      .investmentPropertySince,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.section4LoanAndPropertyInformation.part4aLoanInformation
                  .loanPurpose !== null
                  ? _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("h5", { staticClass: "col-12 col-12-xsmall" }, [
                          _vm._v("Property Address"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-6 col-12-xsmall urla-left-section",
                          },
                          [
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "small-label",
                                    attrs: { for: "street" },
                                  },
                                  [
                                    _c("span", [_vm._v("Street:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation.propertyAddress
                                          .street,
                                      expression:
                                        "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.street\n                    ",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "street",
                                    type: "text",
                                    placeholder: "Street",
                                    required: "",
                                  },
                                  domProps: {
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .street,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .propertyAddress,
                                          "street",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateStreet($event)
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part4aLoanInformation.propertyAddress.street = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part4aLoanInformation
                                        .propertyAddress.street,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: { for: "unit" },
                                  },
                                  [_vm._v("Unit:")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation.propertyAddress
                                          .unit,
                                      expression:
                                        "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.unit\n                    ",
                                    },
                                  ],
                                  staticClass: "small-input",
                                  attrs: {
                                    id: "unit",
                                    type: "text",
                                    placeholder: "unit",
                                  },
                                  domProps: {
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .unit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.validateUnit($event)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation
                                          .propertyAddress,
                                        "unit",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part4aLoanInformation
                                        .propertyAddress.unit,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: { for: "zip" },
                                  },
                                  [
                                    _c("span", [_vm._v("Zip:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation.propertyAddress
                                          .zip,
                                      expression:
                                        "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.zip\n                    ",
                                    },
                                  ],
                                  staticClass: "small-input",
                                  attrs: {
                                    id: "zip",
                                    type: "text",
                                    minlength: "5",
                                    maxlength: "5",
                                    placeholder: "00000",
                                    required: "",
                                  },
                                  domProps: {
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .zip,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .propertyAddress,
                                          "zip",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateZip($event)
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part4aLoanInformation.propertyAddress.zip = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part4aLoanInformation
                                        .propertyAddress.zip,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: { for: "number-of-units" },
                                  },
                                  [
                                    _c("span", [_vm._v("Number of Units:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value:
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation.propertyAddress
                                          .numberOfUnits,
                                      expression:
                                        "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.numberOfUnits\n                    ",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "small-input",
                                  attrs: {
                                    id: "number-of-units",
                                    type: "number",
                                    min: "1",
                                    max: "3",
                                    required: "",
                                  },
                                  domProps: {
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .numberOfUnits,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.section4LoanAndPropertyInformation
                                            .part4aLoanInformation
                                            .propertyAddress,
                                          "numberOfUnits",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateNumberOfUnits($event)
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part4aLoanInformation.propertyAddress.numberOfUnits = 0
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part4aLoanInformation
                                        .propertyAddress.numberOfUnits,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "col-6 col-12-xsmall" }, [
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "city" },
                                },
                                [
                                  _c("span", [_vm._v("City:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .city,
                                    expression:
                                      "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.city\n                    ",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "city",
                                  type: "text",
                                  placeholder: "City",
                                  required: "",
                                },
                                domProps: {
                                  value:
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.propertyAddress
                                      .city,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation
                                          .propertyAddress,
                                        "city",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateCity($event)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part4aLoanInformation.propertyAddress.city = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part4aLoanInformation
                                      .propertyAddress.city,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "state" },
                                },
                                [
                                  _c("span", [_vm._v("State:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .state,
                                    expression:
                                      "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.state\n                    ",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "state",
                                  type: "text",
                                  placeholder: "State",
                                  required: "",
                                },
                                domProps: {
                                  value:
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.propertyAddress
                                      .state,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.section4LoanAndPropertyInformation
                                          .part4aLoanInformation
                                          .propertyAddress,
                                        "state",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateState($event)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part4aLoanInformation.propertyAddress.state = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part4aLoanInformation
                                      .propertyAddress.state,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "county" },
                                },
                                [_vm._v("County:")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress
                                        .county,
                                    expression:
                                      "\n                      section4LoanAndPropertyInformation.part4aLoanInformation\n                        .propertyAddress.county\n                    ",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "county",
                                  type: "text",
                                  placeholder: "County",
                                },
                                domProps: {
                                  value:
                                    _vm.section4LoanAndPropertyInformation
                                      .part4aLoanInformation.propertyAddress
                                      .county,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateCounty($event)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.section4LoanAndPropertyInformation
                                        .part4aLoanInformation.propertyAddress,
                                      "county",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part4aLoanInformation
                                      .propertyAddress.county,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", {
                          staticClass: "col-6 col-12-xsmall urla-left-section",
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _c("transition", { attrs: { name: "fade" } }, [
          this.section4LoanAndPropertyInformation
            .part4bOtherLoansOnSubjectProperty.length !== 0
            ? _c("div", [
                _c("hr", { staticClass: "urla-break" }),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-6 col-12-xsmall" }, [
                      _c("h4", [
                        _vm._v(
                          "Mortgage Loans on the property you are Refinancing"
                        ),
                      ]),
                    ]),
                    _c(
                      "transition-group",
                      { attrs: { name: "list", tag: "div" } },
                      _vm._l(
                        _vm.section4LoanAndPropertyInformation
                          .part4bOtherLoansOnSubjectProperty,
                        function (loan, index) {
                          return _c(
                            "div",
                            {
                              key: index + 1,
                              staticClass: "list-item-transition",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item-action-wrapper row" },
                                [
                                  _c(
                                    "h5",
                                    { staticClass: "col-4 col-12-xsmall" },
                                    [
                                      _vm._v(
                                        " Mortgage Loan " +
                                          _vm._s(index + 1) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-8 col-12-xsmall" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "remove-button",
                                          staticStyle: { float: "none" },
                                          attrs: {
                                            title: "Remove Loan on Property",
                                            id: index + 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeLoanOnProperty(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-lg fa-times",
                                          }),
                                          _vm._v(" Delete Loan "),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row col-8 col-12-xsmall" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "col-6 col-12-xsmall" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "medium-label",
                                              attrs: {
                                                for: "creditor-name-" + index,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Creditor Name:"),
                                              ]),
                                              _c("strong", [
                                                _c(
                                                  "abbr",
                                                  {
                                                    staticClass: "required",
                                                    attrs: {
                                                      title: "required",
                                                    },
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: loan.creditorName,
                                                expression: "loan.creditorName",
                                              },
                                            ],
                                            staticClass: "small-input",
                                            attrs: {
                                              id: "creditor-name-" + index,
                                              type: "text",
                                              placeholder: "Creditor",
                                              required: "",
                                            },
                                            domProps: {
                                              value: loan.creditorName,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    loan,
                                                    "creditorName",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.validateCreditorName(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              ],
                                              invalid: function ($event) {
                                                _vm.validations.part4bOtherLoansOnSubjectProperty[
                                                  index
                                                ].creditorName = 0
                                              },
                                            },
                                          }),
                                          _c("validation-icon", {
                                            attrs: {
                                              isValid:
                                                _vm.validations
                                                  .part4bOtherLoansOnSubjectProperty[
                                                  index
                                                ].creditorName,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "col-6 col-12-xsmall urla-middle-section",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "medium-label",
                                              attrs: {
                                                for: "monthly-payment" + index,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Monthly Payment:"),
                                              ]),
                                              _c("strong", [
                                                _c(
                                                  "abbr",
                                                  {
                                                    staticClass: "required",
                                                    attrs: {
                                                      title: "required",
                                                    },
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.lazy",
                                                value: loan.monthlyPayment,
                                                expression:
                                                  "loan.monthlyPayment",
                                                modifiers: { lazy: true },
                                              },
                                              {
                                                name: "money",
                                                rawName: "v-money",
                                                value:
                                                  loan.monthlyPayment !== null
                                                    ? _vm.currencyFormat
                                                    : null,
                                                expression:
                                                  "\n                        loan.monthlyPayment !== null ? currencyFormat : null\n                      ",
                                              },
                                            ],
                                            staticClass: "small-input",
                                            attrs: {
                                              id: "monthly-payment-" + index,
                                              type: "text",
                                              required: "",
                                            },
                                            domProps: {
                                              value: loan.monthlyPayment,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.validateMonthlyPayment(
                                                  $event,
                                                  index
                                                )
                                              },
                                              invalid: function ($event) {
                                                _vm.validations.part4bOtherLoansOnSubjectProperty[
                                                  index
                                                ].monthlyPayment = 0
                                              },
                                              change: function ($event) {
                                                return _vm.$set(
                                                  loan,
                                                  "monthlyPayment",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("validation-icon", {
                                            attrs: {
                                              isValid:
                                                _vm.validations
                                                  .part4bOtherLoansOnSubjectProperty[
                                                  index
                                                ].monthlyPayment,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "col-6 col-12-xsmall" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "medium-label",
                                              attrs: {
                                                for: "loan-amount-" + index,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Loan Amount:"),
                                              ]),
                                              _c("strong", [
                                                _c(
                                                  "abbr",
                                                  {
                                                    staticClass: "required",
                                                    attrs: {
                                                      title: "required",
                                                    },
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.lazy",
                                                value: loan.loanAmount,
                                                expression: "loan.loanAmount",
                                                modifiers: { lazy: true },
                                              },
                                              {
                                                name: "money",
                                                rawName: "v-money",
                                                value:
                                                  loan.loanAmount !== null
                                                    ? _vm.currencyFormat
                                                    : null,
                                                expression:
                                                  "\n                        loan.loanAmount !== null ? currencyFormat : null\n                      ",
                                              },
                                            ],
                                            staticClass: "small-input",
                                            attrs: {
                                              id: "loan-amount-" + index,
                                              type: "text",
                                              required: "",
                                            },
                                            domProps: {
                                              value: loan.loanAmount,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.validateOtherLoanAmount(
                                                  $event,
                                                  index
                                                )
                                              },
                                              invalid: function ($event) {
                                                _vm.validations.part4bOtherLoansOnSubjectProperty[
                                                  index
                                                ].loanAmount = 0
                                              },
                                              change: function ($event) {
                                                return _vm.$set(
                                                  loan,
                                                  "loanAmount",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("validation-icon", {
                                            attrs: {
                                              isValid:
                                                _vm.validations
                                                  .part4bOtherLoansOnSubjectProperty[
                                                  index
                                                ].loanAmount,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "col-6 col-12-xsmall urla-middle-section",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "medium-label",
                                              attrs: { for: "credit-limit-" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Credit Limit:"),
                                              ]),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.lazy",
                                                value: loan.creditLimit,
                                                expression: "loan.creditLimit",
                                                modifiers: { lazy: true },
                                              },
                                              {
                                                name: "money",
                                                rawName: "v-money",
                                                value:
                                                  loan.creditLimit !== null
                                                    ? _vm.currencyFormat
                                                    : null,
                                                expression:
                                                  "\n                        loan.creditLimit !== null ? currencyFormat : null\n                      ",
                                              },
                                            ],
                                            staticClass: "small-input",
                                            attrs: {
                                              id: "credit-limit-" + index,
                                              type: "text",
                                            },
                                            domProps: {
                                              value: loan.creditLimit,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  loan,
                                                  "creditLimit",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-4 col-12-xsmall" },
                                    [
                                      _c("p", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "urla-radio-label small-label",
                                            attrs: {
                                              for: "lien-type-" + index,
                                            },
                                          },
                                          [
                                            _c("span", [_vm._v("Lien Type:")]),
                                            _c("strong", [
                                              _c(
                                                "abbr",
                                                {
                                                  staticClass: "required",
                                                  attrs: { title: "required" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "fieldset",
                                        {
                                          staticClass:
                                            "urla-radio radio-inline",
                                          staticStyle: { display: "inline" },
                                        },
                                        [
                                          _c("ul", [
                                            _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: loan.lienType,
                                                    expression: "loan.lienType",
                                                  },
                                                ],
                                                attrs: {
                                                  name: "lien-type" + index,
                                                  type: "radio",
                                                  id: "first-lien-" + index,
                                                  value: "First Lien",
                                                  required: "",
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    loan.lienType,
                                                    "First Lien"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      loan,
                                                      "lienType",
                                                      "First Lien"
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "font-weight-normal small-label",
                                                  attrs: {
                                                    for: "first-lien-" + index,
                                                  },
                                                },
                                                [_vm._v("First Lien")]
                                              ),
                                            ]),
                                            _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: loan.lienType,
                                                    expression: "loan.lienType",
                                                  },
                                                ],
                                                attrs: {
                                                  name: "lien-type" + index,
                                                  type: "radio",
                                                  id:
                                                    "subordinate-lien-" + index,
                                                  value: "Subordinate Lien",
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    loan.lienType,
                                                    "Subordinate Lien"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      loan,
                                                      "lienType",
                                                      "Subordinate Lien"
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "font-weight-normal medium-label",
                                                  attrs: {
                                                    for:
                                                      "subordinate-lien-" +
                                                      index,
                                                  },
                                                },
                                                [_vm._v("Subordinate Lien")]
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "col-6 col-12-xsmall" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "cash-out" } },
                                            [_vm._v("Is Cash-Out loan?")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "fieldset",
                                        {
                                          staticClass:
                                            "urla-radio radio-inline",
                                          staticStyle: { display: "inline" },
                                        },
                                        [
                                          _c("ul", [
                                            _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: loan.isCashOutLoan,
                                                    expression:
                                                      "loan.isCashOutLoan",
                                                  },
                                                ],
                                                attrs: {
                                                  name: "cash-out" + index,
                                                  type: "radio",
                                                  id: "cash-out-yes" + index,
                                                  required: "",
                                                },
                                                domProps: {
                                                  value: true,
                                                  checked: _vm._q(
                                                    loan.isCashOutLoan,
                                                    true
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      loan,
                                                      "isCashOutLoan",
                                                      true
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "font-weight-normal small-label",
                                                  attrs: {
                                                    for: "cash-out-yes" + index,
                                                  },
                                                },
                                                [_vm._v("Yes")]
                                              ),
                                            ]),
                                            _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: loan.isCashOutLoan,
                                                    expression:
                                                      "loan.isCashOutLoan",
                                                  },
                                                ],
                                                attrs: {
                                                  name: "cash-out" + index,
                                                  type: "radio",
                                                  id: "cash-out-no" + index,
                                                  required: "",
                                                },
                                                domProps: {
                                                  value: false,
                                                  checked: _vm._q(
                                                    loan.isCashOutLoan,
                                                    false
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$set(
                                                      loan,
                                                      "isCashOutLoan",
                                                      false
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "font-weight-normal small-label",
                                                  attrs: {
                                                    for: "cash-out-no" + index,
                                                  },
                                                },
                                                [_vm._v("No")]
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _c("div", { staticClass: "col-12 col-12-xsmall" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button add-button",
                          staticStyle: { "font-size": "13px" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addLoanOnProperty()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(" Loan "),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _c("br"),
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "button",
            {
              staticClass: "primary wizard__back pull-left",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goBack()
                },
              },
            },
            [_vm._m(12)]
          ),
          _vm._m(13),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "medium-label", attrs: { for: "property-value-" } },
      [
        _c("span", [_vm._v("Sale price/Appraisal value:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "medium-input", attrs: { for: "loan-amount" } },
      [
        _c("span", [_vm._v("Loan Amount:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "block" } }, [
      _c(
        "label",
        {
          staticClass: "urla-radio-label medium-label",
          attrs: { for: "loan-purpose" },
        },
        [
          _c("span", [_vm._v("Loan Purpose?")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "label",
        {
          staticClass: "urla-radio-label",
          attrs: { for: "preferred-loan-program" },
        },
        [
          _c("span", [_vm._v("Preferred loan program:")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "label",
        { staticClass: "urla-radio-label", attrs: { for: "occupancy" } },
        [
          _c("span", [_vm._v("Occupancy:")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "block" } }, [
      _c(
        "label",
        {
          staticClass: "urla-radio-label medium-label",
          attrs: { for: "type-of-house" },
        },
        [
          _c("span", [_vm._v("Type of house:")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticStyle: { "line-height": "25px", "padding-bottom": "0" } },
      [
        _c(
          "label",
          {
            staticStyle: { width: "auto" },
            attrs: { for: "manufactured-home" },
          },
          [
            _c("span", [
              _c("span", { staticClass: "font-weight-normal" }, [
                _vm._v("Is the property a"),
              ]),
              _vm._v(" Manufactured Home? "),
            ]),
            _c("strong", [
              _c(
                "abbr",
                { staticClass: "required", attrs: { title: "required" } },
                [_vm._v("*")]
              ),
            ]),
            _c("br"),
            _c(
              "span",
              { staticClass: "font-weight-normal font-style-italic" },
              [
                _vm._v(
                  "(e.g., a factory built dwelling built on a permanent chasis)"
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "inline" } }, [
      _c(
        "label",
        {
          staticClass: "urla-radio-label",
          staticStyle: { width: "100%" },
          attrs: { for: "isPropertyHeldInATrust" },
        },
        [
          _c("span", [_vm._v("Is this property held in a trust?")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "inline" } }, [
      _c(
        "label",
        {
          staticClass: "urla-radio-label",
          staticStyle: { width: "100%" },
          attrs: { for: "hasSolarPanels" },
        },
        [
          _c("span", [_vm._v("Are there solar panels on the house?")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "inline" } }, [
      _c(
        "label",
        {
          staticClass: "urla-radio-label",
          staticStyle: { width: "100%" },
          attrs: { for: "isCondoWithoutSharedWalls" },
        },
        [
          _c("span", [
            _vm._v(
              "Is this property organized as a condo, even though it does not share walls?"
            ),
          ]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { display: "inline" } }, [
      _c(
        "label",
        {
          staticClass: "urla-radio-label",
          staticStyle: { width: "100%" },
          attrs: { for: "isTravellingDuringClosing" },
        },
        [
          _c("span", [
            _vm._v(
              "Will you be traveling outside of the city, where the subject property is located, during closing ?"
            ),
          ]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-arrow-left fa-lg" }),
      _vm._v(" back "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "primary wizard__next pull-right",
        attrs: { type: "submit" },
      },
      [
        _c("span", [
          _c("i", {
            staticClass: "fa fa-floppy-o",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" save "),
          _c("i", {
            staticClass: "fa fa-arrow-right fa-lg",
            attrs: { "aria-hidden": "true" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }