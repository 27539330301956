var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "main" }, [
    _c("section", [
      _c("div", { staticClass: "error-message" }, [
        _c("h1", [_vm._v("500")]),
        _c("h2", [_vm._v("Unexpected Error :(")]),
        _c("p", [
          _vm._v(
            "An error occurred and your request couldn't be completed. Please try again or come back later."
          ),
        ]),
        _vm._m(0),
        _c(
          "p",
          [
            _c("router-link", { attrs: { to: "home" } }, [
              _vm._v("Return to home page"),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Please do not hesitate to reach us at "),
      _c("a", { attrs: { href: "tel:469-525-6725" } }, [
        _vm._v("+1(469)525-6725"),
      ]),
      _vm._v(" or "),
      _c("a", { attrs: { href: "mailto:info@AmericanSmartLending.com" } }, [
        _vm._v("info@AmericanSmartLending.com."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }