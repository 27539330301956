var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c("section", [
            _c("h1", [_vm._v("Steps in the mortgage process")]),
            _c("p", [
              _vm._v(
                "Here are the steps in the typical home buying and mortgage process"
              ),
            ]),
            _c("p", [
              _vm._v(
                "1) First step in buying a home and getting a loan is to get a pre-approval letter. That is typically submitted to the seller along with purchase contract. "
              ),
            ]),
            _c("p", [
              _vm._v(
                "2) When pre-approving you, the lender arrives at a maximum loan amount they are willing to lend you, which you can then use as a safe indicator during the home search. "
              ),
            ]),
            _c("p", [
              _vm._v(
                "3) Find your home with the help of a licensed realtor and submit an offer to the seller along with the pre-approval letter. Your pre-qualification gives sellers confidence in your ability to follow through on an offer. In a multiple offer situation, buyers with a pre-approval might have a higher chance of getting picked by the seller."
              ),
            ]),
            _c("p", [
              _vm._v(
                "4) once your offer is accepted, send all the required documents to us by uploading in the portal or emailing to us. "
              ),
            ]),
            _c("p", [
              _vm._v(
                "5) Please keep in touch with your loan officer often during this process, and respond promptly to their requests. The faster you can provide the needed documentation, the quicker your loan will be approved."
              ),
            ]),
            _c("p", [
              _vm._v(
                "6) You as the borrower will shop for hazard/home owners insurance for your property and give the details to the loan officer. "
              ),
            ]),
            _c("p", [
              _vm._v(
                "7) The loan officer will get the title/escrow officer contact either from the listing agent or from the builder sales executive and order the title package for the underwriter"
              ),
            ]),
            _c("p", [
              _vm._v(
                "8) Once we have the final approval we will work with the lenders closer and the title agent to arrive at the initial version of the closing disclosure"
              ),
            ]),
            _c("p", [
              _vm._v(
                "9) Legally there is a three business day mandatory wait time for the final closing once the initial closing disclosure is acknowledged by you. Even though the numbers are NOT final and perfect we want you to sign and acknowledge the receipt of the initial CD. we will work for the final numbers in due course and get that final CD promptly."
              ),
            ]),
            _c("p", [
              _vm._v(
                "10) On the day of closing you sign the documents and provide the final cash to close in the form of a cashiers check in favor of title company or wire the funds to the title company"
              ),
            ]),
          ]),
          _c("section", { staticClass: "spotlights alt" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }