var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c("section", [
            _c("h1", [_vm._v("Popular Loan Programs")]),
            _c("p"),
            _c("ul", [
              _c("li", [_vm._v("30 year fixed")]),
              _c("li", [_vm._v("15 year fixed")]),
              _c("li", [_vm._v("7 year ARM")]),
              _c("li", [_vm._v("5 year ARM")]),
              _c("li", [
                _vm._v(
                  "nationwide high balance program with higher loan amount than a conventional loan and lower loan amount compared to a Jumbo loan. The qualification criteria in these kinds of loans are typically less stringent"
                ),
              ]),
              _c("li", [_vm._v("Jumbo loans")]),
            ]),
          ]),
          _c("section", { staticClass: "spotlights alt" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }