var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "sectionOne", staticClass: "urla-section" }, [
    _c("h2", [_vm._v("Borrower Information")]),
    _c("p", { staticClass: "urla-section-description" }, [
      _vm._v(
        " This section asks about your personal information and your income from employment and other sources, such as retirement, that you want considered to qualify for this loan. "
      ),
    ]),
    _vm._m(0),
    _c(
      "form",
      {
        attrs: { id: "section-1-form", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitSectionOne.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c(
              "p",
              [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.firstName,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation.firstName\n            ",
                    },
                  ],
                  staticClass: "medium-input",
                  attrs: {
                    id: "first-name",
                    type: "text",
                    placeholder: "First Name",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .firstName,
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "firstName",
                          $event.target.value
                        )
                      },
                      _vm.validateFirstName,
                    ],
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.firstName = 0
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation.firstName,
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _c(
                  "label",
                  { staticClass: "small-label", attrs: { for: "middle-name" } },
                  [_vm._v("Middle Name:")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.middleName,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation.middleName\n            ",
                    },
                  ],
                  staticClass: "medium-input",
                  attrs: {
                    id: "middle-name",
                    type: "text",
                    placeholder: "Middle Name",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .middleName,
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "middleName",
                          $event.target.value
                        )
                      },
                      _vm.validateMiddleName,
                    ],
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation.middleName,
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm._m(2),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.lastName,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation.lastName\n            ",
                    },
                  ],
                  staticClass: "medium-input",
                  attrs: {
                    id: "last-name",
                    type: "text",
                    placeholder: "Last Name",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .lastName,
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "lastName",
                          $event.target.value
                        )
                      },
                      _vm.validateLastName,
                    ],
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.lastName = 0
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid: _vm.validations.part1aPersonalInformation.lastName,
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _c(
                  "label",
                  { staticClass: "small-label", attrs: { for: "suffix" } },
                  [_vm._v("Suffix:")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.suffix,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation.suffix\n            ",
                    },
                  ],
                  staticClass: "medium-input",
                  attrs: { id: "suffix", type: "text", placeholder: "Suffix" },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .suffix,
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "suffix",
                          $event.target.value
                        )
                      },
                      _vm.validateSuffix,
                    ],
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid: _vm.validations.part1aPersonalInformation.suffix,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c(
              "p",
              [
                _vm._m(3),
                _c("input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "+1(###)###-####",
                      expression: "'+1(###)###-####'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.cellPhone,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation.cellPhone\n            ",
                    },
                  ],
                  staticClass: "medium-input",
                  attrs: {
                    id: "cell-phone",
                    type: "tel",
                    maxlength: "15",
                    minlength: "15",
                    placeholder: "+1(123)456-7890",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .cellPhone,
                  },
                  on: {
                    change: _vm.validateCellPhone,
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.cellPhone = 0
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation,
                        "cellPhone",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation.cellPhone,
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm._m(4),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.emailAddress,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation\n                .emailAddress\n            ",
                    },
                  ],
                  staticClass: "medium-input",
                  attrs: {
                    id: "email-address",
                    type: "email",
                    placeholder: "dan@gmail.com",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .emailAddress,
                  },
                  on: {
                    change: _vm.validateEmailAddress,
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.emailAddress = 0
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation,
                        "emailAddress",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation.emailAddress,
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm._m(5),
                _c("input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "###-##-####",
                      expression: "'###-##-####'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.socialSecurityNumber,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation\n                .socialSecurityNumber\n            ",
                    },
                  ],
                  staticClass: "small-input",
                  attrs: {
                    id: "social-security-number",
                    type: "text",
                    maxlength: "11",
                    minlength: "11",
                    placeholder: "xxx-xx-xxxx",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .socialSecurityNumber,
                  },
                  on: {
                    change: _vm.validateSsn,
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.socialSecurityNumber = 0
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation,
                        "socialSecurityNumber",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation
                        .socialSecurityNumber,
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm._m(6),
                _c("date-pick", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "##/##/####",
                      expression: "'##/##/####'",
                    },
                  ],
                  staticClass: "date-pick medium-input",
                  attrs: {
                    id: "date-of-birth",
                    inputAttributes: {
                      placeholder: "MM/DD/YYYY",
                      required: "required",
                    },
                    isDateDisabled: _vm.isFutureDate,
                    displayFormat: "MM/DD/YYYY",
                  },
                  on: {
                    input: _vm.validateDateOfBirth,
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.dateOfBirth = 0
                    },
                  },
                  model: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .dateOfBirth,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation,
                        "dateOfBirth",
                        $$v
                      )
                    },
                    expression:
                      "\n              section1BorrowerInformation.part1aPersonalInformation\n                .dateOfBirth\n            ",
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation.dateOfBirth,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-4 col-12-xsmall" }, [
            _c("div", [
              _vm._m(7),
              _c("fieldset", { staticClass: "urla-radio" }, [
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation.maritalStatus,
                          expression:
                            "\n                    section1BorrowerInformation.part1aPersonalInformation\n                      .maritalStatus\n                  ",
                        },
                      ],
                      attrs: {
                        name: "marital-status",
                        type: "radio",
                        id: "married",
                        value: "Married",
                        required: "",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.maritalStatus,
                          "Married"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation,
                            "maritalStatus",
                            "Married"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "long font-weight-normal",
                        attrs: { for: "married" },
                      },
                      [_vm._v("Married")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation.maritalStatus,
                          expression:
                            "\n                    section1BorrowerInformation.part1aPersonalInformation\n                      .maritalStatus\n                  ",
                        },
                      ],
                      attrs: {
                        name: "marital-status",
                        type: "radio",
                        id: "separated",
                        value: "Separated",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.maritalStatus,
                          "Separated"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation,
                            "maritalStatus",
                            "Separated"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "long font-weight-normal",
                        attrs: { for: "separated" },
                      },
                      [_vm._v("Separated")]
                    ),
                  ]),
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation.maritalStatus,
                          expression:
                            "\n                    section1BorrowerInformation.part1aPersonalInformation\n                      .maritalStatus\n                  ",
                        },
                      ],
                      attrs: {
                        name: "marital-status",
                        type: "radio",
                        id: "unmarried",
                        value: "Unmarried",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.maritalStatus,
                          "Unmarried"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation,
                            "maritalStatus",
                            "Unmarried"
                          )
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "long font-weight-normal",
                        attrs: { for: "unmarried" },
                      },
                      [_vm._v("Unmarried")]
                    ),
                  ]),
                ]),
                _c("p", { staticStyle: { "padding-left": "1em" } }, [
                  _vm._v(
                    " (Single, Divorced, Widowed, Civil Union, Domestic Partnership, Registered Reciprocal Beneficiary Relationship) "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4 col-12-xsmall urla-left-section" }, [
            _vm._m(8),
            _c("fieldset", { staticClass: "urla-radio" }, [
              _c("ul", [
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.citizenship,
                        expression:
                          "\n                  section1BorrowerInformation.part1aPersonalInformation\n                    .citizenship\n                ",
                      },
                    ],
                    attrs: {
                      name: "citizenship",
                      type: "radio",
                      id: "us-citizen",
                      value: "U.S. Citizen",
                      required: "",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.citizenship,
                        "U.S. Citizen"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "citizenship",
                          "U.S. Citizen"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "long font-weight-normal",
                      attrs: { for: "us-citizen" },
                    },
                    [_vm._v("U.S. Citizen")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.citizenship,
                        expression:
                          "\n                  section1BorrowerInformation.part1aPersonalInformation\n                    .citizenship\n                ",
                      },
                    ],
                    attrs: {
                      name: "citizenship",
                      type: "radio",
                      id: "permanent-resident-alien",
                      value: "Permanent Resident Alien",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.citizenship,
                        "Permanent Resident Alien"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "citizenship",
                          "Permanent Resident Alien"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "long font-weight-normal",
                      attrs: { for: "permanent-resident-alien" },
                    },
                    [_vm._v("Permanent Resident Alien")]
                  ),
                ]),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.citizenship,
                        expression:
                          "\n                  section1BorrowerInformation.part1aPersonalInformation\n                    .citizenship\n                ",
                      },
                    ],
                    attrs: {
                      name: "citizenship",
                      type: "radio",
                      id: "non-permanent-resident-alien",
                      value: "Non-Permanent Resident Alien",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.citizenship,
                        "Non-Permanent Resident Alien"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "citizenship",
                          "Non-Permanent Resident Alien"
                        )
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "long font-weight-normal",
                      attrs: { for: "non-permanent-resident-alien" },
                    },
                    [_vm._v("Non-Permanent Resident Alien")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.section1BorrowerInformation.part1aPersonalInformation
            .maritalStatus == "Married" ||
          _vm.section1BorrowerInformation.part1aPersonalInformation
            .maritalStatus == "Separated"
            ? _c("div", { staticClass: "col-4 col-12-xsmall" }, [
                _c("h6", { staticClass: "spouse-information" }, [
                  _vm._v("Spouse Information:"),
                ]),
                _c(
                  "p",
                  [
                    _vm._m(9),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation.spouse.firstName,
                          expression:
                            "\n              section1BorrowerInformation.part1aPersonalInformation.spouse\n                .firstName\n            ",
                        },
                      ],
                      staticClass: "medium-input",
                      attrs: {
                        id: "spouse-first-name",
                        type: "text",
                        placeholder: "Spouse First Name",
                        required: "",
                      },
                      domProps: {
                        value:
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.spouse.firstName,
                      },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.section1BorrowerInformation
                                .part1aPersonalInformation.spouse,
                              "firstName",
                              $event.target.value
                            )
                          },
                          _vm.validateSpouseFirstName,
                        ],
                        invalid: function ($event) {
                          _vm.validations.part1aPersonalInformation.spouse.firstName = 0
                        },
                      },
                    }),
                    _c("validation-icon", {
                      attrs: {
                        isValid:
                          _vm.validations.part1aPersonalInformation.spouse
                            .firstName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _vm._m(10),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation.spouse.lastName,
                          expression:
                            "\n              section1BorrowerInformation.part1aPersonalInformation.spouse\n                .lastName\n            ",
                        },
                      ],
                      staticClass: "medium-input",
                      attrs: {
                        id: "spouse-last-name",
                        type: "text",
                        placeholder: "Spouse Last Name",
                        required: "",
                      },
                      domProps: {
                        value:
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.spouse.lastName,
                      },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.section1BorrowerInformation
                                .part1aPersonalInformation.spouse,
                              "lastName",
                              $event.target.value
                            )
                          },
                          _vm.validateSpouseLastName,
                        ],
                        invalid: function ($event) {
                          _vm.validations.part1aPersonalInformation.spouse.lastName = 0
                        },
                      },
                    }),
                    _c("validation-icon", {
                      attrs: {
                        isValid:
                          _vm.validations.part1aPersonalInformation.spouse
                            .lastName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _vm._m(11),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.section1BorrowerInformation
                              .part1aPersonalInformation.spouse.emailAddress,
                          expression:
                            "\n              section1BorrowerInformation.part1aPersonalInformation.spouse\n                .emailAddress\n            ",
                        },
                      ],
                      staticClass: "medium-input",
                      attrs: {
                        id: "spouse-email",
                        type: "email",
                        placeholder: "Spouse Email",
                        required: "",
                      },
                      domProps: {
                        value:
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation.spouse.emailAddress,
                      },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.section1BorrowerInformation
                                .part1aPersonalInformation.spouse,
                              "emailAddress",
                              $event.target.value
                            )
                          },
                          _vm.validateSpouseEmailAddress,
                        ],
                        invalid: function ($event) {
                          _vm.validations.part1aPersonalInformation.spouse.emailAddress = 0
                        },
                      },
                    }),
                    _c("validation-icon", {
                      attrs: {
                        isValid:
                          _vm.validations.part1aPersonalInformation.spouse
                            .emailAddress,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "col-4 col-12-xsmall urla-right-section" }, [
            _c(
              "p",
              [
                _vm._m(12),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.numberOfDependents,
                      expression:
                        "\n              section1BorrowerInformation.part1aPersonalInformation\n                .numberOfDependents\n            ",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "x-small-input",
                  attrs: {
                    id: "number-of-dependents",
                    type: "number",
                    min: "0",
                    max: "3",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .numberOfDependents,
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "numberOfDependents",
                          _vm._n($event.target.value)
                        )
                      },
                      _vm.validateNumberOfDependents,
                    ],
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.numberOfDependents = 0
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation
                        .numberOfDependents,
                  },
                }),
                _c("br"),
                _c("span", { staticClass: "font-style-italic" }, [
                  _vm._v("(not listed by another Borrower)"),
                ]),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm.section1BorrowerInformation.part1aPersonalInformation
                  .agesOfDependents.length !== 0
                  ? _c("label", { attrs: { for: "ages-of-dependents" } }, [
                      _c("span", [_vm._v("Ages of Dependents:")]),
                      _vm._m(13),
                    ])
                  : _vm._e(),
                _c(
                  "transition-group",
                  {
                    staticStyle: { display: "inline" },
                    attrs: { name: "list", tag: "div" },
                  },
                  _vm._l(
                    _vm.section1BorrowerInformation.part1aPersonalInformation
                      .agesOfDependents,
                    function (age, index) {
                      return _c(
                        "div",
                        {
                          key: index + 1,
                          staticClass: "list-item-transition inline-list",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value:
                                  _vm.section1BorrowerInformation
                                    .part1aPersonalInformation.agesOfDependents[
                                    index
                                  ],
                                expression:
                                  "\n                  section1BorrowerInformation.part1aPersonalInformation\n                    .agesOfDependents[index]\n                ",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "x-small-input",
                            staticStyle: { "margin-right": "2%" },
                            attrs: {
                              id: "ages-of-dependents-" + index,
                              type: "number",
                              required: "",
                            },
                            domProps: {
                              value:
                                _vm.section1BorrowerInformation
                                  .part1aPersonalInformation.agesOfDependents[
                                  index
                                ],
                            },
                            on: {
                              change: _vm.validateAgesOfDependents,
                              invalid: function ($event) {
                                _vm.validations.part1aPersonalInformation.agesOfDependents = 0
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.section1BorrowerInformation
                                    .part1aPersonalInformation.agesOfDependents,
                                  index,
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _vm.section1BorrowerInformation.part1aPersonalInformation
                  .agesOfDependents.length !== 0
                  ? _c("validation-icon", {
                      attrs: {
                        isValid:
                          _vm.validations.part1aPersonalInformation
                            .agesOfDependents,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "p",
              { staticStyle: { "padding-bottom": "0" } },
              [
                _vm._m(14),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value:
                        _vm.section1BorrowerInformation
                          .part1aPersonalInformation.numberOfBorrowers,
                      expression:
                        "\n            section1BorrowerInformation.part1aPersonalInformation\n              .numberOfBorrowers\n          ",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "x-small-input",
                  attrs: {
                    id: "number-of-borrowers",
                    type: "number",
                    min: "1",
                    max: "3",
                    required: "",
                  },
                  domProps: {
                    value:
                      _vm.section1BorrowerInformation.part1aPersonalInformation
                        .numberOfBorrowers,
                  },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.section1BorrowerInformation
                            .part1aPersonalInformation,
                          "numberOfBorrowers",
                          _vm._n($event.target.value)
                        )
                      },
                      _vm.validateNumberOfBorrowers,
                    ],
                    invalid: function ($event) {
                      _vm.validations.part1aPersonalInformation.numberOfBorrowers = 0
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("validation-icon", {
                  attrs: {
                    isValid:
                      _vm.validations.part1aPersonalInformation
                        .numberOfBorrowers,
                  },
                }),
              ],
              1
            ),
            _c(
              "transition-group",
              { attrs: { name: "list", tag: "div" } },
              _vm._l(
                _vm.section1BorrowerInformation.part1aPersonalInformation
                  .otherBorrowers,
                function (otherBorrower, index) {
                  return _c(
                    "div",
                    { key: index + 1, staticClass: "list-item-transition row" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "col-12 col-12-xsmall font-weight-bold",
                          staticStyle: { "padding-bottom": "0" },
                        },
                        [
                          _vm._v(
                            " Borrower " + _vm._s(index + 2) + " - Full Name: "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        { staticClass: "col-3 col-12-xsmall" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "first-name-" + index },
                            },
                            [
                              _c("span", [_vm._v("First Name:")]),
                              _c("strong", [
                                _c(
                                  "abbr",
                                  {
                                    staticClass: "required",
                                    attrs: { title: "required" },
                                  },
                                  [_vm._v("*")]
                                ),
                              ]),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: otherBorrower.firstName,
                                expression: "otherBorrower.firstName",
                              },
                            ],
                            staticClass: "medium-input",
                            attrs: {
                              id: "first-name-" + index,
                              type: "text",
                              placeholder: "First Name",
                              required: "",
                            },
                            domProps: { value: otherBorrower.firstName },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    otherBorrower,
                                    "firstName",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.validateFirstName($event, index)
                                },
                              ],
                              invalid: function ($event) {
                                _vm.validations.part1aPersonalInformation.otherBorrowers[
                                  index
                                ].firstName = 0
                              },
                            },
                          }),
                          _c("validation-icon", {
                            attrs: {
                              isValid:
                                _vm.validations.part1aPersonalInformation
                                  .otherBorrowers[index].firstName,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticClass: "col-3 col-12-xsmall" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "middle-name-" + index },
                            },
                            [_vm._v("Middle Name:")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: otherBorrower.middleName,
                                expression: "otherBorrower.middleName",
                              },
                            ],
                            staticClass: "medium-input",
                            attrs: {
                              id: "middle-name-" + index,
                              type: "text",
                              placeholder: "Middle Name",
                            },
                            domProps: { value: otherBorrower.middleName },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    otherBorrower,
                                    "middleName",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.validateMiddleName($event, index)
                                },
                              ],
                            },
                          }),
                          _c("validation-icon", {
                            attrs: {
                              isValid:
                                _vm.validations.part1aPersonalInformation
                                  .otherBorrowers[index].middleName,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticClass: "col-3 col-12-xsmall" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "last-name-" + index },
                            },
                            [
                              _c("span", [_vm._v("Last Name:")]),
                              _c("strong", [
                                _c(
                                  "abbr",
                                  {
                                    staticClass: "required",
                                    attrs: { title: "required" },
                                  },
                                  [_vm._v("*")]
                                ),
                              ]),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: otherBorrower.lastName,
                                expression: "otherBorrower.lastName",
                              },
                            ],
                            staticClass: "medium-input",
                            attrs: {
                              id: "last-name-" + index,
                              type: "text",
                              placeholder: "Last Name",
                              required: "",
                            },
                            domProps: { value: otherBorrower.lastName },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    otherBorrower,
                                    "lastName",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.validateLastName($event, index)
                                },
                              ],
                              invalid: function ($event) {
                                _vm.validations.part1aPersonalInformation.otherBorrowers[
                                  index
                                ].lastName = 0
                              },
                            },
                          }),
                          _c("validation-icon", {
                            attrs: {
                              isValid:
                                _vm.validations.part1aPersonalInformation
                                  .otherBorrowers[index].lastName,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        { staticClass: "col-3 col-12-xsmall" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "hidden-label",
                              attrs: { for: "suffix-" + index },
                            },
                            [_vm._v("Suffix:")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: otherBorrower.suffix,
                                expression: "otherBorrower.suffix",
                              },
                            ],
                            staticClass: "small-input",
                            attrs: {
                              id: "suffix",
                              type: "text",
                              placeholder: "Suffix",
                            },
                            domProps: { value: otherBorrower.suffix },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    otherBorrower,
                                    "suffix",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.validateSuffix($event, index)
                                },
                              ],
                            },
                          }),
                          _c("validation-icon", {
                            attrs: {
                              isValid:
                                _vm.validations.part1aPersonalInformation
                                  .otherBorrowers[index].suffix,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _c("hr", { staticClass: "urla-break" }),
        _c(
          "div",
          [
            _c(
              "transition-group",
              { attrs: { name: "list", tag: "div" } },
              _vm._l(
                _vm.section1BorrowerInformation.part1aPersonalInformation
                  .addresses,
                function (address, index) {
                  return _c(
                    "div",
                    { key: index + 1, staticClass: "list-item-transition row" },
                    [
                      _c("div", { staticClass: "col-12 col-12-xsmall" }, [
                        index === 0
                          ? _c("h4", [_vm._v("Current Address:")])
                          : _c("h4", [_vm._v("Former Address:")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "urla-left-section col-6 col-12-xsmall",
                        },
                        [
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "street-" + index },
                                },
                                [
                                  _c("span", [_vm._v("Street:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: address.street,
                                    expression: "address.street",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "street-" + index,
                                  type: "text",
                                  placeholder: "Street",
                                  required: "",
                                },
                                domProps: { value: address.street },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        address,
                                        "street",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateStreet($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1aPersonalInformation.addresses[
                                      index
                                    ].street = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1aPersonalInformation
                                      .addresses[index].street,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "unit-" + index },
                                },
                                [_vm._v("Unit:")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: address.unit,
                                    expression: "address.unit",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "unit-" + index,
                                  type: "text",
                                  placeholder: "unit",
                                },
                                domProps: { value: address.unit },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateUnit($event, index)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      address,
                                      "unit",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1aPersonalInformation
                                      .addresses[index].unit,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "zip-" + index },
                                },
                                [
                                  _c("span", [_vm._v("Zip:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: address.zip,
                                    expression: "address.zip",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "zip-" + index,
                                  type: "text",
                                  minlength: "5",
                                  maxlength: "5",
                                  placeholder: "00000",
                                  required: "",
                                },
                                domProps: { value: address.zip },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        address,
                                        "zip",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateZip($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1aPersonalInformation.addresses[
                                      index
                                    ].zip = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1aPersonalInformation
                                      .addresses[index].zip,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "city-" + index },
                                },
                                [
                                  _c("span", [_vm._v("City:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: address.city,
                                    expression: "address.city",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "city-" + index,
                                  type: "text",
                                  placeholder: "City",
                                  required: "",
                                },
                                domProps: { value: address.city },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        address,
                                        "city",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateCity($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1aPersonalInformation.addresses[
                                      index
                                    ].city = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1aPersonalInformation
                                      .addresses[index].city,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "small-label",
                                  attrs: { for: "state-" + index },
                                },
                                [
                                  _c("span", [_vm._v("State:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: address.state,
                                    expression: "address.state",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "state-" + index,
                                  type: "text",
                                  placeholder: "State",
                                  required: "",
                                },
                                domProps: { value: address.state },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        address,
                                        "state",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateState($event, index)
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1aPersonalInformation.addresses[
                                      index
                                    ].state = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1aPersonalInformation
                                      .addresses[index].state,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "own-property" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: address.isOwnProperty,
                                  expression: "address.isOwnProperty",
                                },
                              ],
                              staticClass: "medium-input",
                              attrs: {
                                id: "status-own" + index,
                                type: "radio",
                                name: "address-status" + index,
                                required: "",
                              },
                              domProps: {
                                value: true,
                                checked: _vm._q(address.isOwnProperty, true),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    address,
                                    "isOwnProperty",
                                    true
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              { attrs: { for: "status-own" + index } },
                              [_c("span", [_vm._v("Own")])]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: address.isOwnProperty,
                                  expression: "address.isOwnProperty",
                                },
                              ],
                              staticClass: "medium-input",
                              attrs: {
                                id: "status-rental" + index,
                                type: "radio",
                                name: "address-status" + index,
                                checked: "",
                                required: "",
                              },
                              domProps: {
                                value: false,
                                checked: _vm._q(address.isOwnProperty, false),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    address,
                                    "isOwnProperty",
                                    false
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "small-label",
                                attrs: { for: "status-rental" + index },
                              },
                              [_c("span", [_vm._v("Rental")])]
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "col-6 col-12-xsmall" }, [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(" How long have you been at this address? "),
                        ]),
                        _c(
                          "p",
                          [
                            _c(
                              "label",
                              {
                                staticClass: "small-label",
                                attrs: { for: "years-at-address-" + index },
                              },
                              [
                                _c("span", [_vm._v("Years:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: address.yearsAtAddress,
                                  expression: "address.yearsAtAddress",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "x-small-input",
                              attrs: {
                                min: "0",
                                max: "50",
                                id: "years-at-address-" + index,
                                type: "number",
                                required: "",
                              },
                              domProps: { value: address.yearsAtAddress },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      address,
                                      "yearsAtAddress",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  function ($event) {
                                    return _vm.validateYearsAtAddress(
                                      $event,
                                      index
                                    )
                                  },
                                ],
                                invalid: function ($event) {
                                  _vm.validations.part1aPersonalInformation.addresses[
                                    index
                                  ].yearsAtAddress = 0
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c("validation-icon", {
                              attrs: {
                                isValid:
                                  _vm.validations.part1aPersonalInformation
                                    .addresses[index].yearsAtAddress,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c(
                              "label",
                              {
                                staticClass: "small-label",
                                attrs: { for: "months-at-address-" + index },
                              },
                              [
                                _c("span", [_vm._v("Months:")]),
                                _c("strong", [
                                  _c(
                                    "abbr",
                                    {
                                      staticClass: "required",
                                      attrs: { title: "required" },
                                    },
                                    [_vm._v("*")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: address.monthsAtAddress,
                                  expression: "address.monthsAtAddress",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "x-small-input",
                              attrs: {
                                min: "0",
                                max: "11",
                                id: "months-at-address-" + index,
                                type: "number",
                                required: "",
                              },
                              domProps: { value: address.monthsAtAddress },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      address,
                                      "monthsAtAddress",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  function ($event) {
                                    return _vm.validateMonthsAtAddress(
                                      $event,
                                      index
                                    )
                                  },
                                ],
                                invalid: function ($event) {
                                  _vm.validations.part1aPersonalInformation.addresses[
                                    index
                                  ].monthsAtAddress = 0
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c("validation-icon", {
                              attrs: {
                                isValid:
                                  _vm.validations.part1aPersonalInformation
                                    .addresses[index].monthsAtAddress,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _c("hr", { staticClass: "urla-break" }),
        _c(
          "div",
          [
            _c("h4", [_vm._v("Current Employment")]),
            _c(
              "transition-group",
              { attrs: { name: "list", tag: "div" } },
              _vm._l(
                _vm.section1BorrowerInformation.part1bcCurrentEmployment,
                function (currentEmployment, index) {
                  return _c(
                    "div",
                    { key: index + 1, staticClass: "list-item-transition row" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "urla-left-section col-6 col-12-xsmall",
                        },
                        [
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for: "current-employer-name-" + index,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v("Employer/Business Name:"),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: currentEmployment.employerName,
                                    expression:
                                      "currentEmployment.employerName",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "current-employer-name-" + index,
                                  type: "text",
                                  placeholder: "Organization Name",
                                  required: "",
                                },
                                domProps: {
                                  value: currentEmployment.employerName,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        currentEmployment,
                                        "employerName",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateEmployerName(
                                        $event,
                                        index,
                                        "current-employment"
                                      )
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].employerName = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].employerName,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for: "current-employer-phone-" + index,
                                  },
                                },
                                [
                                  _c("span", [_vm._v("Employer Phone:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "+1(###)###-####",
                                    expression: "'+1(###)###-####'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: currentEmployment.employerPhone,
                                    expression:
                                      "currentEmployment.employerPhone",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "current-employer-phone-" + index,
                                  type: "tel",
                                  maxlength: "15",
                                  minlength: "15",
                                  placeholder: "+1(123)456-7890",
                                  required: "",
                                },
                                domProps: {
                                  value: currentEmployment.employerPhone,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateEmployerPhone(
                                      $event,
                                      index,
                                      "current-employment"
                                    )
                                  },
                                  invalid: function ($event) {
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].employerPhone = 0
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      currentEmployment,
                                      "employerPhone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].employerPhone,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: { for: "current-position-" + index },
                                },
                                [
                                  _c("span", [_vm._v("Position or Title:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: currentEmployment.position,
                                    expression: "currentEmployment.position",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "current-position-" + index,
                                  type: "text",
                                  placeholder: "Position",
                                  required: "",
                                },
                                domProps: { value: currentEmployment.position },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        currentEmployment,
                                        "position",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validatePosition(
                                        $event,
                                        index,
                                        "current-employment"
                                      )
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].position = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].position,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for:
                                      "current-employment-start-date-" + index,
                                  },
                                },
                                [
                                  _c("span", [_vm._v("Start Date:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("date-pick", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##/##/####",
                                    expression: "'##/##/####'",
                                  },
                                ],
                                staticClass: "date-pick medium-input",
                                attrs: {
                                  id: "current-employment-start-date-" + index,
                                  inputAttributes: {
                                    placeholder: "MM/DD/YYYY",
                                    required: "required",
                                  },
                                  isDateDisabled: _vm.isFutureDate,
                                  displayFormat: "MM/DD/YYYY",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.validateCurrentEmploymentStartDate(
                                      $event,
                                      index
                                    )
                                  },
                                  invalid: function ($event) {
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].startDate = 0
                                  },
                                },
                                model: {
                                  value: currentEmployment.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      currentEmployment,
                                      "startDate",
                                      $$v
                                    )
                                  },
                                  expression: "currentEmployment.startDate",
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1bcCurrentEmployment[
                                      index
                                    ].startDate,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _c("h5", [_vm._v("Employer Address:")]),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "current-employer-street-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("Street:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: currentEmployment.street,
                                      expression: "currentEmployment.street",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "current-employer-street-" + index,
                                    type: "text",
                                    placeholder: "Street",
                                    required: "",
                                  },
                                  domProps: { value: currentEmployment.street },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          currentEmployment,
                                          "street",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateStreet(
                                          $event,
                                          index,
                                          "current-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].street = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].street,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "current-employer-zip=" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("Zip:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: currentEmployment.zip,
                                      expression: "currentEmployment.zip",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "current-employer-zip-" + index,
                                    type: "text",
                                    minlength: "5",
                                    maxlength: "5",
                                    placeholder: "00000",
                                    required: "",
                                  },
                                  domProps: { value: currentEmployment.zip },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          currentEmployment,
                                          "zip",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateZip(
                                          $event,
                                          index,
                                          "current-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].zip = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].zip,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "current-employer-city-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("City:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: currentEmployment.city,
                                      expression: "currentEmployment.city",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "current-employer-city-" + index,
                                    type: "text",
                                    placeholder: "City",
                                    required: "",
                                  },
                                  domProps: { value: currentEmployment.city },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          currentEmployment,
                                          "city",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateCity(
                                          $event,
                                          index,
                                          "current-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].city = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].city,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "current-employer-state-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("State:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: currentEmployment.state,
                                      expression: "currentEmployment.state",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "current-employer-state-" + index,
                                    type: "text",
                                    placeholder: "State",
                                    required: "",
                                  },
                                  domProps: { value: currentEmployment.state },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          currentEmployment,
                                          "state",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateState(
                                          $event,
                                          index,
                                          "current-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].state = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1bcCurrentEmployment[
                                        index
                                      ].state,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "urla-right-section col-6 col-12-xsmall",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "line-height": "35px",
                                  "padding-bottom": "0",
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticStyle: { display: "inline" },
                                    attrs: {
                                      for:
                                        "self-employed-business-owner-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Are you Self-Employed or the Business Owner?"
                                      ),
                                    ]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "fieldset",
                              {
                                staticClass: "urla-radio radio-inline",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            currentEmployment.selfEmployment
                                              .isSelfEmployedBusinessOwner,
                                          expression:
                                            "\n                        currentEmployment.selfEmployment\n                          .isSelfEmployedBusinessOwner\n                      ",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          "self-employed-business-owner-" +
                                          index,
                                        type: "radio",
                                        id:
                                          "self-employed-business-owner-no-" +
                                          index,
                                        required: "",
                                      },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          currentEmployment.selfEmployment
                                            .isSelfEmployedBusinessOwner,
                                          false
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            currentEmployment.selfEmployment,
                                            "isSelfEmployedBusinessOwner",
                                            false
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for:
                                            "self-employed-business-owner-no-" +
                                            index,
                                        },
                                      },
                                      [_vm._v("No")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            currentEmployment.selfEmployment
                                              .isSelfEmployedBusinessOwner,
                                          expression:
                                            "\n                        currentEmployment.selfEmployment\n                          .isSelfEmployedBusinessOwner\n                      ",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          "self-employed-business-owner-" +
                                          index,
                                        type: "radio",
                                        id:
                                          "self-employed-business-owner-yes-" +
                                          index,
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          currentEmployment.selfEmployment
                                            .isSelfEmployedBusinessOwner,
                                          true
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            currentEmployment.selfEmployment,
                                            "isSelfEmployedBusinessOwner",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for:
                                            "self-employed-business-owner-yes-" +
                                            index,
                                        },
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _c("transition", { attrs: { name: "fade" } }, [
                            currentEmployment.selfEmployment
                              .isSelfEmployedBusinessOwner
                              ? _c("div", [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "line-height": "35px",
                                          "padding-bottom": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticStyle: { display: "inline" },
                                            attrs: {
                                              for:
                                                "ownership-share-more-than-twenty-five-" +
                                                index,
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Do you have an ownership share of 25% or more?"
                                              ),
                                            ]),
                                            _c("strong", [
                                              _c(
                                                "abbr",
                                                {
                                                  staticClass: "required",
                                                  attrs: { title: "required" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "urla-radio radio-inline",
                                        staticStyle: { display: "inline" },
                                      },
                                      [
                                        _c("ul", [
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    currentEmployment
                                                      .selfEmployment
                                                      .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  expression:
                                                    "\n                            currentEmployment.selfEmployment\n                              .isOwnershipInCompanyMoreThanTwentyFivePercent\n                          ",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "ownership-share-more-than-twenty-five-" +
                                                  index,
                                                type: "radio",
                                                id:
                                                  "ownership-share-more-than-twenty-five-no-" +
                                                  index,
                                                required: "",
                                              },
                                              domProps: {
                                                value: false,
                                                checked: _vm._q(
                                                  currentEmployment
                                                    .selfEmployment
                                                    .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  false
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    currentEmployment.selfEmployment,
                                                    "isOwnershipInCompanyMoreThanTwentyFivePercent",
                                                    false
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal small-label",
                                                attrs: {
                                                  for:
                                                    "ownership-share-more-than-twenty-five-no-" +
                                                    index,
                                                },
                                              },
                                              [_vm._v("No")]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    currentEmployment
                                                      .selfEmployment
                                                      .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  expression:
                                                    "\n                            currentEmployment.selfEmployment\n                              .isOwnershipInCompanyMoreThanTwentyFivePercent\n                          ",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "ownership-share-more-than-twenty-five-" +
                                                  index,
                                                type: "radio",
                                                id:
                                                  "ownership-share-more-than-twenty-five-yes-" +
                                                  index,
                                              },
                                              domProps: {
                                                value: true,
                                                checked: _vm._q(
                                                  currentEmployment
                                                    .selfEmployment
                                                    .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  true
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    currentEmployment.selfEmployment,
                                                    "isOwnershipInCompanyMoreThanTwentyFivePercent",
                                                    true
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal small-label",
                                                attrs: {
                                                  for:
                                                    "ownership-share-more-than-twenty-five-yes-" +
                                                    index,
                                                },
                                              },
                                              [_vm._v("Yes")]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "self-employment-monthly-income-" +
                                              index,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Monthly Income:"),
                                          ]),
                                          _c("strong", [
                                            _c(
                                              "abbr",
                                              {
                                                staticClass: "required",
                                                attrs: { title: "required" },
                                              },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment.selfEmployment
                                                .selfEmploymentMonthlyIncome,
                                            expression:
                                              "\n                      currentEmployment.selfEmployment\n                        .selfEmploymentMonthlyIncome\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment.selfEmployment
                                                .selfEmploymentMonthlyIncome !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.selfEmployment\n                        .selfEmploymentMonthlyIncome !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "self-employment-monthly-income-" +
                                            index,
                                          type: "text",
                                          maxlength: "15",
                                          required: "",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.selfEmployment
                                              .selfEmploymentMonthlyIncome,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentMonthlyIncome(
                                              $event,
                                              index
                                            )
                                          },
                                          invalid: function ($event) {
                                            _vm.validations.part1bcCurrentEmployment[
                                              index
                                            ].selfEmployment.selfEmploymentMonthlyIncome = 0
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.selfEmployment,
                                              "selfEmploymentMonthlyIncome",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .selfEmployment
                                              .selfEmploymentMonthlyIncome,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("transition", { attrs: { name: "fade" } }, [
                            currentEmployment.selfEmployment
                              .isSelfEmployedBusinessOwner === false
                              ? _c("div", [
                                  _c("hr", { staticClass: "urla-break" }),
                                  _c("h5", [_vm._v("Gross Monthly Income:")]),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "current-employment-base-" +
                                              index,
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Base:")]),
                                          _c("strong", [
                                            _c(
                                              "abbr",
                                              {
                                                staticClass: "required",
                                                attrs: { title: "required" },
                                              },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.baseSalary,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.baseSalary\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome
                                                .baseSalary !== null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.baseSalary !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "current-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                          required: "",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.grossMonthlyIncome
                                              .baseSalary,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentEmploymentBaseSalary(
                                              $event,
                                              index
                                            )
                                          },
                                          invalid: function ($event) {
                                            _vm.validations.part1bcCurrentEmployment[
                                              index
                                            ].grossMonthlyIncome.baseSalary = 0
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.grossMonthlyIncome,
                                              "baseSalary",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .grossMonthlyIncome.baseSalary,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "current-employment-overtime-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Overtime:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.overtime,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.overtime\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.overtime !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.overtime !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "current-employment-overtime-" +
                                            index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.grossMonthlyIncome
                                              .overtime,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentEmploymentOvertime(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.grossMonthlyIncome,
                                              "overtime",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .grossMonthlyIncome.overtime,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "current-employment-bonus-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Bonus:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.bonus,
                                            expression:
                                              "currentEmployment.grossMonthlyIncome.bonus",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.bonus !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.bonus !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "current-employment-bonus-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.grossMonthlyIncome
                                              .bonus,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentEmploymentBonus(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.grossMonthlyIncome,
                                              "bonus",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .grossMonthlyIncome.bonus,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "current-employment-base-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Commission:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.commission,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.commission\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome
                                                .commission !== null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.commission !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "current-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.grossMonthlyIncome
                                              .commission,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentEmploymentCommission(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.grossMonthlyIncome,
                                              "commission",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .grossMonthlyIncome.commission,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "current-employment-base-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Military Entitlements:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome
                                                .militaryEntitlements,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome\n                        .militaryEntitlements\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome
                                                .militaryEntitlements !== null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome\n                        .militaryEntitlements !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "current-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.grossMonthlyIncome
                                              .militaryEntitlements,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentEmploymentMilitaryEntitlements(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.grossMonthlyIncome,
                                              "militaryEntitlements",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .grossMonthlyIncome
                                              .militaryEntitlements,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "current-employment-base-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Other:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.other,
                                            expression:
                                              "currentEmployment.grossMonthlyIncome.other",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              currentEmployment
                                                .grossMonthlyIncome.other !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      currentEmployment.grossMonthlyIncome.other !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "current-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            currentEmployment.grossMonthlyIncome
                                              .other,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validateCurrentEmploymentOther(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              currentEmployment.grossMonthlyIncome,
                                              "other",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1bcCurrentEmployment[index]
                                              .grossMonthlyIncome.other,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.section1BorrowerInformation.part1dPreviousEmployment.length !==
            0
              ? _c("div")
              : _vm._e(),
            _c(
              "transition-group",
              { attrs: { name: "list", tag: "div" } },
              _vm._l(
                _vm.section1BorrowerInformation.part1dPreviousEmployment,
                function (previousEmployment, index) {
                  return _c(
                    "div",
                    { key: index + 1, staticClass: "list-item-transition row" },
                    [
                      _c("div", { staticClass: "col-12 col-12-xsmall" }, [
                        _c("hr", { staticClass: "urla-break" }),
                        _c("h4", [_vm._v("Previous Employment")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "urla-left-section col-6 col-12-xsmall",
                        },
                        [
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for: "previous-employer-name-" + index,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v("Employer/Business Name:"),
                                  ]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: previousEmployment.employerName,
                                    expression:
                                      "previousEmployment.employerName",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "previous-employer-name-" + index,
                                  type: "text",
                                  placeholder: "Orgazination Name",
                                  required: "",
                                },
                                domProps: {
                                  value: previousEmployment.employerName,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        previousEmployment,
                                        "employerName",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateEmployerName(
                                        $event,
                                        index,
                                        "previous-employment"
                                      )
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].employerName = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].employerName,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for: "previous-employer-phone-" + index,
                                  },
                                },
                                [
                                  _c("span", [_vm._v("Employer Phone:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "+1(###)###-####",
                                    expression: "'+1(###)###-####'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: previousEmployment.employerPhone,
                                    expression:
                                      "previousEmployment.employerPhone",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "previous-employer-phone-" + index,
                                  type: "tel",
                                  maxlength: "15",
                                  minlength: "15",
                                  placeholder: "+1(123)456-7890",
                                  required: "",
                                },
                                domProps: {
                                  value: previousEmployment.employerPhone,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateEmployerPhone(
                                      $event,
                                      index,
                                      "previous-employment"
                                    )
                                  },
                                  invalid: function ($event) {
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].employerPhone = 0
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      previousEmployment,
                                      "employerPhone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].employerPhone,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: { for: "previous-position-" + index },
                                },
                                [
                                  _c("span", [_vm._v("Position or Title:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: previousEmployment.position,
                                    expression: "previousEmployment.position",
                                  },
                                ],
                                staticClass: "medium-input",
                                attrs: {
                                  id: "previous-position-" + index,
                                  type: "text",
                                  placeholder: "Position",
                                  required: "",
                                },
                                domProps: {
                                  value: previousEmployment.position,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        previousEmployment,
                                        "position",
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validatePosition(
                                        $event,
                                        index,
                                        "previous-employment"
                                      )
                                    },
                                  ],
                                  invalid: function ($event) {
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].position = 0
                                  },
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].position,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for:
                                      "previous-employment-start-date-" + index,
                                  },
                                },
                                [
                                  _c("span", [_vm._v("Start Date:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("date-pick", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##/##/####",
                                    expression: "'##/##/####'",
                                  },
                                ],
                                staticClass: "date-pick medium-input",
                                attrs: {
                                  id: "previous-employment-start-date-" + index,
                                  inputAttributes: {
                                    placeholder: "MM/DD/YYYY",
                                    required: "required",
                                  },
                                  isDateDisabled: _vm.isFutureDate,
                                  displayFormat: "MM/DD/YYYY",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.validatePreviousEmploymentStartDate(
                                      $event,
                                      index
                                    )
                                  },
                                  invalid: function ($event) {
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].startDate = 0
                                  },
                                },
                                model: {
                                  value: previousEmployment.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      previousEmployment,
                                      "startDate",
                                      $$v
                                    )
                                  },
                                  expression: "previousEmployment.startDate",
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].startDate,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "medium-label",
                                  attrs: {
                                    for:
                                      "previous-employment-end-date-" + index,
                                  },
                                },
                                [
                                  _c("span", [_vm._v("End Date:")]),
                                  _c("strong", [
                                    _c(
                                      "abbr",
                                      {
                                        staticClass: "required",
                                        attrs: { title: "required" },
                                      },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("date-pick", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##/##/####",
                                    expression: "'##/##/####'",
                                  },
                                ],
                                staticClass: "date-pick medium-input",
                                attrs: {
                                  id: "previous-employment-end-date-" + index,
                                  inputAttributes: {
                                    placeholder: "MM/DD/YYYY",
                                    required: "required",
                                  },
                                  isDateDisabled: _vm.isFutureDate,
                                  displayFormat: "MM/DD/YYYY",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.validateEmploymentEndDate(
                                      $event,
                                      index
                                    )
                                  },
                                  invalid: function ($event) {
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].endDate = 0
                                  },
                                },
                                model: {
                                  value: previousEmployment.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(previousEmployment, "endDate", $$v)
                                  },
                                  expression: "previousEmployment.endDate",
                                },
                              }),
                              _c("validation-icon", {
                                attrs: {
                                  isValid:
                                    _vm.validations.part1dPreviousEmployment[
                                      index
                                    ].endDate,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _c("h5", [_vm._v("Employer Address:")]),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "previous-employer-street-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("Street:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: previousEmployment.street,
                                      expression: "previousEmployment.street",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "previous-employer-street-" + index,
                                    type: "text",
                                    placeholder: "Street",
                                    required: "",
                                  },
                                  domProps: {
                                    value: previousEmployment.street,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          previousEmployment,
                                          "street",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateStreet(
                                          $event,
                                          index,
                                          "previous-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].street = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].street,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "previous-employer-zip=" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("Zip:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: previousEmployment.zip,
                                      expression: "previousEmployment.zip",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "previous-employer-zip-" + index,
                                    type: "text",
                                    minlength: "5",
                                    maxlength: "5",
                                    placeholder: "00000",
                                    required: "",
                                  },
                                  domProps: { value: previousEmployment.zip },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          previousEmployment,
                                          "zip",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateZip(
                                          $event,
                                          index,
                                          "previous-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].zip = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].zip,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "previous-employer-city-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("City:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: previousEmployment.city,
                                      expression: "previousEmployment.city",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "previous-employer-city-" + index,
                                    type: "text",
                                    placeholder: "City",
                                    required: "",
                                  },
                                  domProps: { value: previousEmployment.city },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          previousEmployment,
                                          "city",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateCity(
                                          $event,
                                          index,
                                          "previous-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].city = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].city,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "medium-label",
                                    attrs: {
                                      for: "previous-employer-state-" + index,
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("State:")]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: previousEmployment.state,
                                      expression: "previousEmployment.state",
                                    },
                                  ],
                                  staticClass: "medium-input",
                                  attrs: {
                                    id: "previous-employer-state-" + index,
                                    type: "text",
                                    placeholder: "State",
                                    required: "",
                                  },
                                  domProps: { value: previousEmployment.state },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          previousEmployment,
                                          "state",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.validateState(
                                          $event,
                                          index,
                                          "previous-employment"
                                        )
                                      },
                                    ],
                                    invalid: function ($event) {
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].state = 0
                                    },
                                  },
                                }),
                                _c("validation-icon", {
                                  attrs: {
                                    isValid:
                                      _vm.validations.part1dPreviousEmployment[
                                        index
                                      ].state,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "urla-right-section col-6 col-12-xsmall",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "line-height": "35px",
                                  "padding-bottom": "0",
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticStyle: { display: "inline" },
                                    attrs: {
                                      for:
                                        "previous-self-employed-business-owner-" +
                                        index,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Are you Self-Employed or the Business Owner?"
                                      ),
                                    ]),
                                    _c("strong", [
                                      _c(
                                        "abbr",
                                        {
                                          staticClass: "required",
                                          attrs: { title: "required" },
                                        },
                                        [_vm._v("*")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "fieldset",
                              {
                                staticClass: "urla-radio radio-inline",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            previousEmployment.selfEmployment
                                              .isSelfEmployedBusinessOwner,
                                          expression:
                                            "\n                        previousEmployment.selfEmployment\n                          .isSelfEmployedBusinessOwner\n                      ",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          "previous-self-employed-business-owner-" +
                                          index,
                                        type: "radio",
                                        id:
                                          "previous-self-employed-business-owner-no-" +
                                          index,
                                        required: "",
                                      },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          previousEmployment.selfEmployment
                                            .isSelfEmployedBusinessOwner,
                                          false
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            previousEmployment.selfEmployment,
                                            "isSelfEmployedBusinessOwner",
                                            false
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for:
                                            "previous-self-employed-business-owner-no-" +
                                            index,
                                        },
                                      },
                                      [_vm._v("No")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            previousEmployment.selfEmployment
                                              .isSelfEmployedBusinessOwner,
                                          expression:
                                            "\n                        previousEmployment.selfEmployment\n                          .isSelfEmployedBusinessOwner\n                      ",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          "previous-self-employed-business-owner-" +
                                          index,
                                        type: "radio",
                                        id:
                                          "previous-self-employed-business-owner-yes-" +
                                          index,
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          previousEmployment.selfEmployment
                                            .isSelfEmployedBusinessOwner,
                                          true
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            previousEmployment.selfEmployment,
                                            "isSelfEmployedBusinessOwner",
                                            true
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-weight-normal small-label",
                                        attrs: {
                                          for:
                                            "previous-self-employed-business-owner-yes-" +
                                            index,
                                        },
                                      },
                                      [_vm._v("Yes")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _c("transition", { attrs: { name: "fade" } }, [
                            previousEmployment.selfEmployment
                              .isSelfEmployedBusinessOwner
                              ? _c("div", [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "line-height": "35px",
                                          "padding-bottom": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticStyle: { display: "inline" },
                                            attrs: {
                                              for:
                                                "previous-ownership-share-more-than-twenty-five-" +
                                                index,
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Do you have an ownership share of 25% or more?"
                                              ),
                                            ]),
                                            _c("strong", [
                                              _c(
                                                "abbr",
                                                {
                                                  staticClass: "required",
                                                  attrs: { title: "required" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "urla-radio radio-inline",
                                        staticStyle: { display: "inline" },
                                      },
                                      [
                                        _c("ul", [
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    previousEmployment
                                                      .selfEmployment
                                                      .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  expression:
                                                    "\n                            previousEmployment.selfEmployment\n                              .isOwnershipInCompanyMoreThanTwentyFivePercent\n                          ",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "previous-ownership-share-more-than-twenty-five-" +
                                                  index,
                                                type: "radio",
                                                id:
                                                  "previous-ownership-share-more-than-twenty-five-no-" +
                                                  index,
                                                required: "",
                                              },
                                              domProps: {
                                                value: false,
                                                checked: _vm._q(
                                                  previousEmployment
                                                    .selfEmployment
                                                    .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  false
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    previousEmployment.selfEmployment,
                                                    "isOwnershipInCompanyMoreThanTwentyFivePercent",
                                                    false
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal small-label",
                                                attrs: {
                                                  for:
                                                    "previous-ownership-share-more-than-twenty-five-no-" +
                                                    index,
                                                },
                                              },
                                              [_vm._v("No")]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    previousEmployment
                                                      .selfEmployment
                                                      .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  expression:
                                                    "\n                            previousEmployment.selfEmployment\n                              .isOwnershipInCompanyMoreThanTwentyFivePercent\n                          ",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "previous-ownership-share-more-than-twenty-five-" +
                                                  index,
                                                type: "radio",
                                                id:
                                                  "previous-ownership-share-more-than-twenty-five-yes-" +
                                                  index,
                                              },
                                              domProps: {
                                                value: true,
                                                checked: _vm._q(
                                                  previousEmployment
                                                    .selfEmployment
                                                    .isOwnershipInCompanyMoreThanTwentyFivePercent,
                                                  true
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    previousEmployment.selfEmployment,
                                                    "isOwnershipInCompanyMoreThanTwentyFivePercent",
                                                    true
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "font-weight-normal small-label",
                                                attrs: {
                                                  for:
                                                    "previous-ownership-share-more-than-twenty-five-yes-" +
                                                    index,
                                                },
                                              },
                                              [_vm._v("Yes")]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-self-employment-monthly-income-" +
                                              index,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Monthly Income:"),
                                          ]),
                                          _c("strong", [
                                            _c(
                                              "abbr",
                                              {
                                                staticClass: "required",
                                                attrs: { title: "required" },
                                              },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment.selfEmployment
                                                .selfEmploymentMonthlyIncome,
                                            expression:
                                              "\n                      previousEmployment.selfEmployment\n                        .selfEmploymentMonthlyIncome\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment.selfEmployment
                                                .selfEmploymentMonthlyIncome !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.selfEmployment\n                        .selfEmploymentMonthlyIncome !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-self-employment-monthly-income-" +
                                            index,
                                          type: "text",
                                          maxlength: "15",
                                          required: "",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment.selfEmployment
                                              .selfEmploymentMonthlyIncome,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousMonthlyIncome(
                                              $event,
                                              index
                                            )
                                          },
                                          invalid: function ($event) {
                                            _vm.validations.part1dPreviousEmployment[
                                              index
                                            ].selfEmployment.selfEmploymentMonthlyIncome = 0
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.selfEmployment,
                                              "selfEmploymentMonthlyIncome",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .selfEmployment
                                              .selfEmploymentMonthlyIncome,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("transition", { attrs: { name: "fade" } }, [
                            previousEmployment.selfEmployment
                              .isSelfEmployedBusinessOwner === false
                              ? _c("div", [
                                  _c("hr", { staticClass: "urla-break" }),
                                  _c("h5", [_vm._v("Gross Monthly Income:")]),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-employment-base-" +
                                              index,
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Base:")]),
                                          _c("strong", [
                                            _c(
                                              "abbr",
                                              {
                                                staticClass: "required",
                                                attrs: { title: "required" },
                                              },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.baseSalary,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.baseSalary\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome
                                                .baseSalary !== null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.baseSalary !==\n                      null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                          required: "",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment
                                              .grossMonthlyIncome.baseSalary,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousEmploymentBaseSalary(
                                              $event,
                                              index
                                            )
                                          },
                                          invalid: function ($event) {
                                            _vm.validations.part1dPreviousEmployment[
                                              index
                                            ].grossMonthlyIncome.baseSalary = 0
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.grossMonthlyIncome,
                                              "baseSalary",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .grossMonthlyIncome.baseSalary,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-employment-overtime-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Overtime:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.overtime,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.overtime\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.overtime !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.overtime !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-employment-overtime-" +
                                            index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment
                                              .grossMonthlyIncome.overtime,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousEmploymentOvertime(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.grossMonthlyIncome,
                                              "overtime",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .grossMonthlyIncome.overtime,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-employment-bonus-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Bonus:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.bonus,
                                            expression:
                                              "previousEmployment.grossMonthlyIncome.bonus",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.bonus !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.bonus !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-employment-bonus-" +
                                            index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment
                                              .grossMonthlyIncome.bonus,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousEmploymentBonus(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.grossMonthlyIncome,
                                              "bonus",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .grossMonthlyIncome.bonus,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-employment-base-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Commission:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.commission,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.commission\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome
                                                .commission !== null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.commission !==\n                      null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment
                                              .grossMonthlyIncome.commission,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousEmploymentCommission(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.grossMonthlyIncome,
                                              "commission",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .grossMonthlyIncome.commission,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-employment-base-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Military Entitlements:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome
                                                .militaryEntitlements,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome\n                        .militaryEntitlements\n                    ",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome
                                                .militaryEntitlements !== null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome\n                        .militaryEntitlements !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment
                                              .grossMonthlyIncome
                                              .militaryEntitlements,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousEmploymentMilitaryEntitlements(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.grossMonthlyIncome,
                                              "militaryEntitlements",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .grossMonthlyIncome
                                              .militaryEntitlements,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "medium-label",
                                          attrs: {
                                            for:
                                              "previous-employment-base-" +
                                              index,
                                          },
                                        },
                                        [_vm._v("Other:")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.other,
                                            expression:
                                              "previousEmployment.grossMonthlyIncome.other",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value:
                                              previousEmployment
                                                .grossMonthlyIncome.other !==
                                              null
                                                ? _vm.currencyFormat
                                                : null,
                                            expression:
                                              "\n                      previousEmployment.grossMonthlyIncome.other !== null\n                        ? currencyFormat\n                        : null\n                    ",
                                          },
                                        ],
                                        staticClass: "small-input",
                                        attrs: {
                                          id:
                                            "previous-employment-base-" + index,
                                          type: "text",
                                          maxlength: "15",
                                        },
                                        domProps: {
                                          value:
                                            previousEmployment
                                              .grossMonthlyIncome.other,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.validatePreviousEmploymentOther(
                                              $event,
                                              index
                                            )
                                          },
                                          change: function ($event) {
                                            return _vm.$set(
                                              previousEmployment.grossMonthlyIncome,
                                              "other",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("validation-icon", {
                                        staticClass: "x-small-column",
                                        attrs: {
                                          isValid:
                                            _vm.validations
                                              .part1dPreviousEmployment[index]
                                              .grossMonthlyIncome.other,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
        _vm._m(15),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v(" Required fields are followed by "),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "small-label", attrs: { for: "first-name" } },
      [
        _c("span", [_vm._v("First Name:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "small-label", attrs: { for: "last-name" } },
      [
        _c("span", [_vm._v("Last Name:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "small-label", attrs: { for: "cell-phone" } },
      [
        _c("span", [_vm._v("Cell Phone:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "small-label", attrs: { for: "email-address" } },
      [
        _c("span", [_vm._v("Email Address:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "medium-label", attrs: { for: "social-security-number" } },
      [
        _c("span", [_vm._v("Social Security Number:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "small-label", attrs: { for: "date-of-birth" } },
      [
        _c("span", [_vm._v("Date of Birth:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "label",
        { staticClass: "urla-radio-label", attrs: { for: "marital-status" } },
        [
          _c("span", [_vm._v("Marital Status:")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "label",
        { staticClass: "urla-radio-label", attrs: { for: "citizenhip" } },
        [
          _c("span", [_vm._v("Citizenship:")]),
          _c("strong", [
            _c(
              "abbr",
              { staticClass: "required", attrs: { title: "required" } },
              [_vm._v("*")]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "small-label spouse-label",
        attrs: { for: "spouse-first-name" },
      },
      [
        _c("span", [_vm._v("First Name:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "small-label spouse-label",
        attrs: { for: "spouse-last-name" },
      },
      [
        _c("span", [_vm._v("Last Name:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "small-label spouse-label",
        attrs: { for: "spouse-email" },
      },
      [
        _c("span", [_vm._v("Email:")]),
        _c("strong", [
          _c(
            "abbr",
            { staticClass: "required", attrs: { title: "required" } },
            [_vm._v("*")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "number-of-dependents" } }, [
      _c("span", [_vm._v("Number of Dependents:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
        _vm._v("*"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "number-of-borrowers" } }, [
      _c("span", [_vm._v("Total Number of Borrowers:")]),
      _c("strong", [
        _c("abbr", { staticClass: "required", attrs: { title: "required" } }, [
          _vm._v("*"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c(
        "button",
        {
          staticClass: "primary wizard__next pull-right",
          attrs: { type: "submit" },
        },
        [
          _c("span", [
            _c("i", {
              staticClass: "fa fa-floppy-o",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" save "),
            _c("i", {
              staticClass: "fa fa-arrow-right fa-lg",
              attrs: { "aria-hidden": "true" },
            }),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }