var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpenToUpdate,
          expression: "isOpenToUpdate",
        },
      ],
      staticClass: "files__drive",
      class: { mask__panel: _vm.isMaskEnabled },
    },
    [
      _vm.isMaskEnabled ? _c("div", { staticClass: "mask__layer" }) : _vm._e(),
      _c("div", { staticClass: "drive__info" }, [
        _c("div", { staticClass: "drive__icon" }, [
          _c(
            "svg",
            {
              staticClass: "bi bi-database-fill-lock",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "25",
                height: "25",
                fill: "currentColor",
                viewBox: "0 0 16 16",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M3.904 9.223C2.875 8.755 2 8.007 2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.5 4.5 0 0 0-1.364-.125 3 3 0 0 0-2.197.731 4.5 4.5 0 0 0-1.254 1.237A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777M8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16q.134 0 .266-.003A2 2 0 0 1 8 15zm0-1.5q0 .15.01.3A2 2 0 0 0 8 13c-1.573 0-3.022-.289-4.096-.777C2.875 11.755 2 11.007 2 10v-.839c.457.432 1.004.751 1.49.972C4.722 10.693 6.318 11 8 11q.13 0 .257-.002A4.5 4.5 0 0 0 8 12.5",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1",
                },
              }),
            ]
          ),
        ]),
        _vm._m(0),
      ]),
      _vm.nodes != []
        ? _c("folder-item", {
            attrs: { nodes: _vm.nodes, isContextEnabled: _vm.isContextEnabled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "drive__title" }, [
      _c("h2", [_vm._v("Uploaded Files")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }