var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "wrapper" }, [
        _c("section", { staticClass: "main" }, [
          _c("section", [
            _c("h1", [_vm._v("Resources")]),
            _c("ul", { staticClass: "resources-list" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://files.consumerfinance.gov/f/documents/cfpb_your-home-loan-toolkit.pdf",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(" Home loan tool kit "),
                    _c("i", { staticClass: "icon fa-external-link" }),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("section", { staticClass: "spotlights alt" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }